import React, {useEffect, useState} from 'react'
import {Button, Col, notification, Result, Row} from "antd";
import {MailOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {getAccountInfo, resendVerifyEmail, verifyEmail} from "../../redux/auth/auth-action";
import {pageName} from "../../helper/other/common";
import CryptoJS from 'crypto-js'
import {useDispatch} from 'react-redux';
import LoadingPage from '../../components/utils/loader/Loading';
import {isEqual, isUndefined} from 'lodash'
import {setIsPath} from "../../redux/auth/auth-slice";

const VerifyEmail = () => {
	const {id, token: dataToken} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const [isLoading, setIsLoading] = useState({submitLoader: false, verificationLoader: false});

	const token = localStorage.getItem("token");
	const email = localStorage.getItem("Login Details");
	let kycStatus = localStorage.getItem("kycStatus");
	let isVerified = localStorage.getItem("email_verified");

	useEffect(() => {
		dispatch(setIsPath(true));
		emailVerification();
		getUserAccountInfo();

		if(!token) !dataToken && navigate("/login");
		else {
			if(isEqual(isVerified, "true")) isEqual(kycStatus, "approved") ? navigate("/natural") : navigate("/kyc");
		}

		document.title = `Email Verification - ${pageName}`;
	}, [token, isVerified, kycStatus]);

	// Fetch the user account-info
	const getUserAccountInfo = async() => {
		const response = await dispatch(getAccountInfo({navigate}));
		if(!isUndefined(response.payload?.email_verified_at)) {
			isEqual(kycStatus, "approved") ? navigate("/natural") : navigate("/kyc");
			localStorage.setItem("email_verified", true);
		}
	};

	// Email Verification main
	const emailVerification = async() => {
		setIsLoading({submitLoader: false, verificationLoader: true});

		if(id) {
			const response = await verifyEmail(`${id}/${dataToken}${window.location.search}`);

			if(isEqual(response.status, 200)) {
				localStorage.setItem("email_verified", true);
				isEqual(kycStatus, "approved") ? navigate("/natural") : navigate("/kyc");
			}
		}

		setIsLoading({submitLoader: false, verificationLoader: false});
	};

	// Notification alert
	const openNotification = (placement) => {
		api.success({message: "Resend Mail", description: "Verification Email Sent Successfully", placement});
	};

	// Resend Verification mail
	const resendMail = async() => {
		setIsLoading({submitLoader: true, verificationLoader: false});

		const ciphertext = localStorage.getItem("cipherId");
		let bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
		let userId = bytes.toString(CryptoJS.enc.Utf8);

		const res = await resendVerifyEmail(userId);

		if(isEqual(res.data.status, true)) {
			setIsLoading({submitLoader: false, verificationLoader: false});
			openNotification("bottom");
		}
	};

	// Set the value in style object
	const style = {rowStyle: {opacity: isLoading.verificationLoader ? '0.2' : 'unset'}}

	return (
		<>
			{isLoading.verificationLoader ? <LoadingPage /> : <></>}
			{contextHolder}
			<Row className="mt-100 d-flex justify-content-center" style={style.rowStyle}>
				<Col>
					<Result icon={<MailOutlined />}
							title={`An Email has been sent to your Email Address. Please verify ${email}`}
							extra={<Button type="primary" onClick={resendMail} loading={isLoading.submitLoader}> Resend Email</Button>} />
				</Col>
			</Row>
		</>)
}

export default VerifyEmail