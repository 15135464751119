import {DebounceInput} from "react-debounce-input";
import React from "react";
import {Col, Row, Space, Typography} from "antd";

const {Title} = Typography;

const FilterMeasurementData = ({filterData, setFilterData}) => {

	const width = filterData.measurement_width
	const depth = filterData.measurement_depth

	// Handle min-width onChange
	const handleMinWidthChange = (e) => setFilterData({...filterData, measurement_width: {...filterData.measurement_width, min: e.target.value}})

	// Handle max-width onChange
	const handleMaxWidthChange = (e) => setFilterData({...filterData, measurement_width: {...filterData.measurement_width, max: e.target.value}})

	// Handle min-depth onChange
	const handleMinDepthChange = (e) => setFilterData({...filterData, measurement_depth: {...filterData.measurement_depth, min: e.target.value}})

	// Handle max-depth onChange
	const handleMaxDepthChange = (e) => setFilterData({...filterData, measurement_depth: {...filterData.measurement_depth, max: e.target.value}})

	return (
		<Row gutter={[8, 0]}>
			<Col span={12}>
				<Title level={5} className='filter-title'>Measure Width</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={width.min} onChange={handleMinWidthChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={width.max} onChange={handleMaxWidthChange} />
				</Space>
			</Col>
			<Col span={12}>
				<Title level={5} className='filter-title'>Measure Depth</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={depth.min} onChange={handleMinDepthChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={depth.max} onChange={handleMaxDepthChange} />
				</Space>
			</Col>
		</Row>
	);
};

export default FilterMeasurementData;
