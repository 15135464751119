import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Typography} from "antd";
import Carousel from 'react-bootstrap/Carousel';
import {pageName} from "../../helper/other/common";
import AOS from "aos";
import "aos/dist/aos.css";
import './Home.css'
import {FileSearchOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {isEqual} from "lodash";

const {Text, Title} = Typography

const HomePage = () => {
	const [visibleSpan, setVisibleSpan] = useState(1);

	//diamond type show on first slider with animation
	const diamondType = ['natural diamonds', 'labgrown diamonds', 'Gemstones'];

	useEffect(() => {
		document.title = pageName;
		AOS.init({startEvent: 'scroll', once: true});

		const interval = setInterval(() => setVisibleSpan(prev => (prev + 1) % diamondType.length), 2000);
		return () => clearInterval(interval);
	}, []);

	// set a second slider text
	const SecondSliderText = <Col sm={24} md={10} xl={12} className='slider-text-col'>
		<div className="silder-text">
			Finding your customer’s perfect diamond is now as easy as online shopping
		</div>
	</Col>

	const OurProductCard = (data) => {
		//check window width for mobile
		const isMobile = window.innerWidth <= 768;
		const {src, alt, title, description, subText} = data
		return <Col data-aos={isMobile ? "" : 'fade-up'}>
			<Card bordered={false} className='our-product-card box'
				  cover={<img src={src} alt={alt} />}>
				<div className='card-content'>
					<Title level={4} className='card-title'>{title}</Title>
					{subText && <Text className='homepage-description fs-12'>{subText}</Text>}
					<Text className='homepage-description'>{description}</Text>
				</div>
			</Card>
		</Col>
	}

	const isMobile = window.innerWidth < 578;

	//set container classname for service section
	const containerClassName = window.innerWidth >= 768 ? 'container' : ''

	return <>
		<section className='slider-div'>
			<Carousel indicators={false} pause={false} data-bs-theme="dark">
				<Carousel.Item interval={5000}>
					<Row className='slider-row' justify={'center'}>
						<Col sm={24} md={10} xl={12} className='slider-text-col'>
							<div className="silder-text mb-3">Explore our vast selection of <br />
								{diamondType.map((text, index) => (
									<span key={index}
										  className={isEqual(index, visibleSpan) ? 'd-block message': 'd-none'}>{text}</span>
								))}
							</div>
						</Col>
						<Col sm={24} md={14} xl={12} className='silder-img-col'>
							<img src="images/homepage_image/image3.png" alt="diamond image"
								 className="d-block w-100 slider-img" />
						</Col>
					</Row>
				</Carousel.Item>
				<Carousel.Item interval={5000}>
					<Row className='slider-row' justify={'center'}>
						{isMobile && SecondSliderText}
						<Col sm={24} md={14} xl={12} className='silder-img-col'>
							<img src="images/homepage_image/image1.png" alt="diamond image"
								 className="d-block w-100 slider-img" />
						</Col>
						{!isMobile && SecondSliderText}
					</Row>
				</Carousel.Item>
				<Carousel.Item interval={5000}>
					<Row className='slider-row' justify={'center'}>
						<Col sm={24} md={10} xl={12} className='slider-text-col'>
							<div className="silder-text">Easy to magnificent view</div>
						</Col>
						<Col sm={24} md={14} xl={12} className='silder-img-col'>
							<img src="images/homepage_image/image2.png" alt="diamond image"
								 className="d-block w-100 slider-img" />
						</Col>
					</Row>
				</Carousel.Item>
			</Carousel>
		</section>

		{/* START OUR PRODUCT SECTION */}
		<section className='our-product-container'>
			<Title level={2} className='homepage-title'>Our Product</Title>
			<Text className='homepage-description pb-3'>Explored certified diamonds in every shape,
														authenticated by renowned grading organizations like GIA, HRD, IGI, and AGS.</Text>
			<div className='our-product-cards'>
				<Row gutter={[30, 30]} justify='center'>
					{OurProductCard({
										src        : "images/homepage_image/natural-diamond.jpg",
										alt        : 'natural diamond',
										title      : 'Natural Diamonds',
										description: 'Explore timeless D-Z colorless diamonds & rare, captivating pink, blue, green hues, each unique.'
									})}

					{OurProductCard({
										src        : "images/homepage_image/lab-grown-diamond.png",
										alt        : 'lab-grown diamond',
										title      : 'Lab-grown Diamond',
										description: 'Explore eco-friendly lab-grown diamonds, from classic D-Z shades to rare, captivating pink, blue, and green hues.'
									})}

					{OurProductCard({
										src        : "images/homepage_image/gemstone.jpg",
										alt        : 'lab-grown diamond',
										title      : 'gemstone',
										subText    : 'Coming Soon ...',
										description: 'Explore rare gemstones, each with unique, captivating hues, perfect for timeless elegance.'
									})}
				</Row>
			</div>

		</section>
		{/* END OUR PRODUCT SECTION */}

		{/* START HOW IT USE SECTION */}
		<section className='timeline-container'>
			<div className='container'>
				<div className='timeline'>
					<Title level={2} className='homepage-title' data-aos="fade-up">How it Works</Title>
					<Text className='homepage-description pb-3' data-aos="fade-up">Buy Diamonds in 3 easy Steps </Text>
					<Row className='timeline-1' justify='center' data-aos="fade-up">
						<div className='hiw-leftborder'></div>
						<span className='circle-1'>1</span>
						<Col md={12} className='p-4 timeline-img-div'>
							<img src='images\homepage_image\buy_diamonds.png'
								 className='homepage-images'
								 alt='buy diamonds' />
						</Col>
						<Col md={12} className='p-4 d-flex align-items-center'>
							<div className='timeline-content'>
								<Title level={4} className='card-title timeline-title'>Browse and Choose
																					   Diamonds</Title>
								<Text className='homepage-description text-center'>
									Explore a diverse range of diamonds from various suppliers.Select the ones that
									match your preferences and requirements.
								</Text>
							</div>
						</Col>
					</Row>
					<Row className='timeline-2' data-aos="fade-up">
						<span className='circle-2'>2</span>
						<Col md={12} className='p-4 d-flex align-items-center'>
							<div className='timeline-content'>
								<Title level={4} className='card-title timeline-title'>Consolidation and Quality
																					   Check</Title>
								<Text className='homepage-description text-center'>
									We gather and inspect your chosen diamonds, ensuring standard quality.Tailor the
									order to meet any special requirements before shipping.
								</Text>
							</div>
						</Col>
						<Col md={12} className='p-4 timeline-img-div'>
							<img src='images\homepage_image\Consolidate_order.png'
								 className='homepage-images'
								 alt='Consolidation and Quality Check' />
						</Col>
					</Row>
					<Row className='timeline-3' data-aos="fade-up">
						<div className='hiw-leftborder'></div>
						<span className='circle-3'>3</span>
						<Col md={12} className='p-4 timeline-img-div'>
							<img src='images\homepage_image\payment.png'
								 className='homepage-images'
								 alt='payment' />
						</Col>
						<Col md={12} className='p-4 d-flex align-items-center'>
							<div className='timeline-content'>
								<Title level={4} className='card-title timeline-title'>Convenient Delivery and
																					   Payment</Title>
								<Text className='homepage-description text-center'>
									Receive a single delivery for all your ordered diamonds.With our cash on
									delivery option, you can pay upon receipt of your order, simplifying the process
									without the need for international charges or
									transactions.
								</Text>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</section>
		{/* END HOW IT USE SECTION */}

		{/* START SERVICE SECTION */}
		<section className={`service-container ${containerClassName}`}>
			<Title level={2} className='homepage-title' data-aos="fade-up">Benefits Of Partnering With Us</Title>
			<Text className='homepage-description pb-3' data-aos="fade-up">Grow your jewelry business effortlessly while
																		   we support your needs</Text>
			<div className='our-product-cards'>
				<Row gutter={[40, 40]} justify='center'>
					<Col xs={24} sm={24} md={12} lg={10} xl={7} data-aos="fade-up">
						<div className="serviceBox">
							<div className="service-icon">
								<i className="fa fa-globe"></i>
							</div>
							<Title level={4} className="title">Millions of Diamonds Available</Title>
							<p className="description">Explore a vast collection of diamonds at your
													   convenience.</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={10} xl={7} data-aos="fade-up">
						<div className="serviceBox">
							<div className="service-icon">
								<FileSearchOutlined />
							</div>
							<Title level={3} className="title">Streamlined Selection Process</Title>
							<p className="description">Access thousands of global suppliers through a single point
													   of contact, empowering you to select the ideal diamond for your specific needs.</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={10} xl={7} data-aos="fade-up">
						<div className="serviceBox">
							<div className="service-icon">
								<img src='images\homepage_image\purchase_logo.png'
									 alt='purchase' />
							</div>
							<Title level={4} className="title">Effortless Purchasing</Title>
							<p className="description">Focus on your sales and development as we handle purchasing,
													   quality control, shipping, and payment, minimizing your hassle.</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={10} xl={7} data-aos="fade-up">
						<div className="serviceBox">
							<div className="service-icon">
								<i className="fa fa-truck"></i>
							</div>
							<Title level={4} className="title">Swift Shipping</Title>
							<p className="description">Experience rapid delivery ensuring your diamonds reach you
													   promptly and securely.</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={10} xl={7} data-aos="fade-up">
						<div className="serviceBox">
							<div className="service-icon">
								<UserSwitchOutlined />
							</div>
							<Title level={4} className="title">Responsive Customer Support</Title>
							<p className="description">Access quick and reliable customer support whenever you need
													   assistance, ensuring a smooth experience throughout.</p>
						</div>
					</Col>
				</Row>
			</div>
		</section>
		{/* END SERVICE SECTION*/}

	</>
}
export default HomePage