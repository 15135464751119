import {createSlice} from "@reduxjs/toolkit";
import {fetchAddresses} from "./address-action";
import {userRegistration} from "../auth/auth-action";

export const AddressBookReducer = createSlice(
	{
		name         : "addressBook",
		initialState : {address: []},
		reducers     : {},
		extraReducers: (builder) => {
			builder.addCase(fetchAddresses.fulfilled, (state, action) => {
								return void (state.address = action.payload)
							}
			)
		}
	})

export default AddressBookReducer.reducer;