import React, {useMemo, useState} from 'react'
import {FloatButton} from "antd";
import Routing from "../../routing/index.jsx";
import './Icon.css'
import FooterPage from '../../layouts/footer/Footer';
import HeaderPage from '../../layouts/header/Header.jsx';
import Topbar from '../../layouts/header/Topbar.jsx';
import {isNull} from "lodash";

const DashBoard = () => {

	const [didMount, setDidMount] = useState(false);
	const user = localStorage.getItem("userName")
	let message

	useMemo(() => {
		if(!isNull(user)) message = `Hello, I'm ${user} and I want to buy a diamond from DiaHearts.com. But, I need to have a conversation before making a decision.`
		else message = "Hello, I want to buy a diamond from DiaHearts.com. But, I need to have a conversation before making a decision."
	}, [didMount])

	return (
		<div className='dashboard'>
			<Topbar />
			<HeaderPage />
			<div className="home_page">
				<Routing />
			</div>
			<div className="footer"><FooterPage /></div>
			<a href={`https://wa.me/+447842662007?text=${message}`} className="whatsapp_float" target="_blank" rel="noopener noreferrer"
			   onClick={() => setDidMount((save) => !save)}> <i className="fa fa-whatsapp whatsapp-icon"></i>
			</a>
			<FloatButton.BackTop type="primary" className='backtop' />
		</div>
	)
}

export default DashBoard;