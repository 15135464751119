import {createAsyncThunk} from "@reduxjs/toolkit";
import {Api} from "../../helper/api/api";
import {isEqual, isNil} from 'lodash'

// User Enquiry Request
export const enquiryRequest = createAsyncThunk(
	"checkout/enquiryRequest",
	async(data) => {
		return await Api.post("/enquiry/make", data, {headers: {"Content-Type": "multipart/form-data",},})
						.then((res) => { return {data: res?.data, status: res.status}; })
						.catch((error) => { return error });
	}
);

// Calling order - Api with async thunk
export const fetchEnquiryHistory = async(page) => {
	return await Api.get(`/enquiry?page=${page}`)
					.then((res) => {
						// loop for convert string to array for request data
						if(isEqual(res.status, 200)) {
							for(let i = 0; i < res.data.data.length; i++) {
								let element = res.data.data[i];
								element.request_for = JSON.parse(element.request_for);
								// Convert "[null]" to []
								if(Array.isArray(element.request_for) && isEqual(element.request_for.length, 1) && isNil(element.request_for[0])) element.request_for = []
							}
						}

						return {data: isNil(res.data.data) ? [] : res.data.data, total: res.data.total || 0, per_page: res.data.per_page || 0};
					})
					.catch((err) => err);
}
