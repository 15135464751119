import {createAsyncThunk} from "@reduxjs/toolkit";
import {Api} from "../../helper/api/api";
import axios from "axios";
import {URL} from "../../helper/other/url-helper";
import {removeData} from "../../helper/other/common";
import store from "../index";
import {removeToken} from "../auth/auth-slice";

// Calling diamond Api
export const fetchDiamonds = createAsyncThunk(
	'diamondData/fetchDiamonds',
	async() => {
		return await Api.get("/all").then(res => {
			return res;
		}).catch((error) => {
			return error
		});
	}
);

let abortController = new AbortController();
// Calling diamond - filter Api with async thunk
export const filterDiamondFetch = createAsyncThunk(
	'diamondData/filterDiamondFetch',
	async({data, page, pageIncrement, navigate}) => {
		try {
			abortController.abort();
			abortController = new AbortController();
			const token = localStorage.getItem("token");
			const response = await axios.post(`${URL}/filter/${pageIncrement ? page + 1 : 1}`, data, {
				headers: {Authorization: `Bearer ${token}`},
				signal : abortController.signal
			});
			const filterObj = {
				page: pageIncrement ? page + 1 : 1,
				data: response.data.length
			}
			localStorage.setItem("filterObj", JSON.stringify(filterObj))

			for(let i = 0; i < response.data.length; i++) {
				let element = response.data[i];
				element.discount = parseFloat(element.discount.toString()[0] === "-" ? element.discount : -element.discount).toFixed(2)
			}
			return {data: response?.data === undefined || response?.data === 204 ? [] : response?.data, page: pageIncrement ? page + 1 : 1, status: response.status}
		} catch(error) {
			if(error?.response?.status === 500 || error.code === "ERR_NETWORK") navigate('/serverError')
			else if(error?.response?.status == 403) {
				localStorage.setItem("email_verified", "false");
				navigate('/email/verify')
			} else if(error?.response?.status == 401) {
				removeData()
				store.dispatch(removeToken())
				navigate('/login')
			} else if(error?.response?.status == 429) navigate('/many-attempt')
			return {error}
		}
	}
);

export const fetchSingleDiamond = createAsyncThunk(
	"diamondData/fetchSingleDiamond",
	async(id) => {
		return await Api.get(`/single/${id}`).then((res) => {
			res.data.discount = parseFloat(res.data.discount.toString()[0] === "-" ? res.data.discount : -res.data.discount).toFixed(2)
			return {data: res.data}
		}).catch((err) => {
			return {err};
		});
	}
);