import React, {useEffect, useState} from "react";
import {Button, Card, Col, Descriptions, Divider, Row, Spin, Table, Tag, Typography,} from "antd";
import './Order.css';
import {hideOthers} from "aria-hidden";
import {useNavigate, useParams} from "react-router-dom";
import {SafetyCertificateFilled} from '@ant-design/icons';
import DiamondConstData from "../../helper/constant/diamond-constant";
import {fetchSingleOrder,} from "../../redux/order/order-action";
import {useDispatch, useSelector} from "react-redux";
import {dateFormatting, diamondString, formatPrice, isEmpty, pageName} from "../../helper/other/common";
import _ from 'lodash';
import PreviewImage from "../../components/utils/image-view/PreviewImage";

const {Title, Text} = Typography;
const OrderDetailsPage = () => {
	const contentRef = React.useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {orderId} = useParams();
	const [state, setState] = useState({})
	const [isLoading, setIsLoading] = useState(false)

	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting

	//fetch order details
	const fetchOrderDetail = async() => {
		setIsLoading(true)
		const orderDetail = await dispatch(fetchSingleOrder(orderId));
		setState(orderDetail.payload.data)
		orderDetail.payload.data && setIsLoading(false)
	};

	useEffect(() => {
		const content = contentRef.current;
		if(content) return hideOthers(content);
		fetchOrderDetail()
	}, []);

	// set shippiing details using 'is_shipping_address' value
	const shipping_details = state?.order?.is_shipping_address === 1 ? JSON.parse(state?.order?.shipping_details) : state?.order?.billing_details;

	const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];

	const d = state?.created_at !== null ? new Date(state?.created_at) : new Date();

	//when get error while loading the image in table data
	const onImageLoadingError = (e, imagePath) => e.currentTarget.src = imagePath

	//show order diamond Title in table
	const DiamondItemTitle = (data) => {
		const diamond = data.diamond
		return <>
			{diamond ? <p>
						 {diamondString(diamond)} |<br />
						 {diamond?.lab}:
						 <Text copyable={{text: diamond?.cert_number, tooltips: [`Copy: ${diamond?.cert_number}`, "Copied"]}} strong className='ps-1'>
							 {diamond?.cert_number}
						 </Text>
						 {data?.note && (<><br />Note: <Text strong className='ps-1'>{data?.note}</Text></>)}
					 </p> :
			 <SafetyCertificateFilled className='d-flex justify-content-center fs-5' />}
			{state.currency !== "USD" && <>
				<Text className='fs-6'>USD: </Text>
				{DiamondConstData.multi_currency["USD"]?.symbol}{formatPrice(state?.sub_total)} (Exchange Rate: ${state?.exchange_rate})
			</>}
		</>
	}

	//show order diamond image in table
	const DiamondItemImage = (data) => {
		let lastIndexFileExt = data?.img_link?.lastIndexOf(".");
		let fileExt = data?.img_link?.slice(lastIndexFileExt + 1)
		const img_link = isEmpty(data?.img_link)
		const imagePath = DiamondConstData.shape_image[`${data?.shape}`] === undefined ? DiamondConstData.shape_image["Default"] : DiamondConstData.shape_image[`${data?.shape}`]
		const fileExtArr = ["jpg", "jpeg", "JPG", "JPEG", "png", "PNG"]
		const isFileExtension = _.includes(fileExtArr, fileExt)
		let imgLinkSrc = (!img_link && isFileExtension) ? data?.img_link : imagePath
		return <div className='d-grid justify-content-center order-image'>
			<PreviewImage src={imgLinkSrc} width={60} height={60} alt="Diahearts diamond" onError={(e) => onImageLoadingError(e, imagePath)} />
		</div>
	}

	//diamond items column
	const itemDetailColumns = [
		{
			title    : <Title level={5} className='table-title'>Id</Title>,
			dataIndex: "id",
			key      : "id",
		},
		{
			title    : <Title level={5} className='table-title'>Description</Title>,
			dataIndex: "diamond",
			key      : "diamond",
			render   : (diamond) => DiamondItemTitle(diamond)
		},
		{
			title    : <Title level={5} className='table-title'>Subtotal</Title>,
			dataIndex: "sub_total",
			key      : "sub_total",
			render   : (text) => `${DiamondConstData.multi_currency[state.currency]?.symbol}${formatPrice(text)}`
		},
		{
			title    : <Title level={5} className='table-title'>Image</Title>,
			dataIndex: "image",
			key      : "image",
			render   : (data) => DiamondItemImage(data)
		},
	];

	// diamond data
	const diamondData = [
		{
			key      : "1",
			id       : state?.diamond_id,
			diamond  : state,
			sub_total: state?.display_price,
			image    : state?.diamond,
		},
	];

	//set the address value
	const AddressDetails = (value) => {
		return <>
			{value?.address_1 && <>{value?.address_1}{shipping_details?.address_1.length > 14 && <br />} </>}
			{value?.address_2 && <>{value?.address_2}<br /></>}
			{value?.city && <>{value?.city},</>}
			{value?.postcode && <>{value?.postcode}<br /></>}
			{value?.county && <>{value?.county}</>}
		</>
	}

	// billing details columns
	const billingDetails = [
		{
			key     : "1",
			label   : "Company",
			children: state?.order?.billing_details?.company_name || '-'
		},
		{
			key     : "2",
			label   : "Name",
			children: `${state?.order?.billing_details?.first_name} ${state?.order?.billing_details?.last_name}`
		},
		{
			key     : "3",
			label   : "Address",
			children: AddressDetails(state?.order?.billing_details)
		},
		{
			key     : "4",
			label   : "Email",
			children: state?.order?.billing_details?.email_address || "-"
		},
		{
			key     : "5",
			label   : "Status",
			children: <Tag color={global_status?.status?.order_status_color[state.status]} className='status-tag'>{global_status?.status?.order_status[state.status]}</Tag>
		},
		{
			key     : "6",
			label   : "Payment-Method",
			children: global_status?.status?.payment_method[state?.order?.payment_method]?.replace("Cash", "Pay")
		},
	];

	// shipping details columns
	const shippingDetails = [
		{
			key     : "1",
			label   : "Name",
			children: `${shipping_details?.first_name} ${shipping_details?.last_name}`
		},
		{
			key     : "2",
			label   : "Address",
			children: AddressDetails(shipping_details)
		},
		{
			key     : "3",
			label   : "Est. Delivery Date",
			children: dateFormatting(state?.order?.estimated_delivery_date) || '-'
		},
		{
			key     : "4",
			label   : "Note",
			children: state?.order?.note || '-'
		},
	];

	// description label style
	const descriptionLabelStyle = {fontWeight: "bold", fontSize: "15px", color: "black", paddingBottom: "0px"}

	// description content style
	const descriptionContentStyle = {fontSize: "14px", paddingBottom: "10px"}

	useEffect(() => {
		document.title = `Orders - ${pageName}`;
	}, []);

	//set invoice date format
	const date = d.getUTCDate() + " " + month[d.getUTCMonth()] + "," + d.getUTCFullYear()

	return (
		<Row justify="center" className='order-detail-container'>
			{
				isLoading ? <Spin size="large" /> : <>
					<Title level={2} className='mb-3 page-title'>Order #{state.id}</Title>
					<Row justify="center">
						<Col sm={24} md={18} lg={15} xl={12} xxl={9}>
							<Card title={<Row>
								<Col xs={13} sm={12} md={12} lg={13} xl={14} xxl={15} className='order-title'>
									<Title level={2}>Order</Title>
								</Col>
								<Col xs={13} sm={12} md={12} lg={11} xl={10} xxl={9} className='d-flex text-capitalize align-items-center'>
									<Text className="invoice_title">Order Date : </Text>
									<Text className="invoice_data">{date}</Text>
								</Col>
							</Row>}>
								<Row>
									<Col xs={17} sm={12} xs={24}>
										<Descriptions column={1} labelStyle={descriptionLabelStyle} items={billingDetails} title={<Title level={3}>Billing Information</Title>}
													  contentStyle={descriptionContentStyle} />
									</Col>
									<Col xs={17} sm={12} xs={24}>
										<Descriptions column={1} labelStyle={descriptionLabelStyle} items={shippingDetails} title={<Title level={3}>Shipping Information</Title>}
													  contentStyle={descriptionContentStyle} />
									</Col>
								</Row>
								<Divider dashed={true} className='m-0' />
								<Title level={3} className='mt-2'>Product Detail</Title>
								<Table dataSource={diamondData} columns={itemDetailColumns} bordered pagination={false} className="order_table" scroll={{x: 'max-content'}} />
							</Card>
							<div className='pagination'>
								<Button type="primary" onClick={() => navigate("/order")}>BACK</Button>
							</div>
						</Col>
					</Row>
				</>
			}
		</Row>
	);
};
export default OrderDetailsPage;
