import {createSlice} from "@reduxjs/toolkit";

const storeData = localStorage.getItem("add_to_cart")

export const CartDataReducer = createSlice(
    {
        name: "cartData",
        initialState: storeData ? JSON.parse(storeData).data : [],
        reducers: {
            setCartData: (state, action) => {
                return [...state, {...action.payload, note : ''}]
            },
            removeCartData: (state, action) =>
                state.filter((data) => data?.id !== action.payload?.id),
            updateCartData: (state, action) => {
                const updatedCart = action.payload.cart;
                return state.map(cart => {
                    if (cart.id === updatedCart.id) {
                        return updatedCart;
                    }
                    return cart;
                });
            },
            removeAllCartData: (state, action) =>
                state = []
        }
    })

export const {setCartData, removeCartData, removeAllCartData,updateCartData } = CartDataReducer.actions;
export default CartDataReducer.reducer;