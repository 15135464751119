import {Button, Col, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {FANCY_OVERTONE} from "../../../../helper/constant/filter-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography;
const FilterFancyOverton = ({filterData, setFilterData}) => {

		const [selectItem, setSelectItem] = useState({low: null, high: null,});
		const data = filterData.fancy_color_overtone

		useEffect(() => {
			const fancyColorArr = slice(FANCY_OVERTONE, selectItem.low, selectItem.high + 1)
			if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, fancy_color_overtone: []})
			else setFilterData({...filterData, fancy_color_overtone: fancyColorArr})
		}, [selectItem]);

		useEffect(() => {
			const length = size(data)
			if(isEqual(length, 1)) {
				let index = indexOf(FANCY_OVERTONE, data[0])
				setSelectItem({low: index, high: index})
			} else if(length > 0) {
				let firstIndex = indexOf(FANCY_OVERTONE, data[0])
				let lastIndex = indexOf(FANCY_OVERTONE, data[length - 1])
				setSelectItem({low: firstIndex, high: lastIndex})
			}
		}, [])

		// Handle onColorCard click
		const handleColorCardClick = (i) => {
			const {low, high} = selectItem;
			if(isEqual(i, low) && isEqual(i, high)) setSelectItem({low: null, high: null})
			else if(isNil(low) && isNil(high)) setSelectItem({low: i, high: i});
			else if(!isEqual(low, high) || isNil(low) || isNil(high)) setSelectItem({low: i, high: i})
			else if(i >= high) setSelectItem({...selectItem, high: i})
			else setSelectItem({...selectItem, low: i});
		}

		return (
			<>
				<Title level={5} className='filter-title'> overtone </Title>
				<Row wrap gutter={[8, 8]}>
					{FANCY_OVERTONE.map((color, i) => {
						const buttonType = includes(data, color) ? "primary" : "default"
						return <Col key={color}> <Button type={buttonType} onClick={() => handleColorCardClick(i)}>{color}</Button> </Col>
					})}
				</Row>
			</>
		);
	}
;

export default FilterFancyOverton;
