import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Result} from "antd";
import VerifyEmail from '../../pages/email/VerifyEmail';
import {pageName} from '../../helper/other/common';
import {isEqual} from "lodash";


const ErrorPage = () => {
	let diamonds = useSelector((state) => state.diamondData);
	useEffect(() => {
		document.title = `Error Page -  ${pageName}`;
	}, []);
	return (
		<>
			{isEqual(diamonds.error.status, 403) && isEqual(diamonds.error.data.message, "Your email address is not verified.") ? <VerifyEmail /> :
			 <Result
				 status="403"
				 title="403"
				 subTitle="Sorry, you are not authorized to access this page."
			 />}
		</>
	)
}

export default ErrorPage
