import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Form, Input, Typography,} from "antd";
import {useNavigate} from "react-router-dom";
import {resetPassword} from "../redux/auth/auth-action";
import {isEmpty, pageName} from "../helper/other/common";
import AlertMessage from "../helper/other/AlertMessage";
import {isEqual} from 'lodash'
import {setForgotPasswordMessage} from "../redux/auth/auth-slice";

const {Title} = Typography;
const ResetPasswordPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [message, setMessage] = useState({type: "", message: "", show: false})
	const [form] = Form.useForm();
	const passwordInfo = useSelector((state) => state.auth);
	const params = new URLSearchParams(window.location.search);

	// Token from params
	const token = params.get("token");

	// Submit reset password with mail processing
	const onSubmit = async(values) => {
		values.token = token;
		const res = await dispatch(resetPassword({data: values, navigate: navigate, dispatch: dispatch}));
		res && form.resetFields();
		if(isEqual(res.payload.status, 200)) {
			const resMessage = `${res.payload.data.message}, You can login now.`
			navigate('/login')
			dispatch(setForgotPasswordMessage({message: resMessage, show: true}))
		} else {
			setMessage({type: "error", message: res.payload, show: true})
			dispatch(setForgotPasswordMessage({message: res.payload, show: false}))
		}
	};

	useEffect(() => {
		isEmpty(token) && navigate("/login");
		document.title = `Reset Password -  ${pageName}`;
	});

	// Form validation with promise method
	const fieldValidation = ({getFieldValue}, field, message = 'Values are not matching') => ({
		validator(_, value) {
			if(!value || getFieldValue(field) === value) {
				return Promise.resolve();
			}
			return Promise.reject(
				new Error(message)
			);
		},
	});

	// Field validation rules
	const fieldValidationRules = {
		email           : [
			{
				type   : "email",
				message: "Invalid Email"
			},
			{
				required: true,
				message : "Email is Required"
			},
		],
		password        : [
			{
				required: true,
				message : "Password is Required"
			}
		],
		confirm_password: [
			{
				required: true,
				message : "Please Enter Confirm password!"
			},
			({getFieldValue}) => fieldValidation({getFieldValue}, "password", "The Password do not match!")
		],
	}

	return (
		<div className='reset-container container'>
			<Title className="text-center page-title">Reset Password</Title>
			<div className="d-flex justify-content-center">
				<Card className='reset'>
					<Form layout="vertical"
						  form={form}
						  onFinish={onSubmit}
						  className="fw-bold"
						  requiredMark={false}
						  encType="multipart/form-data">
						<Form.Item label="Email" name="email" hasFeedback rules={fieldValidationRules.email}>
							<Input />
						</Form.Item>
						<Form.Item label="Password" name="password" hasFeedback rules={fieldValidationRules.password}>
							<Input.Password />
						</Form.Item>
						<Form.Item label="Confirm Password" name="password_confirmation" dependencies={["password"]} hasFeedback rules={fieldValidationRules.confirm_password}>
							<Input.Password />
						</Form.Item>
						<Form.Item className="d-flex justify-content-center">
							<Button type="primary" htmlType="submit" className="text-uppercase mt-1" loading={passwordInfo.resetPassword.loading}> Reset Password </Button>
						</Form.Item>
					</Form>
					{/*Alert Message*/}
					{message.show && <AlertMessage message={message.message} type={message.type} />}
				</Card>
			</div>
		</div>);
};

export default ResetPasswordPage;
