import React, {useEffect, useState} from "react";
import {Button, Drawer, Segmented, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import FilterMainData from "../../../components/utils/diamond-filter/main";
import FilterAdvanceData from "../../../components/utils/diamond-filter/advance";
import {filterDiamondFetch} from "../../../redux/diamond/diamond-action";
import {clearFilter, scrollTo} from "../../../helper/other/common";
import {applyFilter, setFilterData, setIsLogin,} from "../../../redux/diamond/diamond-slice";
import {filterArray} from "../../../helper/constant/filter-constant";
import {isEqual, omit} from "lodash";

const FilterDataDrawer = ({isOpen, setIsOpenFilter, data, setData}) => {
	const dispatch = useDispatch();
	let diamonds = useSelector((state) => state.diamondData);
	const navigate = useNavigate()
	const [content, setContent] = useState("main");

	useEffect(() => {
		!isOpen && localStorage.setItem("reset", "false")
	}, [isOpen])

	/* Reset button event on Filter popup */
	const onResetBtnClick = () => {
		scrollTo()
		const filterData = clearFilter(data)
		const objData = omit({...filterData}, ['diamondPriority'])
		setData(filterData)
		dispatch(applyFilter([]))
		dispatch(setFilterData(objData))
		dispatch(filterDiamondFetch(
			{
				data         : {...objData, type: [isEqual(window.location.pathname, "/lab-grown") ? "lab_grown" : "natural"]},
				page         : diamonds?.page,
				pageIncrement: false,
				navigate
			}))
	};

	//segment options
	const options = [
		{
			label: "Main",
			value: "main",
		},
		{
			label: "Advance",
			value: "advance",
		},
	]

	// when close the filter drawer
	const onDrawerClose = () => {
		setIsOpenFilter(false)
		setContent("main")
	}

	// when click on button main or advance
	const toggleFilterPanel = (value) => setContent(value);

	// when click on apply filter button
	const handleApplyFilter = () => {
		dispatch(setIsLogin(false))

		let deleteKeyArr = ['diamondPriority', 'sort_direction', 'sort_by', 'type']

		// delete unnecessary key and value pair
		let updatedCompareObj = omit({...data}, deleteKeyArr);
		let updateDefaultFilterObj = omit({...filterArray}, deleteKeyArr)

		// compare current and default object
		const result = isEqual(updatedCompareObj, updateDefaultFilterObj)

		scrollTo()
		let sort_by = result ? "" : data.sort_by ? data.sort_by : 'final_price'
		let sort_direction = result ? "" : data.sort_direction ? data.sort_direction : 'asc'

		const objData = omit({...data, sort_by: sort_by, sort_direction: sort_direction}, ['diamondPriority']);

		dispatch(setFilterData(objData))
		dispatch({type: "APPLY_FILTER"})
		dispatch(applyFilter([]))
		dispatch(filterDiamondFetch(
			{
				data         : {...objData, type: [isEqual(window.location.pathname, "/lab-grown") ? "lab_grown" : "natural"]},
				page         : diamonds?.page,
				pageIncrement: false,
				navigate
			}))
		setIsOpenFilter(false)
		setContent("main")
		setData({...data, sort_by: sort_by, sort_direction: sort_direction})
	}

	// Drawer style
	const drawerStyle = {body: 'pt-1', footer: 'text-end border-top-0'}

	return (
		<>
			{/*show filter data component*/}
			{isOpen && (
				<Drawer onClose={onDrawerClose} open={true} placement="left" width={550} extra={<Segmented options={options} onChange={toggleFilterPanel} size="large" />}
						footer={<Space>
							<Button onClick={onResetBtnClick}> Reset </Button>
							<Button className="hover-button" onClick={handleApplyFilter} type="primary"> Apply Filter </Button>
						</Space>}
						classNames={drawerStyle}>
					{isEqual(content, "main") && <FilterMainData filterData={data} setFilterData={setData} />}
					{isEqual(content, "advance") && <FilterAdvanceData filterData={data} setFilterData={setData} />}
				</Drawer>
			)}
		</>
	);
};

export default FilterDataDrawer;
