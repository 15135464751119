import React, {useEffect} from "react";
import DiamondsPage from "./DiamondsPage";
import {pageName} from "../../helper/other/common";

const LabGrownPage = () => {
	useEffect(() => {
		document.title = `Lab Grown -  ${pageName}`;
	});
	return <DiamondsPage type="lab_grown" />
};

export default LabGrownPage;
