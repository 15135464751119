import React, {useState} from "react";
import {Card, Divider, Segmented} from "antd";
import ShapeSizeLabData from "./Shape";
import FilterColorData from "./Color";
import FilterFancyColorData from "./FancyColor";
import FilterFancyOverton from "./FancyOverton";
import FilterFancyColorIntensity from "./FancyColorIntensity";
import FilterClarityData from "./Clarity";
import FilterCutData from "./Cut";
import FilterFlIntensityData from "./FlIntensity";
import FilterSizeData from "./Size";
import FilterLabData from "./Lab";
import {isEqual, size} from "lodash";
import FilterTreatmentData from "./Treatment";
import FilterAvailabilityData from "./Availability";

const FilterMainData = ({filterData, setFilterData}) => {
	const fancy_overtone = filterData.fancy_color_overtone
	const fancy_intensity = filterData.fancy_color_intensity
	const fancy_color = filterData.fancy_color

	// check white and fancy color
	let isWhiteColor = isEqual(size(fancy_overtone), 0) && isEqual(size(fancy_intensity), 0) && isEqual(size(fancy_color), 0)

	const [colorName, setColorName] = useState(isWhiteColor ? 'white' : 'fancy');

	// set color name for white and fancy
	const handleClick = (e) => setColorName(e);

	const options = [
		{
			label: "White",
			value: "white",
		},
		{
			label: "Fancy",
			value: "fancy",
		}
	]

	return (
		<div>
			{/* shape div */}
			<ShapeSizeLabData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			<FilterSizeData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			<FilterLabData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			<FilterAvailabilityData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />
			{/* color div  */}
			<Segmented options={options} onChange={(v) => handleClick(v)} value={colorName} />

			{isEqual(colorName, "white") && <FilterColorData filterData={filterData} setFilterData={setFilterData} />}
			{isEqual(colorName, "fancy") && <Card className='gray-filter-card'>
				<FilterFancyColorData filterData={filterData} setFilterData={setFilterData} />
				<Divider className='divider' />
				<FilterFancyOverton filterData={filterData} setFilterData={setFilterData} />
				<Divider className='divider' />
				<FilterFancyColorIntensity filterData={filterData} setFilterData={setFilterData} />
			</Card>
			}
			<Divider className='divider' />

			{/* clarity div  */}
			<FilterClarityData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			{/* cut div */}
			<FilterCutData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			{/* fl_intensity div */}
			<FilterFlIntensityData filterData={filterData} setFilterData={setFilterData} />
			<Divider className='divider' />

			{/* filterTreatment div*/}
			{window.location.pathname === "/lab-grown" && <>
				<FilterTreatmentData filterData={filterData} setFilterData={setFilterData} />
				<Divider className='divider' /></>}
		</div>
	);
};

export default FilterMainData;
