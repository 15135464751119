import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Card, Col, Form, Input, Result, Row} from 'antd';
import {FileProtectOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {getAccountInfo, submitKyc} from "../../redux/auth/auth-action";
import LoadingPage from "../../components/utils/loader/Loading";
import {pageName} from "../../helper/other/common";
import AlertMessage from "../../helper/other/AlertMessage";
import {includes, isEmpty, isEqual, isUndefined} from "lodash";
import {setIsPath, setKycSkip} from "../../redux/auth/auth-slice";

/**
 * Kyc form
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const KYCForm = ({setStatus}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState({isError: false, message: ""});

	const [companyFiles, setCompanyFiles] = useState({});
	const [govFiles, setGovFiles] = useState({});

	// Handle OnSubmit
	const onSubmit = async(value) => {
		value.company_proof = companyFiles;
		value.government_certificate = govFiles;
		setIsLoading(true);
		const res = await dispatch(submitKyc(value))
		if(isEqual(res.payload.status, 200)) {
			await dispatch(getAccountInfo({navigate}))
			setIsLoading(false);
			setStatus('after_form_submit');
		} else if(res.error.message) {
			setIsLoading(false);
			setMessage({isError: true, message: res.error.message})
		}
	};

	// Handle image change
	const onHandleChangeImage = (e, field) => {
		const fileSize = e.target.files[0]?.size
		if(isEqual(field, "company")) {
			setCompanyFiles(e.target.files[0])
			e.target.text = fileSize
		} else {
			setGovFiles(e.target.files[0])
			e.target.text = fileSize
		}
	}

	// Check image Size Validation
	const imageValidation = (id) => {

		const imageExtension = document.getElementById(id).value.split('.').pop()
		const extensionArr = ['jpeg', 'pjpeg', 'png', 'jpg', 'heic', 'pdf', '']

		//size validation
		const imageSize = document.getElementById(id)?.text
		const size = imageSize / 1024 / 1024 < 2

		if(!includes(extensionArr, imageExtension)) return Promise.reject('Invalid file format!')
		else if(!size && !isUndefined(imageSize)) return Promise.reject('Image must smaller than 2MB!')
		else return Promise.resolve();
	}

	// FieldValidation Rules
	const fieldValidationRules = {
		company_proof         : [
			{
				required: true,
				message : 'Please Enter Company registration document!',
			},
			{
				validator: (_, value) => imageValidation("companyProof")
			}
		],
		accept_file_extensions: "image/*,.jpeg,.pjpeg,.png,.jpg,.heic,application/pdf",
		government_certificate: [{validator: (_, value) => imageValidation("government")}],
	}

	const handleSkip = async () => {
		await dispatch(setKycSkip(true))
		navigate('/natural')
	}

	return (
		<Form className="fw-bold" encType="multipart/form-data" onFinish={onSubmit}>
			<Form.Item label="Company Registration Document" name="company_proof" rules={fieldValidationRules.company_proof}>
				<Input id="companyProof" type="file" name="company_proof" accept={fieldValidationRules.accept_file_extensions}
					   onChange={(e) => onHandleChangeImage(e, "company")} />
			</Form.Item>
			<Form.Item className="input-size" label="Government issued ID of Owner" name="government_certificate" rules={fieldValidationRules.government_certificate}>
				<Input id="government" type="file" accept={fieldValidationRules.accept_file_extensions}
					   onChange={(e) => onHandleChangeImage(e, "government")} />
			</Form.Item>
			<Form.Item>
				<Button className="fw-bold" htmlType="submit" type="primary" shape="round" size="large" loading={isLoading}>Submit</Button>
				<Button className="fw-bold ms-2" htmlType="button" type="primary" shape="round" size="large" onClick={handleSkip}>Skip</Button>
			</Form.Item>
			{message.isError && <AlertMessage message={message.message} type="error" closable={true} onClose={() => setMessage({isError: false, message: ""})} />}
		</Form>
	);
};

const Kyc = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const globalStatus = isEmpty(globalSetting) ? {} : globalSetting?.status?.kyc_status_message;

	const userInfo = useSelector((state) => state.auth.accountDetails);
	const [status, setStatus] = useState(userInfo?.data?.kyc_status);
	const token = localStorage.getItem('token')

	useEffect(() => {
		setStatus(userInfo?.data?.kyc_status);
	}, [userInfo?.loading]);

	useEffect(() => {
		const statusArr = [null, 'rejected', 'pending']
		const isStatus = (includes(statusArr, status))
		const allowSubmissionMessage = isStatus ? true : false
		document.title = `KYC - ${pageName}`;
		!token && navigate('/login');
		if(!allowSubmissionMessage) navigate('/natural');
		dispatch(setIsPath(true));
	}, [status, token]);


	// set the value in style object
	const style = {rowStyle: {opacity: userInfo?.loading ? '0.2' : 'unset'}}

	// Handle Kyc Title
	const kycTitle = status === "rejected" ? globalStatus?.headings?.rejected : globalStatus?.headings?.pending

	return (
		<>
			{userInfo?.loading && <LoadingPage />}
			<Row className="d-flex justify-content-center" style={style.rowStyle}>
				<Col>
					<Result icon={<FileProtectOutlined />} title={kycTitle} extra={<Card> <KYCForm setStatus={setStatus} /> </Card>} />
				</Col>
			</Row>
		</>);
};

export default Kyc;
