import {Button, Col, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react'
import DiamondConstData from "../../../../helper/constant/diamond-constant";
import {FL_INTENSITY} from "../../../../helper/constant/filter-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography

const FilterFlIntensityData = ({filterData, setFilterData}) => {

	const [selectItem, setSelectItem] = useState({low: null, high: null})
	const flIntensityData = filterData.fl_intensity

	useEffect(() => {
		const fl_intensityArr = slice(FL_INTENSITY, selectItem.low, selectItem.high + 1)
		if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, fl_intensity: []})
		else setFilterData({...filterData, fl_intensity: fl_intensityArr})
	}, [selectItem])

	useEffect(() => {
		const length = size(flIntensityData)
		if(isEqual(length, 1)) {
			let index = indexOf(FL_INTENSITY, flIntensityData[0])
			setSelectItem({low: index, high: index})
		} else if(length > 0) {
			let firstIndex = indexOf(FL_INTENSITY, flIntensityData[0])
			let lastIndex = indexOf(FL_INTENSITY, flIntensityData[length - 1])
			setSelectItem({low: firstIndex, high: lastIndex})
		}
	}, [])

	// Handle onCard click
	const handleCardClick = (i) => {
		const {low, high} = selectItem;
		if(isEqual(i, low) && isEqual(i, high)) setSelectItem({low: null, high: null})
		else if(isNil(low) && isNil(high)) setSelectItem({low: i, high: i});
		else if(!isEqual(low, high) || isNil(low) || isNil(high)) setSelectItem({low: i, high: i})
		else if(i >= high) setSelectItem({...selectItem, high: i})
		else setSelectItem({...selectItem, low: i});
	}

	return (
		<>
			<Title level={5} className='filter-title'>Fluorescence Intensities</Title>
			<Row gutter={[8, 8]}>
				{FL_INTENSITY.map((intensity, i) => {
					const buttonType = includes(flIntensityData, intensity) ? "primary" : "default"
					return <Col key={intensity}> <Button type={buttonType} onClick={() => handleCardClick(i)}>{DiamondConstData.fl_intensity[`${intensity}`]}</Button></Col>
				})}
			</Row>
		</>
	)
}

export default FilterFlIntensityData