import React from "react";
import {useDispatch} from "react-redux";
import {scrollTo} from "../../../../helper/other/common";
import {filterDiamondFetch} from "../../../../redux/diamond/diamond-action";
import {useNavigate} from "react-router-dom";
import Search from "antd/es/input/Search";
import {applyFilter, setFilterData} from "../../../../redux/diamond/diamond-slice";
import {debounce, isEqual, omit} from "lodash";

const FilterStockIdData = ({data, setData}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const value = data?.cert_number
	const filterData = {...data}
	omit(filterData, ['diamondPriority'])

	// Handle onchange stock-id and call the api
	const onChangeStockId = (e) => {
		const isLabGrown = window.location.pathname === "/lab-grown"
		const filterData = {...data , treatment: isLabGrown ? data.treatment : []}
		omit(filterData, ['diamondPriority'])

		setData(({...data, 'cert_number': e.target.value}))
		dispatch(setFilterData({...filterData, 'cert_number': e.target.value}))
		const delayDebounceFn = debounce(() => {
			scrollTo()
			dispatch(applyFilter([]))
			dispatch(filterDiamondFetch(
				{
					data         : {...filterData, type: [isEqual(window.location.pathname, "/lab-grown") ? "lab_grown" : "natural"], 'cert_number': e.target.value},
					page         : 1,
					pageIncrement: false,
					navigate
				}))
		}, 2000)
		return delayDebounceFn()
	}

	return (<Search placeholder="Enter Cert. no / Stock ID" allowClear value={value} onChange={onChangeStockId} size="large" className="serach-bar" />);
};

export default FilterStockIdData;
