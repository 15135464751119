import {createAsyncThunk} from "@reduxjs/toolkit";
import {abortApiCall, Api} from "../../helper/api/api";
import {isNil} from 'lodash'

// Calling order - Api with async thunk
export const fetchOrderHistory = async(page, filterData) => {
	return await Api.get(`/orders?page=${page}&order_id=${filterData?.order_id}&daterange=${filterData?.dateRange}&status=${filterData?.status}`)
					.then((res) => {
						for(let i = 0; i < res.data.data.length; i++) {
							let element = res.data.data[i];
							element.order.billing_details = JSON.parse(
								element.order.billing_details
							);
						}
						return {
							data    : res.data.data || [],
							total   : res.data.total,
							per_page: res.data.per_page,
						};
					})
					.catch((err) => err);
};

// Calling order - Api with async thunk
export const fetchSettingStatus = createAsyncThunk(
	"order/fetchSettingStatus",
	async() => {
		try {
			const response = await abortApiCall("get", "/settings", {})
			localStorage.setItem("global_status", isNil(response.data) ? JSON.stringify({}) : JSON.stringify(response.data));
			return {data: response.data};
		} catch(error) {
			return {error};
		}
	}
);

// Calling order - Api with async thunk
export const fetchSingleOrder = createAsyncThunk(
	"order/fetchSingleOrder",
	async(id) => {
		return await Api.get(`/orders/${id}`)
						.then((res) => {
							res.data.order.billing_details = JSON.parse(
								res.data.order.billing_details
							);
							return {data: res.data}
						})
						.catch((err) => {
							return {err};
						});
	}
);