export const CLARITY = [
	"FL",
	"IF",
	"VVS1",
	"VVS2",
	"VS1",
	"VS2",
	"SI1",
	"SI2",
	"I1",
];

export const COLOR = ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];

export const LOCATION = ["India", "Hong Kong", "United Kingdom", "USA", "Belgium", "China", "Canada", "UAE", "Japan"];

export const CUTSHAPE = ["3EX", "3VG", "3G", "Reset"]

export const COUNTRYCODE = {
	"India"         : "IN",
	"Hong Kong"     : "HK",
	"United Kingdom": "GB",
	"USA"           : "USA",
	"Belgium"       : "BE",
	"China"         : "",
	"Canada"        : "",
	"UAE"           : "",
	"Japan"         : "",
}


export const CUT = ["Excellent", "Very Good", "Good", "Fair", "Poor"];
export const CUT_SHAPE = {EXCELLENT: "Excellent", VERY_GOOD: "Very Good", GOOD: "Good",};


export const FANCY_COLOR = [
	{name: "yellow", code: "#ffff00"},
	{name: "pink", code: "#ff005c"},
	{name: "orange", code: "#ffa500"},
	{name: "green", code: "#006400"},
	{name: "gray", code: "#aaaaaa"},
	{name: "brown", code: "#493323"},
	{name: "blue", code: "#0000ff"},
	{name: "black", code: "#000"},
];

export const FANCY_OVERTONE = ["None", "Yellow", "Yellowish", "Pink", "Pinkish", "Blue", "Bluish", "Red", "Reddish",];

export const FANCY_COLOR_INTENSITY = ["Fancy", "Very Light", "Light ", "Fancy Light", "Fancy Deep", "Fancy Intense", "Fancy Vivid",];

export const FL_COLOR = [
	{name: "Blue", code: "#0000ff"},
	{name: "Yellow", code: "#ffff00"},
	{name: "Green", code: "#006400"},
	{name: "Red", code: "#dc143c"},
	{name: "Orange", code: "#ffa500"},
	{name: "White", code: "#fff"},
];

export const GIRDLE = ["ETN", "VTN", "TN", "STN", "M", "STK", "TK", "VTK", "ETK",];

export const SORT = ["PRICE", "WEIGHT"];

export const LABDATA = ["gia", "igi", "ags", "hrd", "none"];

export const TREATMENTDATA = ["cvd", "hpht", "none"];

export const AVAILABILITYDATA = ["Available", "On Memo"];

export const POLISH = ["Excellent", "Very Good", "Good", "Fair", "Poor"];

export const SYMMETRY = ["Excellent", "Very Good", "Good", "Fair", "Poor"];

export const FL_INTENSITY = ["None",  "Very Slight", "Slight", "Faint", "Medium", "Strong", "Very Strong"];

export const DIAMONDSHAPE = [
	{name: "Round", image: "images/shape_pic/round-1.png"},
	{name: "Marquise", image: "images/shape_pic/marquise-1.png"},
	{name: "Princess", image: "images/shape_pic/princess-1.png"},
	{name: "Pear", image: "images/shape_pic/pear-1.png"},
	{name: "Emerald", image: "images/shape_pic/emerald-1.png"},
	{name: "Heart", image: "images/shape_pic/heart-1.png"},
	{name: "Oval", image: "images/shape_pic/oval-1.png"},
	{name: "Cushion", image: "images/shape_pic/cushion-1.png"},
	{name: "Radiant", image: "images/shape_pic/radiant-1.png"},
	{name: "Asscher", image: "images/shape_pic/asscher-1.png"},
	{name: "Trilliant", image: "images/shape_pic/trilliant.png"},
	{name: "Calf", image: "images/shape_pic/calf.png"},
	{name: "Kite", image: "images/shape_pic/kite.png"},
	{name: "Half moon", image: "images/shape_pic/half_moon.png"},
	{name: "Trapezoid", image: "images/shape_pic/trapezoid.png"},
	{name: "Octagonal", image: "images/shape_pic/octagonal.png"},
	{name: "Hexagonal", image: "images/shape_pic/hexagonal.png"},
	{name: "Pentagonal", image: "images/shape_pic/pentagonal.png"},
	{name: "Sq. emerald", image: "images/shape_pic/square_emerald.png"},
	{name: "Star", image: "images/shape_pic/star.png"},
	{name: "Briolette", image: "images/shape_pic/briolette.png"},
	{name: "Shield", image: "images/shape_pic/shield.png"},
	{name: "Flanders", image: "images/shape_pic/flanders.png"},
	{name: "Triangular", image: "images/shape_pic/triangle.png"},
	{name: "Square", image: "images/shape_pic/square.png"},
	{name: "Lozenge", image: "images/shape_pic/lozenge.png"},
	{name: "Baguette", image: "images/shape_pic/baguette.png"},
	{name: "Tapered baguette", image: "images/shape_pic/tapered_baguette.png"},
];

export const CARAT_SIZE_DATA = [
	{size: "20s", lowsize: "0.20", highsize: "0.29"},
	{size: "30s", lowsize: "0.30", highsize: "0.39"},
	{size: "40s", lowsize: "0.40", highsize: "0.49"},
	{size: "50s", lowsize: "0.50", highsize: "0.59"},
	{size: "60s", lowsize: "0.60", highsize: "0.69"},
	{size: "70s", lowsize: "0.70", highsize: "0.79"},
	{size: "80s", lowsize: "0.80", highsize: "0.89"},
	{size: "90s", lowsize: "0.90", highsize: "0.99"},
	{size: "1ct", lowsize: "1.00", highsize: "1.19"},
	{size: "1.2ct", lowsize: "1.20", highsize: "1.49"},
	{size: "1.5ct", lowsize: "1.50", highsize: "1.99"},
	{size: "2ct", lowsize: "2.00", highsize: "2.49"},
	{size: "2.5ct", lowsize: "2.50", highsize: "2.99"},
	{size: "3ct", lowsize: "3.00", highsize: "3.99"},
	{size: "4ct+", lowsize: "4.00", highsize: "4.99"},
];

export const filterArray = {
	shape                : [],
	weight               : {min: "", max: ""},
	color                : [],
	fancy_color          : [],
	fancy_color_overtone : [],
	fancy_color_intensity: [],
	clarity              : [],
	lab                  : [],
	treatment			 : [],
	address_location     : [],
	cut                  : [],
	polish               : [],
	symmetry             : [],
	fl_intensity         : [],
	fl_clr               : [],
	girdle_thick         : [],
	girdle_thin          : [],
	cert_number          : "",
	final_price          : {min: "", max: ""},
	depth                : {min: "", max: ""},
	table                : {min: "", max: ""},
	ratio                : {min: "", max: ""},
	sort_direction       : "",
	sort_by              : "",
	availability 		 : "",
	measurement_length   : {min: "", max: ""},
	measurement_width    : {min: "", max: ""},
	measurement_depth    : {min: "", max: ""},
	type                 : ["natural"],
	diamondPriority      : []
};

export const newKeys = {
	"fancy_color"          : "fancyColor",
	"fancy_color_overtone" : "fancyColorOvertone",
	"fancy_color_intensity": "fancyColorIntensity",
	"fl_intensity"         : "Intensity",
	"fl_clr"               : "clr",
	"girdle_thick"         : "girdleThick",
	"girdle_thin"          : "girdleThin",
	"stock_id"             : "stockId",
	"cert_number"          : "certificateNumber",
	"final_price"          : "finalPrice",
	"sort_direction"       : "sortDirection",
	"sort_by"              : "sortBy",
	"measurement_length"   : "mLength",
	"measurement_width"    : "mWidth",
	"measurement_depth"    : "mDepth",
};

export const filterObjectDataKey = ["weight", "final_price", "ratio", "depth", "table", "measurement_length", "measurement_width", "measurement_depth",];

export const filterSingleDataKey = ["shape", "lab", "fancy_color", "fl_clr", "girdle_thick", "treatment"];

export const filterRangeDataKey = ["color", "fancy_color_overtone", "fancy_color_intensity", "clarity", "fl_intensity", "cut", "polish", "symmetry",];

export const filterSingleValueKey = ["availability", "cert_number"];

export const LAB_URL = {
	'GIA': 'https://www.gia.edu/report-check?reportno',
	'IGI': 'https://www.igi.org/verify-your-report/?r',
	'HRD': 'https://my.hrdantwerp.com/Download/GetGradingReportPdf/?reportNumber',
	'AGS': 'https://agslab.com/ym-vdgr/en-us/login?id'
}

export const IMAGE_NAME = "amazonaws"

export const filterStatus = [
	{value: "all", label: 'All'},
	{value: "processing", label: 'Processing'},
	{value: "confirmed", label: 'Confirmed'},
	{value: "in_transit", label: 'In Transit'},
	{value: "in_customs", label: 'In Customs'},
	{value: "delivered", label: 'Delivered'},
	{value: "completed", label: 'Completed'},
	{value: "cancelled", label: 'Cancelled'},
	{value: "refunded", label: 'Refunded'},
]

export const orderFilterObj = {
	order_id: "",
	status       : "All",
	dateRange    : ""
}

export const userInitialValue = {
	first_name       : "",
	last_name        : "",
	company          : "",
	email            : "",
	mobile           : "",
	kyc_status       : "",
	status           : "",
	email_verified_at: "",
	kycData          : {
		id              : 0,
		customer_id     : 0,
		company_proof   : "",
		government_certi: null,
		created_at      : "",
		updated_at      : "",
		deleted_at      : null
	},
	address          : {
		id        : 0,
		user_id   : 0,
		company_name: null,
		country   : "",
		address_1 : "",
		address_2 : "",
		county    : "",
		city      : "",
		postcode  : "",
		created_at: "",
		updated_at: "",
		deleted_at: null
	}
}
