import React from 'react'

import {Divider} from 'antd';
import FilterPriceData from './Price';
import FilterFluColor from './FluColor';
import FilterGirdle from './Girdle';
import FilterDepthData from './Depth';
import FilterTableData from './Table';
import FilterMeasurementData from './Measurement';

// Advance Filter Root
const FilterAdvanceData = ({filterData , setFilterData}) => {
    return (
        <div>
            {/* pricediv */}
            <FilterPriceData filterData={filterData} setFilterData={setFilterData}/>
            <Divider className='divider'/>

            {/* flu_color div */}
            <FilterFluColor filterData={filterData} setFilterData={setFilterData}/>
            <Divider className='divider'/>

            {/* girdle div */}
            <FilterGirdle filterData={filterData} setFilterData={setFilterData}/>
            <Divider className='divider'/>

            {/* Depth div */}
            <FilterDepthData filterData={filterData} setFilterData={setFilterData}/>
            <Divider className='divider'/>

            {/* Table div */}
            <FilterTableData filterData={filterData} setFilterData={setFilterData}/>
            <Divider className='divider'/>

            {/* Measurement div */}
            <FilterMeasurementData filterData={filterData} setFilterData={setFilterData}/>
        </div>
    )
}

export default FilterAdvanceData