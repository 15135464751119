import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {scrollTo} from "../../../../helper/other/common";
import {useNavigate} from "react-router-dom";
import {applyFilter, setFilterData, setIsLogin} from "../../../../redux/diamond/diamond-slice";
import {filterDiamondFetch} from "../../../../redux/diamond/diamond-action";
import {isEmpty, isEqual, omit, split} from "lodash";
import {Select} from "antd";

const FilterSortOrderData = ({filterData, setData}) => {
	const dispatch = useDispatch();
	const selectRef = useRef(null);
	const navigate = useNavigate()
	let diamonds = useSelector((state) => state.diamondData);
	const [sortData, setSortData] = useState({sort_by: filterData.sort_by, sort_direction: filterData.sort_direction});

	useEffect(() => {
		const objData = {...filterData}
		objData.diamondPriority && omit(objData, ['diamondPriority']);
		if(isEqual(diamonds.isLogin, true)) {
			setSortData({sort_by: '', sort_direction: ''})
			setData({...filterData, sort_by: "", sort_direction: ""})
			dispatch(setFilterData({...objData, sort_by: '', sort_direction: ''}))
		} else {
			if(isEmpty(filterData.sort_by)) {
				setSortData({sort_by: '', sort_direction: ''})
				setData({...filterData, sort_by: "", sort_direction: ""})
				dispatch(setFilterData({...objData, sort_by: '', sort_direction: ''}))
			} else setSortData({sort_by: filterData.sort_by, sort_direction: filterData.sort_direction})
		}
	}, []);

	//when change the redux sort_by then change the sortData
	useEffect(() => {
		let filter = diamonds.filterData
		setSortData({sort_by: filter.sort_by, sort_direction: filter.sort_direction})
	}, [diamonds.filterData.sort_by])

	// Sorting Options
	const options = [
		{value: "Sort By", label: "Sort By"},
		{value: "final_price asc", label: "Price Asc"},
		{value: "final_price desc", label: "Price Desc"},
		{value: "weight asc", label: "Weight Asc"},
		{value: "weight desc", label: "Weight Desc"},
	];

	// Handle Sorting Change
	const handleSortingChange = (v) => {
		dispatch(setIsLogin(false))
		const data = {...filterData}
		omit(data, ['diamondPriority'])

		scrollTo()
		const sort = split(v, " ");
		isEqual(sort[0], "Sort") ? setSortData({sort_by: "", sort_direction: ""}) : setSortData({sort_by: sort[0], sort_direction: sort[1]});
		if(isEqual(sort[0], "Sort")) {
			setData({...filterData, sort_by: "", sort_direction: ""})
			dispatch(setFilterData({...data, sort_by: '', sort_direction: ''}))
		} else {
			setData({...filterData, sort_by: sort[0], sort_direction: sort[1]})
			dispatch(setFilterData({...data, sort_by: sort[0], sort_direction: sort[1]}))
		}
	};

	useEffect(() => {
		const isLabGrown = window.location.pathname === "/lab-grown"
		const data = {...filterData ,  treatment: isLabGrown ? filterData.treatment : [] }
		omit(data, ['diamondPriority'])

		dispatch(applyFilter([]))
		dispatch(filterDiamondFetch(
			{
				data         : {...data, type: [isEqual(window.location.pathname, "/lab-grown") ? "lab_grown" : "natural"], sort_by: sortData.sort_by, sort_direction: sortData.sort_direction},
				page         : diamonds.page,
				pageIncrement: false,
				navigate
			})
		)
	}, [sortData]);

	// after select the option it's not automatically blur when we click the outside then it's blur so that I set blur functionality when select the option
	const handleSelectBlur = (e) => (selectRef.current && !e) && selectRef.current.blur();

	// Default option value
	const value = diamonds.isLogin ? "Sort By" : isEmpty(sortData.sort_by) ? "Sort By" : `${sortData.sort_by} ${sortData.sort_direction}`


	return (
			<Select ref={selectRef} aria-autocomplete={"inline"} id="sorting" value={value}
					size="large" onChange={handleSortingChange} options={options} className="sort-select"
					onDropdownVisibleChange={handleSelectBlur} />
	)

};

export default FilterSortOrderData;
