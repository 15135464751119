import React, {useEffect, useState} from "react";
import {Button, Card, Form, Input, notification, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {getAccountInfo, userLogin} from "../redux/auth/auth-action";
import {useDispatch, useSelector} from "react-redux";
import {pageName, scrollTo} from "../helper/other/common";
import {fetchSettingStatus} from "../redux/order/order-action";
import CryptoJS from "crypto-js";
import {orderFilterObj} from "../helper/constant/filter-constant";
import DiamondConstData from "../helper/constant/diamond-constant";
import AlertMessage from "../helper/other/AlertMessage";
import {setIsLogin} from "../redux/diamond/diamond-slice";
import {isEqual, toString} from 'lodash'
import {setForgotPasswordMessage} from "../redux/auth/auth-slice";

const {Text, Title} = Typography;

const LogInPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth);
	const [message, setMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [api, notificationHolder] = notification.useNotification();

	// Notification Holder Function
	const openNotification = () => api.success({message: 'Notification', duration: 3, description: login.resetAndForgotPassword.message});

	// Login Form Submit
	const submitLoginForm = async(values) => {
		const res = await dispatch(userLogin({data: values, navigate: navigate}));
		setIsError(!res.payload?.data?.status);
		setMessage(res.payload?.data?.message || DiamondConstData.message.AUTHENTICATION)
		if(isEqual(res.payload.status, true)) {
			dispatch(setIsLogin(true))
			// Encrypt
			let cipherId = toString(CryptoJS.AES.encrypt(toString(res.payload.user_id), process.env.REACT_APP_SECRET_KEY))
			dispatch(fetchSettingStatus());
			dispatch(getAccountInfo({navigate}))
			localStorage.setItem("cipherId", cipherId)
			//set color for filter data which is selected by default
			localStorage.setItem("colorName", "white")
			localStorage.setItem("orderFilter", JSON.stringify(orderFilterObj))
		}
	};

	// Form Validation Rules
	const formValidation = {
		email   : [
			{
				type   : "email",
				message: "The input is not valid E-mail!",
			},
			{
				required: true,
				message : "Please input your E-mail!",
			}
		],
		password: [
			{
				required: true,
				message : "Please input your password!",
			}
		]
	}

	useEffect(() => {
		document.title = `Login -  ${pageName}`;
		if(login.resetAndForgotPassword.show) {
			openNotification()
			setTimeout(() => dispatch(setForgotPasswordMessage({message: "", show: false})), 1000)
		}
	}, []);

	scrollTo();

	return (
		<div className='login-container container'>
			{notificationHolder}
			<Title className='d-flex justify-content-center page-title'>Login</Title>
			<div className='d-flex justify-content-center'>
				<Card className='login'>
					<Form layout="vertical" onFinish={submitLoginForm} className='fw-bold'>
						<Form.Item label="Email address " name="email" rules={formValidation.email}>
							<Input />
						</Form.Item>
						<Form.Item label="Password" name="password" rules={formValidation.password}>
							<Input.Password />
						</Form.Item>
						<div className="d-flex justify-content-end">
							<Link to='/forgot-password' className='primary-text '>
								Forgot Password ?
							</Link>
						</div>
						<Form.Item className='d-flex justify-content-center'>
							<Button type="primary" htmlType="submit" loading={login.loginLoading}>
								Login
							</Button>
						</Form.Item>
						<Text className='primary-text d-flex justify-content-center'>
							You don't have an account?
							<Link to='/register' className='primary-text text-decoration-underline ps-1'>
								Signup Now
							</Link>
						</Text>

						{isError && <AlertMessage message={message} type="error" closable={true} onClose={() => setIsError(false)} />}
					</Form>
				</Card>
			</div>
		</div>
	);
};

export default LogInPage;
