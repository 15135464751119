import React, {useEffect, useState} from "react";
import {Alert, Badge, Button, Col, Row, Space} from "antd";
import {AppstoreOutlined, BarsOutlined, CloseCircleFilled, HeartOutlined, MenuFoldOutlined, ShoppingCartOutlined,} from "@ant-design/icons";
import {filterArray, filterObjectDataKey, filterRangeDataKey, filterSingleDataKey, filterSingleValueKey} from "../../../helper/constant/filter-constant";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import FilterDataDrawer from "./FilterDrawer";
import {applyFilter, setFilterData, setLayout} from "../../../redux/diamond/diamond-slice";
import {filterDiamondFetch} from "../../../redux/diamond/diamond-action";
import {scrollTo} from "../../../helper/other/common";
import FilterSortOrderData from "../../../components/utils/diamond-filter/main/SortOrder";
import {includes, isEmpty, isEqual, omit, replace, toPairs, upperCase} from 'lodash'
import FilterStockIdData from "../../../components/utils/diamond-filter/main/StockId";

const FilterTag = ({filterData, setData}) => {
	let data = useSelector((state) => state.diamondData.filterData);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let diamonds = useSelector((state) => state.diamondData);

	//when click on close tag than call api
	const filterTagCloseAction = (key, value) => {
		const objData = omit({...filterData}, ['diamondPriority'])
		const isLabGrown = window.location.pathname === "/lab-grown"
		const treatmentValue = !isLabGrown ? [] : (key === 'treatment' ? value : objData.treatment)
		setData(({...filterData, [`${key}`]: value}))
		dispatch(setFilterData({...objData, [`${key}`]: value}))


		//when value is girdle_thick then update the value of girdle_thin
		if(isEqual(key, 'girdle_thick')) {
			objData.girdle_thin = value
			setData({...filterData, girdle_thin: value})
			dispatch(setFilterData({...objData, girdle_thin: value}))
		}

		let deleteKeyArr = ['diamondPriority', 'sort_direction', 'sort_by', 'type']
		let filterObj = {...filterArray}

		// delete unnecessary key and value pair
		let updatedCompareObj = omit({...objData, [`${key}`]: value}, deleteKeyArr);
		let updateDefaultFilterObj = omit(filterObj, deleteKeyArr)

		// compare current and default object
		const result = isEqual(updatedCompareObj, updateDefaultFilterObj)

		if(result) {
			setData({...filterData, [`${key}`]: value, sort_by: "", sort_direction: ""})
			dispatch(setFilterData({...objData, [`${key}`]: value, sort_by: "", sort_direction: ""}))
			objData.sort_by = ''
			objData.sort_direction = ''
		} else {
			setData({...filterData, [`${key}`]: value})
			dispatch(setFilterData({...objData, [`${key}`]: value}))
		}

		dispatch(filterDiamondFetch(
			{
				data         : {
					...objData,
					type       : [isLabGrown ? "lab_grown" : "natural"],
					[`${key}`] : value,
					girdle_thin: key === 'girdle_thick' ? [] : data['girdle_thin'],
					treatment  : treatmentValue
				},
				page         : diamonds?.page,
				pageIncrement: false,
				navigate
			})
		)
	}

	const stringNumber = (v) => Number(v).toFixed(2)

	/* Remove Selected filter event  */
	const filterTagClose = (value, clickTag) => {
		scrollTo()
		// clear data in redux storage
		dispatch(applyFilter([]))
		if(includes(filterSingleDataKey, value)) {
			filterTagCloseAction(value, data[value].filter((name) => name !== clickTag))
		} else if(includes(filterRangeDataKey, value) || includes(filterObjectDataKey, value) || includes(filterSingleValueKey, value)) {
			filterTagCloseAction(value, filterArray[value])
		}
	};

	//show filter object data
	const ObjectData = (key, value) => {
		const tagKey = key?.replace(/_([a-z])/g, (match) => match?.toUpperCase())?.split("_").join("")
		const TagValue = () => {
			if(!value.min && value.max) {
				return `[${isEqual(key, 'weight') ? '0.20' : '1.00'} - ${stringNumber(value.max)}]`
			} else if(!value.max && value.min) {
				return `[${stringNumber(value.min)} - ${isEqual(key, 'weight') && value.min <= 4 ? '4.00' : stringNumber(value.min)}+]`
			} else {
				return `[${stringNumber(value.min)} - ${stringNumber(value.max)}]`
			}
		}
		return <Badge count={<CloseCircleFilled className="badgedata" onClick={() => filterTagClose(key)} key={key} />} dot={true}>
			<Alert type="info" message={`${tagKey} : ${TagValue()}`} className="filter_data" />
		</Badge>
	}

	//show filter single data
	const SingleData = (key, value) => {
		const tagKey = isEqual(key, 'girdle_thick') ? 'Girdle' : key.replace(/_([a-z])/g, (match) => match.toUpperCase()).split("_").join("")

		const TagValue = (data) => isEqual(key, 'lab') || isEqual(key, 'treatment') ? data.toUpperCase() : data

		if(!(isEqual(key, 'treatment') && isEqual(window.location.pathname, "/natural"))) {
			return <>
				{value.map((data) => (
					<Badge count={<CloseCircleFilled className="badgedata" onClick={() => filterTagClose(key, data)} key={key} />} dot={true}>
						<Alert type="info" message={`${tagKey} : ${TagValue(data)}`} className="filter_data" />
					</Badge>
				))}
			</>
		}
	}

	//show filter range data
	const RangeData = (key, value) => {
		const tagKey = replace(key, /_([a-z])/g, (match) => upperCase(match))
		const TagValue = () => isEqual(value.length, 1) ? value[0] : `[${value[0]} - ${value[value.length - 1]}]`

		return <Badge count={<CloseCircleFilled className="badgedata" onClick={() => filterTagClose(key)} key={key} />} dot={true}>
			<Alert type="info" message={`${tagKey} : ${TagValue()}`} className="filter_data" />
		</Badge>
	}

	//show filter single value data
	const SingleValue = (key, value) => {
		const tagKey = replace(key, /_([a-z])/g, (match) => upperCase(match))
		return <Badge count={<CloseCircleFilled className="badgedata" onClick={() => filterTagClose(key)} key={key} />} dot={true}>
			<Alert type="info" message={`${tagKey} : ${value}`} className="filter_data" />
		</Badge>
	}
	return <Space>
		{toPairs(data).map((item) => {
			const key = item[0]
			const value = item[1]
			const isObjectData = includes(filterObjectDataKey, key) && !(isEmpty(value.min) && isEmpty(value.max));
			const isSingleData = includes(filterSingleDataKey, key) && value && value.length !== 0
			const isRangeData = includes(filterRangeDataKey, key) && value && value.length !== 0
			const isSingleValue = includes(filterSingleValueKey, key) && value

			if(isSingleValue) {
				return SingleValue(key, value)
			} else if(isObjectData) {
				return ObjectData(key, value)
			} else if(isSingleData) {
				return SingleData(key, value, item)
			} else if(isRangeData) {
				return RangeData(key, value)
			}
		})}
	</Space>
}

const FilterDataBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let diamonds = useSelector((state) => state.diamondData);
	let cartData = useSelector((state) => state.cartData);
	let wishlistData = useSelector((state) => state.wishlistData);
	let filterData = useSelector((state) => state.diamondData.filterData);
	const [isOpen, setIsOpenFilter] = useState(false)
	const [showCartBtn, setShowCartBtn] = useState(false);
	const [data, setData] = useState(filterData)
	const userInfo = useSelector((state) => state.auth.accountDetails);
	let kycStatus = userInfo.data?.kyc_status || "undefined"

	// check kyc status
	const approvedKycStatus = isEqual(kycStatus, "approved")

	useEffect(() => {
		setData(filterData)
		const handleScroll = () => {
			if(window.scrollY > 200) setShowCartBtn(true);
			else setShowCartBtn(false);
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	useEffect(() => {
		setData(filterData)
	}, [filterData])

	// when toggle the view layout
	const handleProductView = () => {
		dispatch(setLayout(!diamonds.tableLayout))
		localStorage.setItem("productView", diamonds.tableLayout ? "grid" : "table");
	};

	//when click on cart button
	const onClickCartButton = () => navigate("/cart");

	const ActionButtons = () => {
		return <>
			{showCartBtn && <Space className='cartbtn'>
				<Button size="large" shape="round" className='ps-2 pe-2' onClick={() => navigate("/wishlist")}>
					<Badge count={wishlistData.length} color='var(--primary)'> <HeartOutlined className='view-btn' /> </Badge>
				</Button>
				<Button size="large" shape="round" className='ps-2 pe-2' onClick={onClickCartButton}>
					<Badge count={cartData.length} color='var(--primary)'> <ShoppingCartOutlined className='view-btn' /> </Badge>
				</Button>
			</Space>}
		</>
	}


	return (
		<Row className='mb-2 filter-bar'>
			<Col className='filter-btn-div'>
				<Button type="primary" onClick={() => { setIsOpenFilter(true) }} size="large" className='filter-btn' icon={<MenuFoldOutlined />}> Filter Data </Button>
				<span className='btn-action'>
						{ActionButtons()}
				</span>
			</Col>
			<Col flex={12} className='selected-filter-bar'>
				{/*Show selected filter*/}
				<FilterTag filterData={data} setData={setData} />
			</Col>
			<Col flex={1} className='other-filter'>
				<div className='d-flex justify-content-end action-btn'>
					{approvedKycStatus && <Space size={[8, 16]} wrap>
						<FilterStockIdData data={data} setData={setData} />
						<Button size="large" icon={diamonds.tableLayout ? <AppstoreOutlined className='view-btn' /> : <BarsOutlined className='view-btn' />} onClick={handleProductView} />
						{ActionButtons()}
						<FilterSortOrderData filterData={data} setData={setData} />
					</Space> }
				</div>
			</Col>
			<FilterDataDrawer isOpen={isOpen} setIsOpenFilter={setIsOpenFilter} data={data} setData={setData} />
		</Row>
	);
};

export default FilterDataBar;

