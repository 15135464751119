import {Button, Card, Col, Divider, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react'
import {CUT, CUT_SHAPE, CUTSHAPE, POLISH, SYMMETRY} from "../../../../helper/constant/filter-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography
const FilterCutData = ({filterData, setFilterData}) => {
	const cutShape = filterData.diamondPriority || []
	const cutData = filterData?.cut
	const symmetryData = filterData?.symmetry
	const polishData = filterData?.polish

	const [selectItem, setSelectItem] = useState({low: null, high: null})
	const [selectSymmetryItem, setSelectSymmetryItem] = useState({low: null, high: null})
	const [selectPolishItem, setSelectPolishItem] = useState({low: null, high: null})

	// when component render first time , then set cut, polish and symmetry data using function parameter
	const didMount = (item, setItem, itemOfArr) => {
		const length = size(item)
		if(isEqual(length, 1)) {
			let index = indexOf(itemOfArr, item[0])
			setItem({low: index, high: index})
		} else if(length > 0) {
			let firstIndex = indexOf(itemOfArr, item[0])
			let lastIndex = indexOf(itemOfArr, item[length - 1])
			setItem({low: firstIndex, high: lastIndex})
		}
	}

	useEffect(() => {
		didMount(cutData, setSelectItem, CUT)
		didMount(polishData, setSelectPolishItem, POLISH)
		didMount(symmetryData, setSelectSymmetryItem, SYMMETRY)
	}, [])

	// when select a 3EX
	const handle3EXSelect = (item, setItem) => setItem({low: 0, high: 0})

	// when select 3VG
	const handle3VGSelect = (item, setItem) => setItem({low: 0, high: 1})

	// when select 3G
	const handle3GSelect = (item, setItem) => setItem({low: 0, high: 2})

	// when select RESET
	const handleResetSelect = (item, setItem) => setItem({low: null, high: null})


	// update a data in localStorage when change a cutShape
	const updateAllData = (data, cut) => setFilterData({...filterData, cut: data, polish: data, symmetry: data, diamondPriority: [cut]})

	// when click on cutShape
	const handleCutShape = (cut) => {
		switch(cut) {
			case "3EX":
				updateAllData([CUT_SHAPE.EXCELLENT], cut)
				handle3EXSelect(selectItem, setSelectItem);
				handle3EXSelect(selectPolishItem, setSelectPolishItem)
				handle3EXSelect(selectSymmetryItem, setSelectSymmetryItem)
				break;
			case "3VG":
				updateAllData([CUT_SHAPE.EXCELLENT, CUT_SHAPE.VERY_GOOD], cut)
				handle3VGSelect(selectItem, setSelectItem);
				handle3VGSelect(selectPolishItem, setSelectPolishItem)
				handle3VGSelect(selectSymmetryItem, setSelectSymmetryItem)
				break;
			case "3G":
				updateAllData([CUT_SHAPE.EXCELLENT, CUT_SHAPE.VERY_GOOD, CUT_SHAPE.GOOD], cut)
				handle3GSelect(selectItem, setSelectItem);
				handle3GSelect(selectPolishItem, setSelectPolishItem)
				handle3GSelect(selectSymmetryItem, setSelectSymmetryItem)
				break;
			default:
				updateAllData([], cut)
				handleResetSelect(selectItem, setSelectItem);
				handleResetSelect(selectPolishItem, setSelectPolishItem)
				handleResetSelect(selectSymmetryItem, setSelectSymmetryItem)
				break;
		}
	}

	// Cut
	useEffect(() => {
		const cutArr = slice(CUT, selectItem.low, selectItem.high + 1)
		if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, cut: []})
		else setFilterData({...filterData, cut: cutArr})
	}, [selectItem])

	// Polish
	useEffect(() => {
		const polishArr = slice(POLISH, selectPolishItem.low, selectPolishItem.high + 1)
		if(isNil(selectPolishItem.low) && isNil(selectPolishItem.high)) setFilterData({...filterData, polish: []})
		else setFilterData({...filterData, polish: polishArr})
	}, [selectPolishItem])

	// Symmetry
	useEffect(() => {
		const symmetryArr = slice(SYMMETRY, selectSymmetryItem.low, selectSymmetryItem.high + 1)
		if(isNil(selectSymmetryItem.low) && isNil(selectSymmetryItem.high)) setFilterData({...filterData, symmetry: []})
		else setFilterData({...filterData, symmetry: symmetryArr})
	}, [selectSymmetryItem])

	// when select a card
	const handleCardClick = (i, item, setItem) => {
		const {low, high} = item;
		if(isEqual(i, low) && isEqual(i, high)) setItem({low: null, high: null})
		else if(isNil(low) && isNil(high)) setItem({low: i, high: i});
		else if(!isEqual(low, high) || isNil(low) || isNil(high)) setItem({low: i, high: i})
		else if(i >= high) setItem({...item, high: i})
		else setItem({...item, low: i});
	}

	return (
		<Card className='gray-filter-card'>
			<Row gutter={[8, 8]}>
				{CUTSHAPE.map((name, index) => {
					const buttonType = includes(cutShape, name) ? "primary" : "default"
					return <Col key={index}><Button type={buttonType} onClick={() => handleCutShape(name)}>{name}</Button></Col>
				})}
			</Row>

			{/* cut data */}
			<Title level={5} className='filter-title'> Cut </Title>
			<Row gutter={[8, 8]}>
				{CUT.map((cut, i) => {
					const buttonType = includes(cutData, cut) ? "primary" : "default"
					return <Col key={cut}> <Button type={buttonType} onClick={() => handleCardClick(i, selectItem, setSelectItem)}>{cut}</Button> </Col>
				})}
			</Row>
			<Divider className='divider' />

			{/* polish data */}
			<Title level={5} className='filter-title'> Polish </Title>
			<Row gutter={[8, 8]}>
				{POLISH.map((polish, i) => {
					const buttonType = includes(polishData, polish) ? "primary" : "default"
					return <Col key={polish}> <Button type={buttonType} onClick={() => handleCardClick(i, selectPolishItem, setSelectPolishItem)}>{polish}</Button> </Col>
				})}
			</Row>
			<Divider className='divider' />

			{/* symmetry data */}
			<Title level={5} className='filter-title'> symmetry </Title>
			<Row gutter={[8, 8]}>
				{SYMMETRY.map((symmetry, i) => {
					const buttonType = includes(symmetryData, symmetry) ? "primary" : "default"
					return <Col key={symmetry}> <Button type={buttonType} onClick={() => handleCardClick(i, selectSymmetryItem, setSelectSymmetryItem)}>{symmetry}</Button> </Col>
				})}
			</Row>
		</Card>
	)
}

export default FilterCutData