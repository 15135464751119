import {DebounceInput} from "react-debounce-input";
import React from "react";
import {Col, Row, Space, Typography} from "antd";

const {Title} = Typography;

const FilterTableData = ({filterData, setFilterData}) => {

	const table = filterData.table
	const length = filterData.measurement_length

	// Handle min-table onChange
	const handleMinTableChange = (e) => setFilterData({...filterData, table: {...filterData.table, min: e.target.value}})

	// Handle max-table onChange
	const handleMaxTableChange = (e) => setFilterData({...filterData, table: {...filterData.table, max: e.target.value}})

	// Handle min-length onChange
	const handleMinLengthChange = (e) => setFilterData({...filterData, measurement_length: {...filterData.measurement_length, min: e.target.value}})

	// Handle max-length onChange
	const handleMaxLengthChange = (e) => setFilterData({...filterData, measurement_length: {...filterData.measurement_length, max: e.target.value}})

	return (
		<Row gutter={[8, 0]}>
			<Col span={12}>
				<Title level={5} className='filter-title'>Table Percent</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={table.min} onChange={handleMinTableChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={table.max} onChange={handleMaxTableChange} />
				</Space>
			</Col>
			<Col span={12}>
				<Title level={5} className='filter-title'>Measure Length</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={length.min} onChange={handleMinLengthChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={length.max} onChange={handleMaxLengthChange} />
				</Space>
			</Col>
		</Row>
	);
};

export default FilterTableData;
