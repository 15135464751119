import {createSlice} from "@reduxjs/toolkit";

const storeData = JSON.parse(localStorage.getItem("wishlist")) || [];
export const WishlistDataReducer = createSlice(
	{
		name        : "wishlistData",
		initialState: storeData,
		reducers    : {
			setWishlistData   : (state, action) => {
				state.push(action.payload);
			},
			removeWishlistData: (state, action) =>
			 state.filter((data) => data?.id !== action.payload?.id),
			removeAllWishListData: (state, action) =>
				state = []
		},
	})

export const {setWishlistData, removeWishlistData,removeAllWishListData} = WishlistDataReducer.actions;
export default WishlistDataReducer.reducer;