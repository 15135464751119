import React, {useEffect} from "react";
import {ConfigProvider} from "antd";
import {BrowserRouter} from "react-router-dom";
import {AntdConfigStyle} from "./assets/style/antd-config-style";
import "./App.css";
import Boundary from "./error/error-boundary/ErrorBoundary.jsx";
import {useDispatch} from "react-redux";
import DashBoard from "./pages/dashboard/DashBoard.jsx";
import {fetchSettingStatus} from "./redux/order/order-action";
import {getAccountInfo} from "./redux/auth/auth-action";
import globalRouter from "./routing/global-router";
function App() {
	const dispatch = useDispatch()
	let token = localStorage.getItem("token");
	const navigate = globalRouter.navigate

	useEffect(() => {
		dispatch(fetchSettingStatus())
		token && dispatch(getAccountInfo({navigate}))
	}, [])

	return (
		<Boundary>
			<BrowserRouter>
				<ConfigProvider theme={AntdConfigStyle}>
					<DashBoard />
				</ConfigProvider>
			</BrowserRouter>
		</Boundary>
	);
}

export default App;
