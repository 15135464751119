import {createSlice} from "@reduxjs/toolkit";
import {fetchDiamonds, filterDiamondFetch} from "./diamond-action";
import {filterArray} from "../../helper/constant/filter-constant";

const storeView = localStorage.getItem("productView")
const isFilterApply = JSON.parse(localStorage.getItem('isFilterApply'))
const filterObject = localStorage.getItem('filterObject')
const filterArrayData = {...filterArray}
delete filterArrayData.diamondPriority;
export const DiamondDataReducer = createSlice(
	{
		name         : "diamondData",
		initialState : {
			data            : [],
			loading         : true,
			error           : "",
			status          : "",
			page            : 0,
			filterDataDrawer: {isFilter: false, isFilterApply: isFilterApply ? isFilterApply : false},
			filterData      : filterObject ? JSON.parse(filterObject) : filterArrayData,
			tableLayout     : storeView === "table" ? true : false,
			isLogin         : false
		},
		reducers     : {
			applyFilter        : (state, action) => {
				void ((state.filterDataDrawer.isFilter = true), (state.data = action.payload), (state.loading = true));
			},
			setFilterData      : (state, action) => {
				void (state.filterData = action.payload)
				localStorage.setItem('filterObject', JSON.stringify(action.payload))
			},
			filterSelectedValue: (state, action) => {
				void ((state.filterDataDrawer.isFilterApply = action.payload.isApply));
				localStorage.setItem('isFilterApply', action.payload.isApply)
			},
			setLayout          : (state, action) => {
				void (state.tableLayout = action.payload)
			},
			setIsLogin         : (state, action) => void ((state.isLogin = action.payload))
		},
		extraReducers: (builder) => {
			builder.addCase(fetchDiamonds.fulfilled, (state, action) => {
				action.payload.data ? void ((state.data = [...state.data, ...action?.payload?.data]), (state.loading = false)) : void (state.error = action.payload.response)
			});
			builder.addCase(fetchDiamonds.pending, (state) => {
				state.loading = true;
			});
			builder.addCase(filterDiamondFetch.fulfilled, (state, action) => {
				action.payload.status === 204 && void ((state.data = []))
				window.scrollY < 377 && (action.payload.error?.code === "ERR_CANCELED" && void ((state.data = [])))

				if(action.payload.error?.response?.status === 404) {
					void ((state.status = action.payload?.status === undefined ? action.payload.error.response?.status : action.payload?.status), (state.loading = false))
				} else if(action.payload.data) {
					void ((state.data = action.payload.page === 1 ? [...action.payload.data] : [...state.data, ...action.payload.data]),
						(state.loading = false),
						(state.status = action.payload?.status === undefined ? action.payload.error.response?.status : action.payload?.status),
						(state.page = action.payload.page))
				} else {
					void ((state.error = action.payload.error?.code === "ERR_CANCELED" ? action.payload.error?.code : action.payload.error.response.data.message),
						(state.loading = false),
						(state.status = action.payload?.status === undefined ? action.payload.error.response?.status : action.payload?.status));
				}
				(state.filterDataDrawer.isFilter = false)
			});
			builder.addCase(filterDiamondFetch.pending, (state) => {
				state.loading = true
			});
		},
	});

export const {
	applyFilter,
	filterSelectedValue,
	setFilterData,
	setIsLogin,
	setLayout
} = DiamondDataReducer.actions;

export default DiamondDataReducer.reducer;
