import {createSlice} from "@reduxjs/toolkit";
import { fetchSettingStatus} from "./order-action";

export const OrderReducer = createSlice(
	{
		name         : "orderHistory",
		initialState : {order_id: '', status: '', dateRange: '', displayDateRange: []},
		reducers: {
			setOrderFilterData: (state, action) => state = action.payload,
			removeOrderFilterData: (state, action) => state = {order_id: '', status: '', dateRange: '', displayDateRange: []}
		},
		extraReducers: (builder) => {
			builder.addCase(fetchSettingStatus.fulfilled, (state, action) => {
				return void (state = action.payload)
			})
		},
	});
export const {setOrderFilterData , removeOrderFilterData} = OrderReducer.actions;
export default OrderReducer.reducer;