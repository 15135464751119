import {Button, Col, Row, Typography} from 'antd'
import React from 'react'
import {FANCY_COLOR} from "../../../../helper/constant/filter-constant";
import {includes, isEqual} from "lodash";

const {Title} = Typography
const FilterFancyColorData = ({filterData, setFilterData}) => {
	const data = filterData?.fancy_color

	// Handle onColor Click
	const handleColorClick = (color) => {
		if(includes(data, color)) {
			const filterColorData = data.filter(name => name !== color);
			setFilterData({...filterData, fancy_color: filterColorData})
		} else setFilterData({...filterData, fancy_color: [...filterData.fancy_color, color]})
	}

	return (
		<>
			<Title level={5} className='filter-title mt-0'> Fancy Color </Title>
			<Row gutter={[8, 8]}>
				{FANCY_COLOR.map((color) => {
					const colorClassName = includes(data, color.name) ? "fancy_color_selected" : "fancy_color"
					const buttonStyle = {backgroundColor: color.code, color: isEqual(color.name, "yellow") ? "black" : "white"}
					return <Col key={color.name}> <Button className={`${colorClassName} text-uppercase`} style={buttonStyle} onClick={() => handleColorClick(color.name)}>{color.name.charAt(0)}</Button> </Col>
				})}
			</Row>
		</>
	)
}

export default FilterFancyColorData