import React, {useEffect, useState} from 'react';
import {Card, Button, Row, Col, Tag, Modal, Popconfirm, notification, Tooltip} from 'antd';
import {EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import './address.css';
import AddressBookForm from "./Form";
import {deleteAddress, fetchAddresses} from "../../../redux/address/address-action";
import countryList from "react-select-country-list";
import {useDispatch} from "react-redux";

const AddressBook = () => {
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const [isEditAddress, setIsEditAddress] = useState(false);
	const [addressId, setAddressId] = useState(null);
	const [addressList, setAddressList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [api, notificationHolder] = notification.useNotification();

	//fetch address list
	const fetchAddressList = async() => {
		// API call to fetch address list
		const response = await dispatch(fetchAddresses());
		setAddressList(response.payload);
	}

	//when click on edit address icon
	const handleEditAddress = (id) => {
		setModalOpen(true);
		setIsEditAddress(true);
		setAddressId(id);
	}

	//when click on add address button
	const handleAddAddress = () => {
		setModalOpen(true);
		setIsEditAddress(false);
		setAddressId(null);
	}

	//when click on delete address icon
	const handleDeleteAddress = async(id) => {
		// API call to delete address
		setLoading(true);
		const response = await deleteAddress(id);
		console.log(response)
		setLoading(false);
		if(response.status === 200) {
			api.success({message: response.data.message, placement: 'topRight',});
			fetchAddressList();
		} else {
			api.error({message: response.message, placement: 'topRight',});
		}
	}

	useEffect(() => {
		fetchAddressList();
	}, []);

	const handleModalCancel = () => setModalOpen(false);

	return (
		<div>
			{notificationHolder}
			<Row gutter={[16, 16]}>
				{addressList.map((address, index) => {
					const country = countryList().getLabel(address.country) || address.country;
					const extraAddress = address.address_2 ? `${address.address_2},` : '';
					const state = address.state ? `${address.state},` : '';

					const fullAddress = `${address.address_1}, ${extraAddress} ${address.city}, ${state} ${address.postcode}, ${country}`;
					const actionDivClassName = `ms-2 ${address.is_default && "action-div"}`
					const cardClassName = `${address.is_default && "default-card"}`

					return (
						<Col lg={12} md={12} sm={24} key={index}>
							<Card className={cardClassName}>
								<div className={'address-title'}>
									<h4 className={'text-capitalize'}>{address.full_name}</h4>
									{address.is_default && <Tag color="orange"><span className={'fs-12'}>Default</span></Tag>}
								</div>

								<p>{address.contact_no}</p>
								<div className={'address-detail'}>
									<span className={'address text-capitalize'}><Tooltip title={fullAddress} placement="bottom">{fullAddress}</Tooltip></span>
									<div className={actionDivClassName}>
										<EditOutlined key="edit" className={'address-icon text-primary'} onClick={() => handleEditAddress(address.id)} /><br />
										{!address.is_default && <Popconfirm title="Delete"
																			description="Are you sure to delete this address?"
																			okText="Delete"
																			okButtonProps={{loading}}
																			onConfirm={() => handleDeleteAddress(address.id)}>
											{<DeleteOutlined key="delete" className={'address-icon mt-2 text-danger'} />}
										</Popconfirm>}
									</div>
								</div>
							</Card>
						</Col>
					)
				})}

				<Col lg={12} md={12} sm={24}>
					<Button type="dashed" onClick={handleAddAddress} className={"add-address-card"} icon={<PlusOutlined />}> Add new address</Button>
				</Col>
			</Row>

			<Modal open={modalOpen} footer={null} onCancel={handleModalCancel} className={"address-modal"}>
				<AddressBookForm isEditable={isEditAddress} handleModalCancel={handleModalCancel} id={addressId} fetchAddressList={fetchAddressList} setModalOpen={setModalOpen} />
			</Modal>

		</div>
	);
};

export default AddressBook;
