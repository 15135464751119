export const ShadeMilkyEyeCleanTagColor = {
    none: {bgColor: '#FEE2E2', textColor: '#B91C1C'},
    shade: {bgColor: '#FEF3C7', textColor: '#D97706'},
    milky: {bgColor: '#DDFCE8', textColor: '#3F7E65'},
    eye_clean: {bgColor: '#DDFCE8', textColor: '#3F7E65'},
}

const DiamondConstData =
    {
        flag: {
            "India": "images/india_flag.png",
            "Hong Kong": "images/hong_kong_flag.png",
            "Belgium": "images/belgium_flag.png",
            "United Kingdom": "images/United_kingdom_flag.png",
            "Canada": "images/Canada_flag.png",
            "USA": "images/USA_flag.png",
            "China": "images/china_flag.png",
            "UAE": "images/UAE_flag.png",
            "Japan": "images/japan_flag.png"
        },
        grading: {
            "Excellent": "ex",
            "Very Good": "vg",
            "Good": "g",
            "Fair": 'f',
            "Poor": 'p',
            "Ideal": "id",
            "": ""
        },
        shape_image: {
            "Round": "/images/round.jpg",
            "Marquise": "/images/marquise.jpg",
            "Princess": "/images/princess.jpg",
            "Pear": "/images/pear.jpg",
            "Emerald": "/images/emrald.jpg",
            "Heart": "/images/heart.jpg",
            "Oval": "/images/oval.jpg",
            "Cushion": "/images/cushion.jpg",
            "Radiant": "/images/radiant.jpg",
            "Asscher": "/images/asscher-img.jpg",
            "Trilliant": "/images/trilliant_border.png",
            "Default": "/images/diamond.jpg"
        },
        fl_intensity: {
            "None": "None",
            "Faint": 'FNT',
            "Very Slight": 'VSL',
            "Slight": 'SL',
            "Medium": 'MED',
            "Strong": 'STR',
            "Very Strong": 'VST'
        },
        fancy_color_intensity: {
            "Fancy": "F",
            "Very Light": "VL",
            "Light ": "L",
            "Fancy Light": "FL",
            "Fancy Deep": "FD",
            "Fancy Intense": "FI",
            "Fancy Vivid": "FV"
        },
        discountRate: 0.02,
        multi_currency: {
            "USD": {logo: "/images/currency_logos/usd.png", symbol: "$", name: "USD"},
            "GBP": {logo: "/images/currency_logos/gbp.png", symbol: "£", name: "GBP"},
            "EUR": {logo: "/images/currency_logos/eur.png", symbol: "€", name: "EUR"},
            "INR": {logo: "/images/currency_logos/inr.png", symbol: "₹", name: "INR"}
        },
        message: {
            ENQUIRY_MESSAGE: 'We got your enquiry, we’ll get back to you soon!',
            TOOL_TIP: "This Diamond has a final price.",
            AUTHENTICATION: "Something went wrong..!"
        }
    };

export default DiamondConstData;


