import React, {useMemo, useState} from "react";
import DiamondConstData from "../../../helper/constant/diamond-constant";
import {Button, Card, Col, Row, Spin, Tag, Typography} from "antd";
import {CheckOutlined, HeartFilled, HeartOutlined, LinkOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import Slider from "react-slick";
import "./Product.css";
import {diamondString, eventPropagation, isValidVideoUrl} from "../../../helper/other/common";
import {removeWishlistData, setWishlistData} from "../../../redux/wishlist/wishlist-slice";
import {setCartData} from "../../../redux/cart/cart-slice";
import EnquiryModel from '../../../components/utils/models/EnquiryModel';
import ProductInfoModel from "../../../components/utils/models/ProductInfoModal";
import {includes, isEmpty, isEqual, isUndefined, lastIndexOf} from "lodash";
import ProductDetailCard from "../../../pages/diamond/product-card/ProductDetailCard";
import SimplifiedDetailCard from "../../../pages/diamond/product-card/SimplifiedDetailCard";
import NonKycDetailCard from "../../../pages/diamond/product-card/NonKycDetaiCard";

const {Text} = Typography;

/**
 * Media Content
 * @param offerTextColor
 * @returns {JSX.Element}
 */
export const MediaContent = ({data, api, action, setAction, handleImageRequest, isWishlist}) => {

	const userInfo = useSelector((state) => state.auth.accountDetails);
	const kycStatus = userInfo?.data?.kyc_status;

	const dispatch = useDispatch();
	let wishlistData = useSelector((state) => state.wishlistData);

	const [isVideo, setIsVideo] = useState(false)
	const [isImage, setIsImage] = useState(false)
	const [videoLoading, setVideoLoading] = useState(true);
	let {shape, availability, img_link, video_link} = data;

	const settings = {
		dots          : true,
		infinite      : true,
		arrows        : false,
		speed         : 500,
		slidesToShow  : 1,
		slidesToScroll: 1,
		swipeToSlide  : true,
		lazyLoad      : true
	};

	let fileExt
	let videoFileExt

	// Use Memo use for update a wishlistData
	useMemo(() => {
		const isItemInWishlist = wishlistData?.some(item => item.id === data.id);
		setAction((prev) => ({...prev, likeIconSelected: isItemInWishlist}))
		localStorage.setItem("wishlist", JSON.stringify(wishlistData))
	}, [wishlistData]);

	if(isEmpty(img_link)) img_link = false
	else {
		let lastIndexFileExt = lastIndexOf(img_link, ".");
		fileExt = img_link?.slice(lastIndexFileExt + 1);
	}

	if(isEmpty(video_link)) video_link = false
	else {
		let lastIndexVideoFileExt = lastIndexOf(video_link, ".");
		videoFileExt = video_link?.slice(lastIndexVideoFileExt + 1);
	}

	// hidden video spinner
	const hideVideoLoadingSpinner = () => {
		setVideoLoading(false);
	};

	// click on Enquiry Button
	const showEnquiryModal = () => {
		setAction({...action, enquiryModalOpen: true});
	};

	// Add favourite Diamond
	const handleLikeIcon = async() => {
		isWishlist && setAction((prev) => ({...prev, productDetailModalOpen: false}))
		const itemString = diamondString(data)
		const existingWishListItem = action.likeIconSelected
		const message = (<><Text className="text-uppercase fw-medium">"{itemString}"</Text> {existingWishListItem ? 'Remove from Wishlist' : 'Added to Wishlist'}</>);
		api[existingWishListItem ? 'info' : 'success']({message, duration: 2, placement: "bottom"});
		if(existingWishListItem) await dispatch(removeWishlistData(data));
		else await dispatch(setWishlistData(data));
	}

	const imagePath = isUndefined(DiamondConstData.shape_image[`${shape}`]) ? DiamondConstData.shape_image["Default"] : DiamondConstData.shape_image[`${shape}`]
	const fileExtArr = ["jpg", "jpeg", "JPG", "JPEG", "png", "PNG"]
	const isFileExtension = includes(fileExtArr, fileExt)

	// Handle onError image and video
	const onError = (e, isImage) => {
		e.currentTarget.src = imagePath
		isImage ? setIsImage(true) : setIsVideo(true)
	}

	// Enquiry for image and video
	const requestImage = () => {
		handleImageRequest("i_request");
		setAction({...action, isImageRequest: 'i_request'});
		showEnquiryModal();
	}

	// Favorite Icon
	const favoriteIcon = action.likeIconSelected ? <HeartFilled className="fs-5 primary-text" /> : <HeartOutlined className="fs-5 text-gray" />

	// Kyc Approved
	const kycApproved = isEqual(kycStatus, 'approved')

	return (
		<>
			<section className="card_image" onClick={(e) => eventPropagation(e)}>
				{isEqual(availability, 'On Memo') && <Tag className="memo-tag"> On Memo </Tag>}
				<Slider {...settings}>
					<div className="position-relative justify-content-center">
						{!img_link ? (
							<img src={imagePath} className="product-media" alt="Diahearts diamond"
								 onError={(e) => onError(e, true)} />
						) : isFileExtension ? (
							<img src={img_link} className="product-media" alt="Diahearts diamond"
								 onError={(e) => onError(e, true)} />
						) : (<img src={`${imagePath}`} className="product-media" alt="Diahearts diamond" onLoad={() => setIsImage(true)} />)}
						{kycApproved && (!img_link || isImage || isVideo) && <Button className="request-btn" id="enquiryButton" onClick={requestImage}> Request Image </Button>}
					</div>
					<div>
						{!video_link || isValidVideoUrl(video_link) === false ?
						 (<img src={imagePath} className="product-media" alt="Diahearts diamond"></img>) : (
							 <div>

								 {videoLoading ? (<Row justify="center"> <Spin /> </Row>) : null}
								 {!isEqual(videoFileExt, "mp4") ? (
									 <iframe src={`${video_link}`} allowFullScreen={true}
											 onLoad={hideVideoLoadingSpinner}
											 loading="lazy" className="product-media"
											 alt="Diahearts diamond"></iframe>
								 ) : (isVideo ?
									  <img src={imagePath} className="product-media" alt="Diahearts diamond" /> :
									  <video src={`${video_link}`} onMouseOver={(event) => event.target.play()}
											 onMouseOut={(event) => event.target.pause()}
											 loop muted onLoadStart={hideVideoLoadingSpinner}
											 className="product-media"
											 onError={(e) => onError(e, false)} />)}

								 {/*video link icon*/}
								 {(!isEqual(videoFileExt, "mp4") || !isVideo) &&
									 <Text strong className={"video-link"}
										   copyable={{
											   text: video_link, tooltips: ['Click to copy', 'Link copied'],
											   icon                      : [<LinkOutlined key="copy-icon" className='copy-icon' />, <CheckOutlined key="copied-icon" className={'copied-icon'} />]
										   }} />}
							 </div>)}
					</div>
				</Slider>
				{kycApproved && <Button onClick={handleLikeIcon} shape="circle" className="like-btn" id='request-image' icon={favoriteIcon} />}
			</section>
		</>)
}

/**
 * Product Action
 * @param offerTextColor
 * @returns {JSX.Element}
 */
export const ProductAction = ({data, api, action, setAction, handleImageRequest}) => {
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const kycStatus = userInfo?.data?.kyc_status;

	const dispatch = useDispatch();
	let cartData = useSelector((state) => state.cartData);
	const kycApproved = isEqual(kycStatus, 'approved')

	// Use Memo use for update a cartData
	useMemo(() => {
		const isItemInCart = cartData?.some(item => item.id === data.id);
		setAction((prev) => ({...prev, addToCart: isItemInCart}))
	}, [cartData]);

	// click on Enquiry Button
	const showEnquiryModal = () => {
		setAction({...action, enquiryModalOpen: true});
	};

	// click on add to cart button
	const handleAddToCart = async(e) => {
		eventPropagation(e)
		const itemString = diamondString(data)
		const existingCartItem = cartData?.find(item => item.stock_id === data.stock_id);
		const message = (<><Text className="text-uppercase fw-medium">"{itemString}"</Text> {existingCartItem ? ' Already Added' : ' Added to cart Successfully'}</>);
		api[existingCartItem ? 'info' : 'success']({message, duration: 2, placement: "bottom"});
		const isNotInCart = cartData?.filter(item => item.stock_id === data.stock_id).length === 0;
		if(isNotInCart) await dispatch(setCartData(data));
	};

	const buttonClassName = kycApproved ? action.addToCart ? "added_Cart_btn product_btn text-uppercase w-100" : "product_btn text-uppercase w-100" : "w-100 pointer-events"
	const buttonType = !action.addToCart ? "primary" : ""
	const buttonName = isEqual(action.addToCart, true) ? "In Cart" : " Add to Cart"

	// Show enquiry model
	const diamondEnquiry = (e) => {
		eventPropagation(e)
		handleImageRequest("n_request");
		showEnquiryModal();
	}

	const buttonClickAction = (functionCall) => kycApproved ? functionCall : null
	const className = kycApproved ? 'product_btn text-uppercase w-100' : 'w-100 pointer-events'
	const btnDivClassName = kycApproved ? "mt-2" : "mt-2 blur-text disable-text-selection"

	return (
		<>
			<div className={btnDivClassName}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<Button type="primary" size="large" id="enquiryButton" onClick={(e) => buttonClickAction(diamondEnquiry(e))} className={className}> Enquiry </Button>
					</Col>
					<Col span={12}>
						<Button type={buttonType} size="large" id="cart_data" onClick={(e) => buttonClickAction(handleAddToCart(e))} className={buttonClassName}>{buttonName}
							<ShoppingCartOutlined className="fs-18" id="cart_data" />
						</Button>
					</Col>
				</Row>
			</div>
		</>)
}

const ProductCard = (props) => {
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const kycStatus = userInfo?.data?.kyc_status;

	const [action, setAction] = useState(
		{
			likeIconSelected      : false,
			likeIconLoading       : false,
			enquiryModalOpen      : false,
			addToCart             : false,
			productDetailModalOpen: false,
		});

	const [imageRequest, setIsImageRequest] = useState("");
	const viewType = useSelector((state) => state.auth.accountDetails.data?.view_type) ;

	// This function use for open the product model
	const onCardClick = () => {
		setAction({...action, productDetailModalOpen: true});
	}

	const handleImageRequest = (imageRequest) => {
		setIsImageRequest(imageRequest)
	};

	// Media content
	const mediaContent = <MediaContent data={props.data} api={props.api} status={props.status} action={action} setAction={setAction} handleImageRequest={handleImageRequest}  />

	// Non Kyc Detail View
	const KycProductDetailView = () => {
		const kycApproved = isEqual(kycStatus, "approved")
		const simplifiedViewType = isEqual(viewType, "simplified")
		const cardClickAction = (kycApproved && !simplifiedViewType) ? onCardClick : null
		return (
			<Card className="product_card" cover={mediaContent} onClick={cardClickAction}>
				{kycApproved ? simplifiedViewType ? <SimplifiedDetailCard data={props.data} status={props.status} /> :
							   <ProductDetailCard data={props.data} status={props.status} /> : <NonKycDetailCard data={props.data} status={props.status} viewType={viewType}/>}
				<ProductAction data={props.data} api={props.api} status={props.status} action={action} setAction={setAction} handleImageRequest={handleImageRequest} kycStatus={kycStatus} />
			</Card>
		)
	}

	return (
		<>
			{/*Product Detail Popup*/}
			<EnquiryModel action={action} setAction={setAction} data={props.data} imageRequest={imageRequest} />
			<ProductInfoModel
				action={action}
				setAction={setAction}
				id={props.data.id}
				handleImageRequest={handleImageRequest}
				imageRequest={imageRequest}
				isWishlist={props.isWishlist} />

			{KycProductDetailView()}
		</>
	);
};

export default ProductCard;
