import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Input, Row, Select, Spin, Tabs, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAccountInfo, profileUpdate, updatePassword,} from "../../redux/auth/auth-action";
import {pageName} from "../../helper/other/common";
import countryList from "react-select-country-list";
import {useNavigate} from "react-router-dom";
import AlertMessage from "../../helper/other/AlertMessage";
import {isEmpty, isEqual} from "lodash";
import AddressBook from "./address-book/Index";

const {Title} = Typography;

/**
 * Profile Edit Form
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileUpdateForm = () => {
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const [profileUpdateInfo, setProfileUpdateInfo] = useState({message: "", error: "", loading: false});
	const selectRef = useRef(null);
	const dispatch = useDispatch();

	const initialValue = {
		first_name: userInfo.data.first_name,
		last_name : userInfo.data.last_name,
		email     : userInfo.data.email,
		company   : userInfo.data.company,
		country   : userInfo.data.country,
	};

	const validationForUserInfo = {
		first_name: [
			{
				required: true,
				message : "Please Enter First Name!",
			},
		],
		last_name : [
			{
				required: true,
				message : "Please Enter Last Name!",
			},
		],
		company   : [
			{
				required: true,
				message : "Please Enter Company name!",
			},
		],
		country   : [
			{
				required: true,
				message : "Please Enter Country!",
			},
		]
	}

	//update profile
	const updateProfile = async(value) => {
		setProfileUpdateInfo({...profileUpdateInfo, loading: true})
		const response = await dispatch(profileUpdate(value));
		if(isEqual(response.payload.status, 200)) setProfileUpdateInfo({error: "", message: response.payload.data.message, loading: false})
		else setProfileUpdateInfo({message: "", error: response.payload.response.data.message, loading: false})
	};

	// filter country option based on search
	const filterCountryOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	// after select the option it's not automatically blur when we click the outside then it's blur so that I set blur functionality when select the option
	const handleBlur = (e) => (selectRef.current && !e) && selectRef.current.blur();

	//when click on alert close button
	const onClickCloseAlert = () => setProfileUpdateInfo({message: "", error: "", loading: false})

	return (<div className={'d-flex justify-content-center'}>
			<Card className='password-card'>
				<Form initialValues={initialValue} layout="vertical" className='fw-bold' onFinish={updateProfile} encType="multipart/form-data">
					<Row gutter={[20, 0]}>
						<Col xs={24} sm={24} md={12}>
							<Form.Item label="First Name" name="first_name" rules={validationForUserInfo.first_name}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12}>
							<Form.Item label="Last Name" name="last_name" rules={validationForUserInfo.last_name}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item label="Company name" name="company" rules={validationForUserInfo.company}>
						<Input />
					</Form.Item>

					<Form.Item label="Email" name="email">
						<Input disabled />
					</Form.Item>

					<Form.Item label="Country / Region" name="country" rules={validationForUserInfo.country}>
						<Select options={countryList().getData()} showSearch filterOption={filterCountryOption} ref={selectRef} onDropdownVisibleChange={handleBlur} />
					</Form.Item>

					<Form.Item>
						<Button htmlType="submit" type="primary" shape="round" size="large" className='fw-bold' loading={profileUpdateInfo.loading}>
							Submit
						</Button>
					</Form.Item>
					{!isEmpty(profileUpdateInfo.message) && <AlertMessage message={profileUpdateInfo.message} type="success" closable={true} onClose={onClickCloseAlert} />}
					{!isEmpty(profileUpdateInfo.error) && <AlertMessage message={profileUpdateInfo.error} type="error" closable={true} onClose={onClickCloseAlert} />}
				</Form>
			</Card>
		</div>
	)
		;
}

/**
 * Update Password.
 * @returns {JSX.Element}
 * @constructor
 */
const UpdatePasswordForm = () => {
	const [updatePasswordInfo, setUpdatePasswordInfo] = useState({message: "", error: "", loading: false});
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	//when submit the reset password form
	const resetPassword = async(value) => {
		setUpdatePasswordInfo({...updatePasswordInfo, loading: true})
		const response = await dispatch(updatePassword(value));
		response && form.resetFields();
		if(isEqual(response.payload.status, 200)) setUpdatePasswordInfo({error: "", message: response.payload?.message, loading: false})
		else setUpdatePasswordInfo({message: "", error: response.payload?.message, loading: false})
	};

	const validation = {
		old_password         : [
			{
				required: true,
				message : "Please Enter Current Password!",
			},
			({getFieldValue}) => ({
				validator(_, value) {
					if(value.length >= 6) {
						return Promise.resolve();
					}
					return Promise.reject(
						new Error("The password must be at least 6 characters.")
					);
				},
			}),
		],
		password             : [
			{
				required: true,
				message : "Please Enter New Password!",
			},
			({getFieldValue}) => ({
				validator(_, value) {
					if(value.length >= 6) {
						return Promise.resolve();
					}
					return Promise.reject(
						new Error("The password must be at least 6 characters.")
					);
				},
			}),
		],
		password_confirmation: [
			{
				required: true,
				message : "Please Enter Confirm password!",
			},
			({getFieldValue}) => ({
				validator(_, value) {
					if(!value || getFieldValue("password") === value) {
						return Promise.resolve();
					} else {
						return Promise.reject(
							new Error("The password do not match!")
						);
					}
				},
			}),
		]
	}

	//when click on alert close button
	const onClickCloseAlert = () => setUpdatePasswordInfo({message: "", error: "", loading: false})

	return (
		<div className={'d-flex justify-content-center'}>
			<Card className='password-card'>
				<Form layout="vertical" className='fw-bold' onFinish={resetPassword} form={form}>

					<Form.Item label="Current Password" name="old_password" rules={validation.old_password}>
						<Input.Password />
					</Form.Item>

					<Form.Item label="New Password" name="password" rules={validation.password}>
						<Input.Password />
					</Form.Item>

					<Form.Item label="Confirm Password" name="password_confirmation" dependencies={["password"]} rules={validation.password_confirmation}>
						<Input.Password />
					</Form.Item>

					<Form.Item>
						<Button htmlType="submit" type="primary" shape="round" size="large" loading={updatePasswordInfo.loading} className='fw-bold'>
							Submit
						</Button>
					</Form.Item>
					{updatePasswordInfo.message !== "" && (<AlertMessage message={updatePasswordInfo.message} type="success" closable={true} onClose={onClickCloseAlert} />)}
					{updatePasswordInfo.error !== "" && <AlertMessage message={updatePasswordInfo.error} type="error" closable={true} onClose={onClickCloseAlert} />}
				</Form>
			</Card>
		</div>
	)
}

const MyAccount = () => {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const [tabPosition, setTabPosition] = useState(window.innerWidth < 768 ? 'top' : 'left');
	const navigate = useNavigate();
	const isKycSkip = isEqual(userInfo?.data?.kyc_status, 'skipped');
	//fetch user info data
	const fetchUserInfo = async() => dispatch(getAccountInfo({navigate, isKycSkip}));

	useEffect(() => {
		document.title = `My Account -  ${pageName}`;
		fetchUserInfo();
	}, []);

	const TabChildComponent = ({children}) => {
		return <div className={'tab-content'}>{children}</div>;
	}

	//tab content
	const tabContent = [
		{label: 'Profile', children: <TabChildComponent><ProfileUpdateForm /></TabChildComponent>, key: '1', icon: <i className="fa fa-thin fa-user" />},
		{label: 'Change Password', children: <TabChildComponent><UpdatePasswordForm /></TabChildComponent>, key: '2', icon: <i className="fa fa-key" />},
		{label: 'Address Book', children: <TabChildComponent><AddressBook/></TabChildComponent>, key: '3', icon: <i class="fa fa-home" />},
	]

	//for show tab position
	window.addEventListener('resize', () => {
		const position = window.innerWidth <= 768 ? 'top' : 'left';
		setTabPosition(position);
	});

	return (
		<div className='account-container'>
			<Title className='d-flex justify-content-center page-title'>My Account</Title>
			{userInfo.loading && (<div className="loading-data"><Spin size="large" /></div>)}
			{!userInfo.loading && <Tabs items={tabContent} tabPosition={tabPosition} size={'large'} type={'card'} animated={{inkBar: false, tabPane: false}} />}
		</div>
	);
};

export default MyAccount;
