import React from 'react'
import {Col, Image, Row, Space, Typography} from "antd";
import '../layout.css'

const {Text, Title} = Typography
// todo:change component name to Footer
const FooterPage = () => {
	return (
		<Row gutter={[20, 40]}>
			<Col md={5}>
				<Image className="footer-logo" src="/images/DiaHearts Logo2.png" preview={false} alt="Diahearts Logo" />
			</Col>
			<Col md={5} className={"footer-column"}>
				<Space direction={"vertical"}>
					<Title level={5} className={"uppercase"}>Opening Time</Title>
					<Text type="secondary">Mon-Sat</Text>
					<Text type="secondary">09:30-18:30</Text>
				</Space>
			</Col>
			<Col md={8} lg={6} className={"footer-column"}>
				<Space direction="vertical">
					<Title level={5} className={"uppercase"}>Shipping Partners</Title>
					<Text type="secondary">Trackable Shipping 24 / 7</Text>
					<Text type="secondary">Free Shipping</Text>
					<Space wrap>
						<Image src="/images/Footer Image/ups.png" className="footer-image" preview={false} />
						<Image src="/images/Footer Image/Layer-778.png" className="footer-image" preview={false} />
						<Image src="/images/Footer Image/fed-ex.png" className="footer-image" preview={false} />
						<Image src="/images/Footer Image/brinks-logo.png" className="footer-image" preview={false} />
						<Image src="/images/Footer Image/dhl.jpg" className="footer-image" preview={false} />
					</Space>
				</Space>
			</Col>
			<Col md={6} className={"footer-column"}>
				<Space direction="vertical" className={"lab-list"}>
					<Title level={5}>Certified Diamonds</Title>
					<Image src="/images/Footer Image/GIA.png" preview={false} />
					<Image src="/images/Footer Image/IGI.png" preview={false} />
					<Image src="/images/Footer Image/hrd.png" preview={false} />
				</Space>
			</Col>
		</Row>
	)
}
export default FooterPage