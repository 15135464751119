import React from 'react'
import {Alert} from "antd";

const AlertMessage = (props) => {
	let {className, ...rest} = props;
	return (
		<Alert className= {`${className || ''} text-center fw-bold mt-2`} {...rest}/>
	)
}

export default AlertMessage