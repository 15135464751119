import React, {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, Form, Input, notification, Select,} from "antd";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import {addAddress, fetchAddress, updateAddress} from "../../../redux/address/address-action";
import {trim} from "lodash";
import {useSelector} from "react-redux";

const AddressBookForm = ({isEditable, handleModalCancel, id, fetchAddressList, setModalOpen}) => {
	const [contactNoDetail, setContactNoDetail] = useState({countryCode: "GB", error: false});
	const [countryDetail, setCountryDetail] = useState({countryCode: "GB", dialCode: ""});
	const selectRef = useRef(null);
	const [form] = Form.useForm();
	const [api, notificationHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const addressData = useSelector(state => state.addressBook.address);

	const obj = {
		full_name : "",
		address_1 : "",
		address_2 : "",
		city      : "",
		state     : "",
		postcode  : "",
		country   : "",
		contact_no: "",
		is_default: false,
	}
	const [initialValue, setInitialValue] = useState(obj);

	const fetchAddressData = async() => {
		const data = addressData.find(item => item.id === id);
		setInitialValue({
							full_name : data.full_name,
							address_1 : data.address_1,
							address_2 : data.address_2,
							city      : data.city,
							state     : data.state,
							postcode  : data.postcode,
							country   : data.country,
							contact_no: data.contact_no,
							is_default: data.is_default,
						});

		setContactNoDetail({countryCode: data.country, error: false});
	}


	//change initail value in state
	useEffect(() => {
		if(isEditable) {
			fetchAddressData();
		} else {
			setInitialValue(obj);
			setContactNoDetail({countryCode: 'GB', error: false});
		}
	}, [isEditable, id]);


	//set initial value in form
	useEffect(() => {
		form.setFieldsValue(initialValue);

	}, [initialValue, form]);

	// form validation
	const validationForUserInfo = {
		address_1 : [
			{
				required: true,
				message : "Please Enter Address!",
			},
		],
		city      : [
			{
				required: true,
				message : "Please Enter City!",
			},
		],
		postcode  : [
			{
				required: true,
				message : "Please Enter Post Code Number!",
			},
		],
		country   : [
			{
				required: true,
				message : "Please Enter Country!",
			},
		],
		contact_no: [
			{
				required: true,
				message : "Please Enter Contact Number!",
			},
			{
				validator: (_, value) => value === countryDetail.dialCode ? Promise.reject('Please Enter Contact Number!') : Promise.resolve()
			}
		],
	};

	// add address data
	const addAddressData = async(value) => {
		const response = await addAddress(value)
		setLoading(false);
		if(response.status === 200) {
			api.success({message: response.data.message, placement: 'topRight'});
		} else {
			api.error({message: response.data.message, placement: 'topRight'});
		}
	}

	// update address data
	const updateAddressData = async(value) => {
		const response = await updateAddress(id, value)
		console.log('response', response)
		setLoading(false);
		if(response.status === 200) {

			api.success({message: response.data.message, placement: 'topRight'});
		} else {
			api.error({message: response.data.message, placement: 'topRight'});
		}

	}

	// submit form
	const onSubmit = async(value) => {
		setLoading(true);
		isEditable ? await updateAddressData(value) : await addAddressData(value);
		fetchAddressList();
		setModalOpen(false);
	};

	// filter country option based on search
	const filterCountryOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	// after select the option it's not automatically blur when we click the outside then it's blur so that I set blur functionality when select the option
	const handleBlur = (e) => (selectRef.current && !e) && selectRef.current.blur();

	// when select the contanct no then set the country code and dial code
	const handleContactChange = (e, value) => {
		if(!trim(e) || e == value.dialCode) setContactNoDetail({...contactNoDetail, error: true});
		else setContactNoDetail({...contactNoDetail, error: false});
		setCountryDetail({dialCode: value.dialCode, countryCode: value.countryCode})
	};

	// when select the country then set the country code
	const handleCountryChange = (e) => {
		if(e !== countryDetail.countryCode) {
			form.setFieldValue('contact_no', "");
		}

		setContactNoDetail({...contactNoDetail, countryCode: e});
	}

	return (
		<>
			{notificationHolder}
			<Form form={form} layout="vertical" className='fw-bold' onFinish={onSubmit} encType="multipart/form-data">
				<Form.Item label="Name" name="full_name" rules={validationForUserInfo.address_1}>
					<Input />
				</Form.Item>

				<Form.Item label="Street address" name="address_1" rules={validationForUserInfo.address_1}>
					<Input placeholder="House Number and street name" />
				</Form.Item>

				<Form.Item name="address_2">
					<Input placeholder="Apartment, suite, unit, etc." />
				</Form.Item>

				<Form.Item label="Town / City" name="city" rules={validationForUserInfo.city}>
					<Input />
				</Form.Item>

				<Form.Item label="County / State" name="state">
					<Input />
				</Form.Item>

				<Form.Item label="Postcode" name="postcode" rules={validationForUserInfo.postcode}>
					<Input />
				</Form.Item>

				<Form.Item label="Country / Region" name="country" rules={validationForUserInfo.country}>
					<Select options={countryList().getData()}
							showSearch
							filterOption={filterCountryOption}
							ref={selectRef}
							onChange={handleCountryChange}
							onDropdownVisibleChange={handleBlur}
							value={contactNoDetail.countryCode} />
				</Form.Item>

				<Form.Item label="Phone" name='contact_no' rules={validationForUserInfo.contact_no}>
					<PhoneInput
						country={contactNoDetail.countryCode.toLowerCase()}
						name='contact_no'
						placeholder="Enter contact number"
						inputClass={`phone-input ${contactNoDetail.error ? 'error' : ''}`}
						enableSearch
						onChange={handleContactChange}
					/>
				</Form.Item>

				<Form.Item name="is_default" valuePropName="checked">
					<Checkbox> Set this address as default</Checkbox>
				</Form.Item>

				<div className={'d-flex justify-content-end'}>
					<Form.Item>
						<Button size="large" className='fw-bold me-2' onClick={handleModalCancel}>
							Cancel
						</Button>
						<Button htmlType="submit" type="primary" size="large" className='fw-bold' loading={loading}>
							{isEditable ? "Update" : "Create"}
						</Button>
					</Form.Item>
				</div>
			</Form>
		</>
	);
}

export default AddressBookForm;
