import React, {useEffect, useMemo, useState} from 'react'
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import {pageName} from '../../helper/other/common';

const ExtraContent = () => {
	const navigate = useNavigate()
	let [second, setSecond] = useState(10);
	let [isAllow, setIsAllow] = useState(false);

	// Count Interval time par second
	useMemo(() => {
		let timeCount = 10
		let intervalTime = setInterval(() => {
			timeCount -= 1
			setSecond(timeCount)
			// clear interval time after item count is less than 1
			if(timeCount < 1) {
				setIsAllow(true)
				setSecond(timeCount)
				clearInterval(intervalTime)
			}
		}, 1000)
	}, [])

	// On Button Click
	const onButtonClick = (path) => navigate(path)

	// Button array with path and label
	const buttonArray = [
		{label: "Natural", path: '/natural'},
		{label: "Lab-Grown", path: '/lab-grown'}
	]

	// List of button view
	const ButtonView = () => buttonArray.map(value => <Button type="primary" onClick={() => onButtonClick(value.path)}>{value.label}</Button>)

	// Return JSX element
	return isAllow ? <>{ButtonView()}</> : <p>waiting for new request: {second}</p>
}

const TooManyAttempt = () => {

	useEffect(() => {
		document.title = `Too Many Request-${pageName}`
	}, [])

	return (
		<Result icon={<img src={'/images/many-attempt.png'} className='error-image'/>}
				title="429"
				subTitle="Due to heavy load, we can't load data at the moment. We'll get back soon"
				extra={<ExtraContent />} />
	)
}

export default TooManyAttempt