import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAccountInfo, userRegistration} from "../redux/auth/auth-action";
import {Link, useNavigate} from "react-router-dom";
import {pageName} from "../helper/other/common";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import CryptoJS from "crypto-js";
import AlertMessage from "../helper/other/AlertMessage";
import {isEqual, toString, trim} from 'lodash'
import {setIsLogin} from "../redux/diamond/diamond-slice";
import {fetchSettingStatus} from "../redux/order/order-action";
import {orderFilterObj} from "../helper/constant/filter-constant";

const {Title, Text} = Typography;

/**
 * Form Input Fields
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const RegistrationForm = () => {
	const dispatch = useDispatch();

	const register = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [error, setError] = useState(false);
	const [isPhoneValidation, setIsPhoneValidation] = useState(false);

	// OnSubmit register function
	const onSubmit = async(values) => {
		const res = await dispatch(userRegistration({data: values, navigate: navigate}));
		if(isEqual(res.payload.status, true)) {
			dispatch(setIsLogin(true))
			// Encrypt
			let cipherId = toString(CryptoJS.AES.encrypt(toString(res.payload.user_id), process.env.REACT_APP_SECRET_KEY))
			localStorage.setItem("cipherId", cipherId)
			dispatch(fetchSettingStatus());
			dispatch(getAccountInfo({navigate}))
			//set color for filter data which is selected by default
			localStorage.setItem("colorName", "white")
			localStorage.setItem("orderFilter", JSON.stringify(orderFilterObj))
		} else setError(res?.payload)
	};


	// Form validation with promise method
	const fieldValidation = ({getFieldValue}, field, message = 'Values are not matching') => ({
		validator(_, value) {
			if(!value || getFieldValue(field) === value) {
				return Promise.resolve()
			}
			return Promise.reject(
				new Error(message)
			);
		},
	});

	//when change the mobile number
	const handlePhoneChange = (value) => {
		// Validate if the phone number is required
		if(!trim(value)) setIsPhoneValidation(true);
		else setIsPhoneValidation(false);
	};


	// Form field validation rules
	const fieldValidationRules = {
		first_name      : [
			{
				required: true,
				message : "First name is required"
			}
		],
		last_name       : [
			{
				required: true,
				message : "Last name is required"
			}
		],
		email           : [
			{
				required: true,
				message : "Email is Required"
			},
			{
				type   : "email",
				message: "Invalid Email"
			}
		],
		confirm_email   : [
			{
				required: true,
				message : "Please Enter Email Again!"
			},
			{
				type   : "email",
				message: "Please Enter Valid Email Address!"
			},
			({getFieldValue}) => fieldValidation({getFieldValue}, "email", "Emails do not match!")
		],
		password        : [
			{
				required: true,
				message : "Password is Required"
			}
		],
		confirm_password: [
			{
				required: true,
				message : "Please Enter Confirm password!"
			},
			({getFieldValue}) => fieldValidation({getFieldValue}, "password", "The Password do not match!")
		],
		mobile          : [
			{
				required: true,
				message : "Phone Number is Required"
			}
		],
		company         : [
			{
				required: true,
				message : "Company Name is Required"
			}
		],
	};

	return (
		<Form layout="vertical" className="fw-bold" encType="multipart/form-data" onFinish={onSubmit} form={form}>
			<Row gutter={[20, 0]}>
				<Col xs={24} md={12}>
					<Form.Item label="First Name" name="first_name" rules={fieldValidationRules.first_name}>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Last Name" name="last_name" rules={fieldValidationRules.last_name}>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Company Email" name="email" rules={fieldValidationRules.email} hasFeedback>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Confirm Email" name="confirm_email" dependencies={["email"]} rules={fieldValidationRules.confirm_email} hasFeedback>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Password" name="password" rules={fieldValidationRules.password} hasFeedback>
						<Input.Password />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Confirm Password" name="password_confirmation" dependencies={["password"]} rules={fieldValidationRules.confirm_password} hasFeedback>
						<Input.Password />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Mobile Number" name="mobile" rules={fieldValidationRules.mobile}>
						<PhoneInput country={'gb'} name="mobile" placeholder="Enter phone number" inputClass={`phone-input ${isPhoneValidation ? 'error' : ''}`} enableSearch onChange={handlePhoneChange} />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Company Name" name="company" rules={fieldValidationRules.company}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item className="justify-content-center d-flex">
				<Button htmlType="submit" type="primary" loading={register.registrationLoading}>
					Register
				</Button>
			</Form.Item>

			{/*Footer content*/}
			<Text className="primary-text d-flex justify-content-center fw-bold">
				Already have an account?
				<Link className="text-decoration-underline primary-text ps-1" to={"/login"}> Login </Link>
			</Text>

			{/*Alert error*/}
			{error && <AlertMessage message={error} type="error" closable={true} onClose={() => setError(false)} />}
		</Form>
	)
}

const RegistrationPage = () => {
	useEffect(() => {
		document.title = `Registration -  ${pageName}`;
	}, []);

	return (
		// Start Section
		<section className="pt-4 pb-4 container">
			<Title className="text-center page-title" level={1}>Registration</Title>
			<Card className="max-w-400 m-auto">
				{/*Register Form*/}
				<RegistrationForm />
			</Card>
		</section>
		// End Section
	);
};

export default RegistrationPage;