import React from 'react'
import {Image} from "antd";
import {EyeOutlined} from "@ant-design/icons";

const PreviewImage = (props) => {

	return (
		<div className="table-image">
			<Image className='preview-image' {...props} />
			<div className="image-icons ">
				<span><EyeOutlined className='text-white fs-6 eye-icon' /></span>
			</div>
		</div>
	)
}

export default PreviewImage
