import axios from 'axios';
import {URL} from "../other/url-helper";
import {removeData} from "../other/common";
import globalRouter from "../../routing/global-router";
import {includes, isEqual} from "lodash";
import store from "../../redux";
import {removeToken} from "../../redux/auth/auth-slice";

export const Api = axios.create({baseURL: URL});

Api.interceptors.request.use(function(config) {
	const token = localStorage.getItem("token");
	if(token) config.headers['Authorization'] = `Bearer ${token}`;
	return config;
}, function(error) {
	if(isEqual(error.code, "ERR_NETWORK")) globalRouter.navigate('/serverError')
	return error
});

Api.interceptors.response.use(function(response) {
	return response;
}, function(error) {
	const pathArrOfObj = {home: ['/', '/home'], diamond: ['/natural', '/lab-grown']}
	const isHome = includes(pathArrOfObj.home, window.location.pathname)
	const isDiamondPage = includes(pathArrOfObj.diamond, window.location.pathname)

	if(isEqual(error.code, "ERR_NETWORK")) globalRouter.navigate('/serverError')
	else if(isEqual(error.response.status, 403) && !isEqual(window.location.pathname, '/email/verify') && !isHome && !isDiamondPage) {
		localStorage.setItem("email_verified", "false");
		globalRouter.navigate('/email/verify')
	} else if(isEqual(error.response.status, 401) && !isEqual(window.location.pathname, '/login') && !isHome && !isDiamondPage) {
		removeData()
		store.dispatch(removeToken())
		globalRouter.navigate('/login')
	} else if(isEqual(error.response.status, 429)) globalRouter.navigate('/many-attempt')
	else if(isEqual(error.response.status, 401)) globalRouter.navigate('/login')

	return error
});


// Abort API call if it is already in progress
let abortController = new AbortController();
export const abortApiCall = async(method, slug, data = {}) => {
	try {
		abortController.abort();
		abortController = new AbortController();
		const token = localStorage.getItem("token");
		let requestData = {
			headers: {Authorization: `Bearer ${token}`},
			signal : abortController.signal,
		};

		if(isEqual(method.toLowerCase(), 'get')) requestData.params = data;
		else requestData.data = data;

		const response = await axios({method: method.toUpperCase(), url: `${URL}${slug}`, ...requestData});
		return response;
	} catch(error) {
		return {error};
	}
}
