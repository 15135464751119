import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import LogInPage from "../auth/LogIn";
import {NaturalPage} from "../pages/diamond/Natural";
import LabGrownPage from "../pages/diamond/LabGrown";
import ProtectedRoute from "./ProtectedRoute";
import CartPage from "../pages/cart/Cart";
import CheckOutPage from "../pages/check-out/Checkout";
import ThankYouPage from "../pages/thank-you/ThankYou";
import RegistrationPage from "../auth/Registration";
import VerifyEmail from "../pages/email/VerifyEmail";
import DiamondsPage from "../pages/diamond/DiamondsPage";
import {EnquiryPage} from "../pages/enquiry/EnquiryListing";
import ForgotPasswordPage from "../auth/ForgotPassword";
import ResetPasswordPage from "../auth/ResetPassword";
import EnquiryDetailPage from "../pages/enquiry/EnquiryDetail";
import MyAccount from "../pages/account/MyAccount";
import KycStatus from "../pages/account/Kyc";
import UnProtectedRoute from "./UnProtectedRoute";
import Boundary from "../error/error-boundary/ErrorBoundary.jsx";
import WishListPage from "../pages/wishlist/WishList";
import HomePage from "../pages/home/Home";
import PageNotFound from "../error/pages/PageNotFound";
import ErrorPage from "../error/pages/Error";
import ServerErrorPage from "../error/pages/ServerError";
import AddDeleteTableRows from "../pages/loose-diamond/index.jsx";
import OrderPage from "../pages/order/OrderListing";
import OrderDetailsPage from "../pages/order/OrderDetail";
import TooManyAttempt from "../error/pages/TooManyRequest";
import globalRouter from "./global-router";

const Routing = () => {

	// Implement the navigate for handle the global router
	const navigate = useNavigate();
	globalRouter.navigate = navigate;

	return (
		<>
			<Routes>
				<Route exact path="/kyc" element={<Boundary><KycStatus /></Boundary>} />
				<Route exact path="*" element={<Boundary><PageNotFound /></Boundary>} />
				<Route exact path="/unauthorize" element={<Boundary><ErrorPage /></Boundary>} />
				<Route exact path="/email/verify" element={<Boundary><VerifyEmail /></Boundary>} />
				<Route exact path="/serverError" element={<Boundary><ServerErrorPage /></Boundary>} />
				<Route exact path="/many-attempt" element={<Boundary><TooManyAttempt /></Boundary>} />
				<Route exact path="/email/verify/:id/:token" element={<Boundary><VerifyEmail /></Boundary>} />

				{/* Protected Route */}
				<Route exact path="/wishlist" element={<ProtectedRoute><WishListPage /> </ProtectedRoute>} />
				<Route exact path="/cart" element={<ProtectedRoute> <Boundary><CartPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/order" element={<ProtectedRoute><Boundary><OrderPage /> </Boundary></ProtectedRoute>} />
				<Route exact path="/enquiry" element={<ProtectedRoute><Boundary><EnquiryPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/natural" element={<ProtectedRoute> <Boundary><NaturalPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/my-account" element={<ProtectedRoute><Boundary><MyAccount /></Boundary></ProtectedRoute>} />
				<Route exact path="/checkout" element={<ProtectedRoute> <Boundary><CheckOutPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/diamonds" element={<ProtectedRoute> <Boundary><DiamondsPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/lab-grown" element={<ProtectedRoute> <Boundary><LabGrownPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/loose-diamond" element={<ProtectedRoute><Boundary><AddDeleteTableRows /></Boundary></ProtectedRoute>} />
				<Route exact path="/order/:orderId" element={<ProtectedRoute> <Boundary><OrderDetailsPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/:orderId/thank-you" element={<ProtectedRoute> <Boundary><ThankYouPage /></Boundary></ProtectedRoute>} />
				<Route exact path="/enquiry/:enquiryId" element={<ProtectedRoute><Boundary><EnquiryDetailPage /></Boundary></ProtectedRoute>} />

				{/* UnProtected Routes */}
				<Route exact path="/" element={<UnProtectedRoute><Boundary><HomePage /></Boundary></UnProtectedRoute>} />
				<Route exact path="/home" element={<UnProtectedRoute><Boundary><HomePage /></Boundary></UnProtectedRoute>} />
				<Route exact path="/login" element={<UnProtectedRoute><Boundary><LogInPage /></Boundary></UnProtectedRoute>} />
				<Route exact path="/register" element={<UnProtectedRoute><Boundary><RegistrationPage /></Boundary></UnProtectedRoute>} />
				<Route exact path="/reset-password" element={<UnProtectedRoute><Boundary><ResetPasswordPage /></Boundary></UnProtectedRoute>} />
				<Route exact path="/forgot-password" element={<UnProtectedRoute><Boundary><ForgotPasswordPage /></Boundary></UnProtectedRoute>} />
			</Routes>
		</>
	);
};

export default Routing;


