import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row, Space, Typography} from "antd";
import {forgotPassword} from "../redux/auth/auth-action";
import {useDispatch, useSelector} from "react-redux";
import {pageName} from "../helper/other/common";
import AlertMessage from "../helper/other/AlertMessage";
import {isEqual} from 'lodash'
import {useNavigate} from "react-router-dom";
import {setForgotPasswordMessage} from "../redux/auth/auth-slice";

const {Text, Title} = Typography;
const ForgotPasswordPage = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [message, setMessage] = useState({type: "", message: "", show: false})
	const passwordInfo = useSelector((state) => state?.auth);
	const [form] = Form.useForm();

	// Send the mail for reset password
	const onSubmit = async(values) => {
		const res = await dispatch(forgotPassword({data: values}));
		res && form.resetFields();
		if(isEqual(res.payload.status, 200)) {
			navigate('/login')
			dispatch(setForgotPasswordMessage({message: res.payload.data.message, show: true}))
		} else {
			setMessage({type: "error", message: res.payload, show: true})
			dispatch(setForgotPasswordMessage({message: res.payload, show: false}))
		}
	};

	useEffect(() => {
		document.title = `Forgot Password -  ${pageName}`;
	}, []);

	// field validation
	const fieldValidationRules = {
		email: [
			{
				type   : "email",
				message: "The input is not a valid E-mail!"
			},
			{
				required: true,
				message : "Please input you E-mail!"
			}
		]
	}

	//when click on alert close button
	const closeAlert = () => setMessage({type: "", message: "", show: false})

	return (
		<section className="mt-100">
			<Title className="text-center page-title">Forgot Password</Title>
			<Row justify="center">
				<Col>
					<Card className="p-1">
						<Space direction="vertical">
							<Text className="desc-text"> Forgot Password? Please enter your username or email address. </Text>
							<Text className="desc-text"> You will receive a link to create a new password via email. </Text>
							<Form layout="vertical"
								  form={form}
								  onFinish={onSubmit}
								  className="fw-bold mt-2"
								  requiredMark={false}
								  encType="multipart/form-data">
								<Form.Item label="Email address" name="email" rules={fieldValidationRules.email}>
									<Input />
								</Form.Item>
								<Form.Item className="d-flex justify-content-center">
									<Button type="primary" htmlType="submit" className="text-uppercase mt-1" loading={passwordInfo.forgotPassword.loading}> Reset Password </Button>
								</Form.Item>
							</Form>
							{/*Alert Message*/}
							{message.show && <AlertMessage message={message.message} type={message.type} closable={true} onClose={closeAlert} />}
						</Space>
					</Card>
				</Col>
			</Row>
		</section>);
};

export default ForgotPasswordPage;
