import React, {useEffect, useMemo, useRef, useState} from "react";
import {Badge, Button, Card, Checkbox, Col, Divider, Form, Input, notification, Radio, Row, Select, Space, Spin, Table, Typography,} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {countCommission, countSubTotal, dateFormatting, diamondString, exchangeRate, formatPrice, isEmpty, pageName, scrollTo,} from "../../helper/other/common";
import DiamondConstData from "../../helper/constant/diamond-constant";
import {CloseCircleFilled} from "@ant-design/icons";
import {orderCheckout} from "../../redux/checkout/checkout-action";
import {removeAllCartData} from "../../redux/cart/cart-slice";
import {DIAMONDSHAPE, filterArray} from "../../helper/constant/filter-constant";
import countryList from "react-select-country-list";
import PreviewImage from "../../components/utils/image-view/PreviewImage";
import PhoneInput from "react-phone-input-2";
import {includes, lastIndexOf, lowerCase, omit, reduce, trim} from "lodash";
import {getAccountInfo} from "../../redux/auth/auth-action";
import {setFilterData} from "../../redux/diamond/diamond-slice";
import {addAddress, fetchAddresses, updateAddress} from "../../redux/address/address-action";

const {TextArea} = Input;
const {Title, Text} = Typography;

/**
 * Billing Form
 * @param values
 * @returns {Promise<void>}
 */
const CheckoutForm = ({name, updateSellingDetail, form}) => {
	const dispatch = useDispatch()
	const [require, setRequire] = useState(true)
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const [phoneDetail, setPhoneDetail] = useState({countryCode: userInfo?.data?.address?.country ?? "GB", error: false})
	const selectRef = useRef(null);
	const [dropdownOption, setDropdownOption] = useState([{}])
	const [selectedAddress, setSelectedAddress] = useState('new')
	const [addressList, setAddressList] = useState([])
	const [isBtnEnable, setIsBtnEnable] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [countryDetail, setCountryDetail] = useState({countryCode: "GB", dialCode: ""});
	const [api, notificationHolder] = notification.useNotification();

	useEffect(() => {
		(updateSellingDetail === false && name === "shipping") && setRequire(false)
	})

	// filter country option based on search
	const filterCountryOption = (input, option) => includes(lowerCase(option?.label ?? ''), lowerCase(input));

	//empty address when user select new address
	let emptyAddress = {
		first_name: userInfo.data.first_name,
		last_name : userInfo.data.last_name,
		country   : userInfo.data.country,
		address_1 : '',
		address_2 : '',
		city      : '',
		state     : '',
		postcode  : '',
		contact_no: '',
	}

	//set form values
	const handleAddressFieldValue = (address) => {
		form.setFieldsValue({
								[name]: {
									...[name], ...{
										first_name: address.first_name,
										last_name : address.last_name,
										country   : address.country,
										address_1 : address.address_1,
										address_2 : address.address_2,
										city      : address.city,
										state     : address.state,
										postcode  : address.postcode,
										phone     : address.contact_no,
									}
								}
							})
	}

	// fetch All address and set a defualt/new address in dropdown
	const fetchAllAddress = async(id) => {
		const response = await dispatch(fetchAddresses())
		const data = response.payload
		setAddressList(data)
		const optionList = data.map((address) => {
			const country = countryList().getLabel(address.country) || address.country;
			const extraAddress = address.address_2 ? `${address.address_2},` : '';
			const state = address.state ? `${address.state},` : '';

			const fullAddress = `${address.address_1}, ${extraAddress} ${address.city}, ${state} ${address.postcode}, ${country}`;
			return {label: fullAddress, value: address.id,}
		})

		//fetch default address and set it as selected
		const defaultAddress = id ? data.filter((address) => address.id === id) : data.filter((address) => address.is_default)
		if(defaultAddress.length > 0) {
			setSelectedAddress(defaultAddress[0].id)
			const fullName = defaultAddress[0].full_name.split(" ");
			const firstName = fullName[0];
			const lastName = fullName[1] || "";
			handleAddressFieldValue({...defaultAddress[0], first_name: firstName, last_name: lastName})
		}

		setDropdownOption([...optionList, {label: 'Add New Address', value: 'new'}])
	}

	useEffect(() => {
		fetchAllAddress()
	}, [])

	// field validation rules
	const fieldValidationRules = {
		first_name   : [
			{
				required: require,
				message : "Please Enter First Name!"
			},
		],
		last_name    : [
			{
				required: require,
				message : "Please Enter Last Name!"
			},
		],
		country      : [
			{
				required: true,
				message : "Please Enter Country!",
			},
		],
		company_name : [
			{
				required: true,
				message : "Please Enter Company Name!",
			},
		],
		address_1    : [
			{
				required: require,
				message : "Please Enter Address!",
			},
		],
		city         : [
			{
				required: require,
				message : "Please Enter City!",
			},
		],
		postcode     : [
			{
				required: require,
				message : "Please Enter Post Code Number!",
			},
		],
		phone        : [
			{
				required: require,
				message : "Please Enter Phone Number!",
			},
			{
				validator: (_, value) => value === countryDetail.dialCode ? Promise.reject('Please Enter phone Number!') : Promise.resolve()
			}
		],
		email_address: [
			{
				type   : "email",
				message: "The input is not valid E-mail!",
			},
			{
				required: require,
				message : "Please Enter Email!",
			},
		],
	}

	//when change a phone number
	const handlePhoneChange = (e, value) => {
		// Validate if the phone number is required
		setIsBtnEnable(true)
		if(!trim(e) || e == value.dialCode) setPhoneDetail({...phoneDetail, error: true});
		else setPhoneDetail({...phoneDetail, error: false});
		setCountryDetail({dialCode: value.dialCode, countryCode: value.countryCode})
	};

	//change on country
	const handleCountryChange = (e) => {
		setIsBtnEnable(true)
		if(e !== countryDetail.countryCode) {
			form.setFieldValue([name, 'phone'], "");
		}

		setPhoneDetail({...phoneDetail, countryCode: e})
	}

	// add address data
	const addAddressData = async(value) => {
		const response = await addAddress(value)
		setIsLoading(false);
		if(response.status === 200) {
			setIsBtnEnable(false)
			api.success({message: response.data.message, placement: 'topRight'});
			fetchAllAddress(response.data.address.id)
		} else {
			api.error({message: response.data.message, placement: 'topRight'});
		}
	}

	// update address data
	const updateAddressData = async(id, value) => {
		const response = await updateAddress(id, value)
		setIsLoading(false);
		if(response.status === 200) {
			setIsBtnEnable(false)
			api.success({message: response.data.message, placement: 'topRight'});
			fetchAllAddress(id)
		} else {
			api.error({message: response.data.message, placement: 'topRight'});
		}

	}
	const handleAddressAction = async() => {
		form.validateFields().then(values => {

			const billingDetails = values.billing;

			let address = {...billingDetails};
			address.full_name = `${address.first_name} ${address.last_name}`
			address.contact_no = address.phone

			// Remove unnecessary fields
			const updatedValue = omit(address, ['first_name', 'last_name', 'company_name', 'phone']);

			// Disable the button after successful validation
			setIsBtnEnable(false);
			setIsLoading(true)
			// Determine whether to add or update an address
			if(selectedAddress === 'new') {
				addAddressData(updatedValue)
			} else {
				updateAddressData(selectedAddress, updatedValue)
			}

		})
			.catch(errorInfo => {
				// Extract error fields and set them
				const {errorFields} = errorInfo;

				// Set the fields with errors
				form.setFields(errorFields);
			});

	};


	// handle change address dropdown
	const handleAddressChange = (value) => {
		setIsBtnEnable(false);
		setSelectedAddress(value)
		let selectedAddress = addressList.filter((address) => address.id === value)
		let address = () => {
			if(value !== 'new') {
				const fullName = selectedAddress[0].full_name.split(" ");
				const firstName = fullName[0];
				const lastName = fullName[1] || "";
				return {...selectedAddress[0], first_name: firstName, last_name: lastName}
			} else {
				return emptyAddress
			}
		}

		handleAddressFieldValue(address())
	}

	// set address update/save button name
	const btnName = useMemo(() => {
		return selectedAddress === 'new' ? 'Save Address into my Address book' : 'Update Saved Address'
	}, [selectedAddress])

	//when change field value then set enable/disable in button
	const handleFieldChange = () => {
		setIsBtnEnable(true);
	};

	return (
		<>
			{notificationHolder}
			<Form.Item label="Select Address">
				<Select className={'address-select'} placeholder={'Select Address'} options={dropdownOption} onChange={handleAddressChange} value={selectedAddress} />
			</Form.Item>
			<Row gutter={[30, 10]}>
				<Col xs={24} md={12}>
					<Form.Item label="First Name" name={[name, "first_name"]} rules={fieldValidationRules.first_name}>
						<Input onChange={handleFieldChange} />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Last Name" name={[name, "last_name"]} rules={fieldValidationRules.last_name}>
						<Input onChange={handleFieldChange} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[30, 10]}>
				<Col xs={24} md={12}>
					<Form.Item label="Company name" name={[name, "company_name"]} rules={fieldValidationRules.company_name}>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Country / Region" name={[name, "country"]} rules={fieldValidationRules.country}>
						<Select options={countryList().getData()}
								showSearch
								filterOption={filterCountryOption}
								ref={selectRef}
								onChange={handleCountryChange}
								onDropdownVisibleChange={(e) => selectRef.current && !e && selectRef.current.blur()}
								value={phoneDetail.countryCode} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[30, 10]}>
				<Col xs={24} md={12}>
					<Form.Item label="Street address" name={[name, "address_1"]} rules={fieldValidationRules.address_1}>
						<Input placeholder="House Number and street name" onChange={handleFieldChange} />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="  " name={[name, "address_2"]}>
						<Input placeholder="Apartment, suite, unit, etc." onChange={handleFieldChange} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[30, 10]}>
				<Col xs={24} md={12}>
					<Form.Item label="Town / City" name={[name, "city"]} rules={fieldValidationRules.city}>
						<Input placeholder='Enter city' onChange={handleFieldChange} />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="County" name={[name, "state"]}>
						<Input placeholder='Enter county' onChange={handleFieldChange} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[30, 10]}>
				<Col xs={24} md={12}>
					<Form.Item label="Postcode" name={[name, "postcode"]} rules={fieldValidationRules.postcode}>
						<Input placeholder='Enter postcode' onChange={handleFieldChange} />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item label="Phone" name={[name, "phone"]} rules={fieldValidationRules.phone}>
						<PhoneInput country={phoneDetail.countryCode.toLowerCase()} name={[name, "phone"]} placeholder="Enter phone number"
									inputClass={`phone-input ${phoneDetail.error ? 'error' : ''}`} enableSearch onChange={handlePhoneChange} />
					</Form.Item>
				</Col>
			</Row>
			{name == 'billing' && <div className={'d-flex justify-content-end'}><Button type="primary" disabled={!isBtnEnable} onClick={handleAddressAction} loading={isLoading}>{btnName}</Button></div>}
		</>);
};

/**
 * Order Details
 * @param values
 * @returns {Promise<void>}
 */
const OrderDetails = (props) => {
	let cartData = useSelector((state) => state.cartData);
	let currency = useSelector((state) => state.currencyData);
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting
	const symbol = DiamondConstData.multi_currency[currency].symbol

	// Handle image error
	const onImageError = (e, imagePath) => e.currentTarget.src = imagePath


	// View product image
	const productImage = (cart) => {
		let {img_link} = cart
		let fileExt

		if(isEmpty(img_link)) img_link = false
		else {
			let lastIndexFileExt = lastIndexOf(img_link, ".");
			fileExt = img_link?.slice(lastIndexFileExt + 1)
		}

		let imagePath = cart.shape && DIAMONDSHAPE?.filter((shape) => shape.name === cart.shape).map((data) => data?.image)
		const fileExtArr = ["jpg", "jpeg", "JPG", "JPEG", "png", "PNG"]
		const isFileExtension = includes(fileExtArr, fileExt)

		// checkout preview image
		const CheckOutImage = (imagesrc) => <PreviewImage src={imagesrc} className="cart-image" onError={(e) => onImageError(e, imagePath)} />

		const itemString = diamondString(cart)
		return (
			cart.shape !== undefined ?
			<Space>
				<Button className="checkout-button">{!img_link ? (CheckOutImage(imagePath)) : isFileExtension ? CheckOutImage(img_link) : CheckOutImage(imagePath)}</Button>
				<Text className="text-uppercase">{itemString}</Text>
			</Space> : cart
		)
	}

	let subTotal = reduce(cartData, (total, item) => total + (Number(countCommission(item.seller, item.final_price, global_status))), 0);

	// Product price
	const productPrice = (cart) => `${symbol}${(formatPrice(countCommission(cart.seller, cart.final_price, global_status)))}`

	// Final sub-total
	const finalSubTotal = `${symbol}${formatPrice(subTotal)}`

	const productData = [
		...cartData?.map((cart, i) => ({
			key        : i,
			product    : cart,
			certificate: <Text className="text-uppercase">{cart.cert_number}</Text>,
			price      : <Text className="text-uppercase">{productPrice(cart)}</Text>
		}))
	];

	// Product columns
	const productColumns = [
		{
			title    : <Text className="fs-6 text-uppercase fw-bold"> Product </Text>,
			dataIndex: "product",
			key      : "product",
			render   : (cart) => productImage(cart)
		},
		{
			title    : <Text className="fs-6 text-uppercase fw-bold"> Cert.No </Text>,
			dataIndex: "certificate",
			key      : "certificate",
			align    : "center",
		},
		{
			title    : <Text className="fs-6 text-uppercase fw-bold"> SubTotal </Text>,
			dataIndex: "price",
			key      : "price",
			align    : "center",
		},
	];

	const priceDetailCol = [
		{
			dataIndex: "product",
			key      : "product",
			render   : (cart) => productImage(cart)
		},
		{
			dataIndex: "price",
			key      : "price",
			align    : "end",
		},
	]

	const priceDetail = [
		{
			key    : cartData.length + 1,
			product: <Text className="fw-bold">Sub Total</Text>,
			price  : <Text className="fw-bold text-uppercase">{finalSubTotal}</Text>,
		},
		{
			key    : Math.random(),
			product: <Text className="fw-bold">Shipping</Text>,
			price  : <Text className="fw-bold align-center">Free Shipping</Text>,
		},
		// count after shipping fees
		{
			key    : Math.random(),
			product: <Text className="fw-bold">Total</Text>,
			price  : <Text className="fw-bold text-uppercase">{finalSubTotal}</Text>,
		},
	]
	// custom filed validation
	const fieldValidation = (message = 'Values are not matching') => (async(_, checked) => {
		if(!checked) return Promise.reject(new Error(message));
	});

	// field validation rules
	const fieldValidationRules = {
		terms_condition: [
			{
				validator: fieldValidation("you must accept the policy")
			}
		]
	}


	return (
		<>
			<Title className="text-uppercase mt-0 text-decoration-underline" level={3}> Your Order </Title>
			<Table columns={productColumns} dataSource={productData} pagination={false} scroll={{x: 'max-content'}}
				   footer={() => {
					   return <div className='footer-wrapper'>
						   <Table columns={priceDetailCol}
								  dataSource={priceDetail}
								  showHeader={false}
								  bordered={false}
								  pagination={false} />
					   </div>
				   }}

			/>


			<Card className="mt-2">
				<Row justify={"space-between"}>
					<Col>
						<Radio size="large" checked={true}> <Text className="fs-6">Pay on Delivery</Text> </Radio>
					</Col>
					<Col>
						<Text className="fs-6"><span className={'fw-bold'}>Estimate Delivery Date:</span> {dateFormatting(global_status.estimated_delivery_date)}</Text>
					</Col>
				</Row>
				<Divider />
				<div>
					<Text> Your personal data will be used to process your order, support your
						   experience throughout this website, and for other purposes described
						   in our privacy policy </Text>
					<Form.Item name="allowTerm" valuePropName="checked" rules={fieldValidationRules.terms_condition}>
						<Checkbox> <Text className="fw-bold"> I have read and agree to the website terms and conditions <sup className="required-sup">*</sup> </Text> </Checkbox>
					</Form.Item>
				</div>
				<div className="align-items-end d-flex justify-content-end">
					<Button type="primary" shape="round" className="text-uppercase" htmlType="submit" loading={props.loading}> Click Here to Verify Your Order </Button>
				</div>
			</Card>
		</>)
};


const CheckOutPage = () => {
	let cartData = useSelector((state) => state.cartData);
	const userInfo = useSelector((state) => state.auth.accountDetails);
	let currency = useSelector((state) => state.currencyData);
	const [isMessage, setIsMessage] = useState({isMessage: false, message: ""});
	const [isLoading, setIsLoading] = useState(false);
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// value for all update shipping address radio button
	const [updateSellingDetail, setUpdateSellingDetail] = useState(false);

	// Handle checkout order
	const checkoutOrder = async(values) => {

		// processing on cart data
		let orderData = cartData.map((item) => {
			let {img_link} = item;
			const image = isEmpty(img_link) ? `/images/shape_pic/${item.shape?.toLowerCase()}.png` : img_link
			const subTotal = countCommission(item.seller, item.final_price, global_status);
			return Object.assign({}, item, {
				item_name    : diamondString(item),
				sub_total    : subTotal.toFixed(2),
				final_price  : countSubTotal(subTotal, currency),
				image        : image,
				exchange_rate: exchangeRate(currency),
				currency     : DiamondConstData.multi_currency[currency].name,
				display_price: subTotal.toFixed(2),
			});
		});

		// Calculate a final sub-total
		let subTotal = reduce(cartData, (total, item) => total + Number(countCommission(item.seller, item.final_price, global_status)), 0);

		// Return a final checkout object
		let checkoutObj = {
			...values,
			orderItem              : orderData,
			total                  : countSubTotal(subTotal, currency),
			subtotal               : countSubTotal(subTotal, currency),
			is_shipping_address    : updateSellingDetail,
			shipping_type          : "free",
			payment_method         : "cash_on_delivery",
			tc_agreed              : 1,
			sms_subscribe          : 0,
			estimated_delivery_date: global_status.estimated_delivery_date,
		};

		return checkoutObj
	}

	// Submitting order
	const onSubmit = async(values) => {
		setIsLoading(true);
		const checkoutObj = await checkoutOrder(values)
		const res = await dispatch(orderCheckout(checkoutObj));
		if(res.payload.status) {
			const filterData = omit(filterArray, ["diamondPriority"])
			setIsLoading(false);
			navigate(`/${res.payload.data.order_id}/thank-you`, {state: res.payload.data,});
			dispatch(setFilterData(filterData))
			localStorage.removeItem("add_to_cart");
			dispatch(removeAllCartData());
		} else {
			setIsLoading(false);
			setIsMessage({message: res.payload.message, isMessage: true,});
			scrollTo();
		}
	};

	const fetchUserInfo = async() => await dispatch(getAccountInfo({navigate}));

	useEffect(() => {
		document.title = `Checkout -  ${pageName}`;
		fetchUserInfo()
	}, []);

	// Object Of Initial Value
	const formInitialValue = {
		billing            : {
			first_name  : userInfo.data.first_name,
			last_name   : userInfo.data.last_name,
			phone       : "",
			country     : userInfo.data.country ?? "GB",
			company_name: userInfo.data.company,
			state       : "",
			address_1   : "",
			address_2   : "",
			city        : "",
			postcode    : "",
		},
		shipping           : {
			first_name  : userInfo.data.first_name,
			last_name   : userInfo.data.last_name,
			phone       : "",
			country     : userInfo.data.country ?? "GB",
			company_name: userInfo.data.company,
			state       : '',
			address_1   : '',
			address_2   : '',
			city        : '',
			postcode    : '',
		},
		allowTerm          : true,
		sendUpdateMessage  : true,
		note               : '',
		is_shipping_address: false,
		orderDetails       : "",
	};

	useEffect(() => {form.setFieldsValue(formInitialValue);}, [userInfo]);

	return (
		//Start-section
		<section className="checkout-container">
			<Title level={2} className="text-center page-title">Check Out</Title>
			{isMessage.isMessage && (
				<div className="d-flex justify-content-center">
					<p className="error-alert-message" dangerouslySetInnerHTML={{__html: isMessage.message}}></p>
					<Badge count={<CloseCircleFilled className="badgedata close-icon" onClick={() => setIsMessage({isMessage: false, message: ""})} />} dot={true} />
				</div>)}
			{userInfo.loading && (<div className="loading-data"><Spin size="large" /></div>)}

			{!userInfo.loading && (
				<Form
					layout="vertical"
					onFinish={onSubmit}
					encType="multipart/form-data"
					form={form}>
					<Row gutter={[30, 10]}>
						<Col xs={24} md={24} xl={14}>
							<Card>
								<Title className="text-uppercase mt-0 text-decoration-underline" level={3}> Billing details </Title>
								{/*show billing form*/}
								<CheckoutForm name="billing" form={form} />
								<Form.Item name="sendUpdateMessage" valuePropName="checked">
									<Checkbox> Please send me order updates via text message </Checkbox>
								</Form.Item>
								<Title className="text-uppercase text-decoration-underline" level={3}> Shipping details </Title>
								{/*radio button for change shipping address*/}
								<Form.Item name="is_shipping_address" valuePropName="checked">
									<Checkbox onClick={() => setUpdateSellingDetail(!updateSellingDetail)} checked={updateSellingDetail} /> Ship to a different address?
								</Form.Item>
								{updateSellingDetail && (<CheckoutForm name="shipping" updateSellingDetail={updateSellingDetail} form={form} />)}
								<Form.Item label="Order notes" name="note">
									<TextArea rows={3} placeholder="Notes about your order, e.g. special notes for delivery" />
								</Form.Item>
							</Card>
						</Col>
						<Col xs={24} md={20} xl={10}>
							{/*show ordered detail*/}
							<OrderDetails loading={isLoading} />
						</Col>
					</Row>
				</Form>)}
		</section>);
	//End-Section
};

export default CheckOutPage;
