import {createSlice} from "@reduxjs/toolkit";
import {checkCheckout, orderCheckout} from "./checkout-action";

export const CheckoutReducer = createSlice(
	{
		name         : "orderCheckout",
		initialState : {token: "", error: ""},
		reducers     : {},
		extraReducers: (builder) => {
			builder.addCase(orderCheckout.fulfilled, (state, action) => { state.token = action.payload?.token }
			)
			builder.addCase(checkCheckout.fulfilled, (state) => state);
			builder.addCase(orderCheckout.rejected, (state, action) => { console.log("action", action) }
			)
		}
	})

export default CheckoutReducer.reducer;