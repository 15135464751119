import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchOrderHistory,} from "../../redux/order/order-action";
import {LeftOutlined, RightOutlined,} from "@ant-design/icons";
import {DatePicker, Select, Space, Table, Tag, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import dayjs from 'dayjs';
import {commission, countCommission, dateFormatting, diamondString, formatPrice, isEmpty, pageName, scrollTo,} from "../../helper/other/common";
import DiamondConstData from "../../helper/constant/diamond-constant";
import {filterStatus} from "../../helper/constant/filter-constant";
import Search from "antd/es/input/Search";
import {setOrderFilterData} from "../../redux/order/order-slice";
import {ceil, debounce, isEqual} from "lodash";

const {Text, Title} = Typography;
const {RangePicker} = DatePicker

const FilterDataBar = ({fetchOrders}) => {
	const selectRef = useRef(null);
	const dispatch = useDispatch()
	const filterData = useSelector(state => state.orderHistory)

	//when change the date range filter
	const applyDateRangeFilter = async(e) => {
		e = e ?? []
		const startDate = e[0]?.$d
		const endDate = e[1]?.$d
		const dateRange = dayjs(new Date(startDate)).format('DD/MM/YYYY') + " - " + dayjs(new Date(endDate)).format('DD/MM/YYYY')
		const data = {...filterData, displayDateRange: e, dateRange: isEqual(dateRange, "Invalid Date - Invalid Date") ? "" : dateRange}
		dispatch(setOrderFilterData(data))
		fetchOrders(1, data, true)
	}

	//when change status filter
	const applyStatusFilter = async(value) => {
		const data = {...filterData, status: isEqual(value, 'all') ? '' : value}
		dispatch(setOrderFilterData(data))
		fetchOrders(1, data, true)
	}

	//when change status filter
	const applyOrderIdFilter = async(e) => {
		const data = {...filterData, order_id: e.target.value}
		dispatch(setOrderFilterData(data))
		await fetchOrders(1, data, true)
	}

	const debouncedOnChangeOrderId = debounce(applyOrderIdFilter, 2000);

	const handleBlur = (e) => {
		if(selectRef.current && !e) selectRef.current.blur()
	}
	return <div className='order-filter-bar'>
		<RangePicker allowClear value={filterData.displayDateRange} onChange={applyDateRangeFilter} className='filter-bar-item' />
		<Select ref={selectRef} value={filterData.status || filterStatus[0].value} className='select-status filter-bar-item' onChange={applyStatusFilter} options={filterStatus} onDropdownVisibleChange={handleBlur} />
		<Search placeholder="search order id" rootClassName='search-order filter-bar-item' allowClear defaultValue={filterData?.order_id} onChange={debouncedOnChangeOrderId} />
	</div>
}

const OrderPage = () => {
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [totalData, setTotalData] = useState(0);
	const filterData = useSelector(state => state.orderHistory)
	const navigate = useNavigate();
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting
	const [orderData, setOrderData] = useState({data: [], loading: true})

	//Fetch Order Data
	const fetchOrders = async(page, data, isFilter) => {
		if(!isEqual(page, 0)) {
			setOrderData({...orderData, loading: true})
			const response = await fetchOrderHistory(page, data);
			let totalPages;
			if(response.data) {
				setOrderData({data: response.data, loading: false})
				setTotalData(response.total)
				totalPages = ceil(response.total / response.per_page);
				if(isEqual(response.data.length, 0)) setPage(0)
				else if(isFilter) setPage(page)
			} else {
				setTotalData(0)
				setOrderData({data: [], loading: false})
				totalPages = 0;
				setPage(0)
			}
			setTotalPage(totalPages)
		}
	};

	useMemo(() => {
		scrollTo()
		fetchOrders(page, filterData)
	}, [page]);

	useEffect(() => {
		document.title = `Orders -  ${pageName}`;
	}, []);

	//when click on View More Data
	const viewMoreData = (data) => navigate(`/order/${data.id}`)

	// show diamond description in data column
	const DiamondDescription = (data) => {
		let diamond = data.diamond
		const copyCertNumber = {text: diamond?.cert_number, tooltips: [`Copy: ${diamond?.cert_number}`, "Copied"]};
		if(diamond) {
			return (
				<>
					{diamondString(diamond)} |<br />
					{diamond?.lab}:
					<Text copyable={copyCertNumber} strong className='ps-1'>{diamond?.cert_number}</Text><br />
					{data?.note && (<>Note: <Text strong className='ps-1'>{data?.note}</Text><br /></>)}
											 Measurement: <Text strong className='ps-1'>{diamond?.measurements}</Text>
				</>
			);
		} else {
			return '-';
		}
	};
	// show diamond total price in data column
	const DiamondTotalPrice = (diamond) => {
		const deliveryPrice = countCommission(diamond?.diamond?.seller, diamond?.diamond?.final_price, global_status)
		let diamondCommission = diamond.diamond != null ? commission(diamond?.diamond?.discount, diamond?.diamond?.seller, global_status, diamond?.diamond, deliveryPrice) : 0
		return <>
			{!isEqual(diamond?.diamond?.color, "Fancy") && <><Text className='commission'>{isEqual(diamondCommission, "NaN") ? 0 : diamondCommission}%</Text><br /></>}
			{DiamondConstData.multi_currency[diamond?.currency]?.symbol}{formatPrice((diamond?.display_price))}
		</>
	}

	const DiamondDate = (date) => {
		const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
		const d = date ? new Date(date) : new Date();
		return d?.getUTCDate() + " " + month[d?.getUTCMonth()] + "," + d?.getUTCFullYear()
	}

	const dataColumns = [
		{
			title    : "Date",
			dataIndex: "created_at",
			key      : "created_at",
			sorter   : (a, b) => new Date(a.created_at) - new Date(b.created_at),
			render   : (date) => DiamondDate(date)
		},
		{
			title    : "Order ID",
			dataIndex: "display_id",
			key      : "display_id",
			sorter   : (a, b) => a.id - b.id,
		},
		{
			title    : "Supplier",
			dataIndex: "diamond",
			key      : "diamond",
			render   : (diamond) => diamond?.seller?.username ? diamond?.seller?.username?.toUpperCase() : '-'
		},
		{
			title    : "Description",
			dataIndex: "",
			key      : "",
			render   : (diamond) => DiamondDescription(diamond)
		},
		{
			title    : "Total Price",
			dataIndex: "",
			key      : "",
			sorter   : (a, b) => a.id - b.id,
			render   : (diamond) => DiamondTotalPrice(diamond)
		},
		{
			title    : "Returnable",
			dataIndex: "diamond",
			key      : "diamond",
			render   : (diamond) => isEqual(diamond?.seller?.return_option, 1) ? "Returnable" : "Not Returnable"
		},
		{
			title    : "Ship To",
			dataIndex: "order",
			key      : "order",
			render   : (order) => {
				const shippingDetails = isEqual(order?.is_shipping_address, 1) && order?.shipping_details ? JSON.parse(order.shipping_details) : order?.billing_details;
				return <div className='text-capitalize'> {shippingDetails?.city},{shippingDetails?.country}</div>
			}
		},
		{
			title    : "Est. Delivery Date",
			dataIndex: "order",
			key      : "order",
			render   : (order) => dateFormatting(order?.estimated_delivery_date) || "-"
		},
		{
			title    : "Payment-Method",
			key      : "order",
			dataIndex: "order",
			render   : (order) => global_status?.status?.payment_method[order?.payment_method]
		},
		{
			title    : "Status",
			key      : "status",
			dataIndex: "status",
			render   : (status) => {
				const color = global_status?.status?.order_status_color[status];
				return <Tag color={color} className='status-tag'>{global_status?.status?.order_status[status]}</Tag>
			}
		},
		{
			key   : "action",
			render: (record) => <button className='ant-btn hoverableButton' onClick={() => viewMoreData(record)}>View More</button>
		},
	];

	// NextPage
	const nextPage = () => {
		if(!isEqual(page, totalPage) && !isEqual(totalPage, 0)) setPage(page + 1);
	}

	// Previous Page
	const previousPage = () => {
		if(page > 1 && !isEqual(totalPage, 0)) setPage(page - 1);
	};

	let isDisablePreviousBtn = isEqual(page, totalPage) || isEqual(totalPage, 0)
	let currentPage = isEqual(totalPage, 0) ? 0 : page
	let previousBtnClassName = `d-block pagination-icon ${isDisablePreviousBtn ? 'icon-disable' : ''}`
	let nextBtnClassName = `d-block pagination-icon ${isEqual(page, 1) || isEqual(page, 0) ? 'icon-disable' : ''}`

	return (
		<div className='order-list-container'>
			<Title className='text-center page-title'>Order Diamonds</Title>
			<FilterDataBar fetchOrders={fetchOrders} setPage={setPage} />
			<Table columns={dataColumns} pagination={false} dataSource={orderData?.data} scroll={{x: 'max-content'}} loading={orderData.loading} />
			<div className='pagination'>
				<Space align="center" size={[3]}>
					<RightOutlined className={previousBtnClassName} onClick={nextPage} />
					{`Page: ${currentPage}/${totalPage}`}
					{`Total: ${totalData}`}
					<LeftOutlined className={nextBtnClassName} onClick={previousPage} />
				</Space>
			</div>
		</div>
	);
};
export default OrderPage;
