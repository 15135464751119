import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./ThankYou.css";
import {CheckCircleOutlined} from "@ant-design/icons";
import {pageName} from "../../helper/other/common";
import {useSelector} from "react-redux";
import {Button} from "antd";

const ThankYouPage = () => {
	const navigate = useNavigate();

	const [data, setData] = useState();
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = globalSetting

	const addHrefToLink = (html) => {
		const modifyHtml = html?.replace(/<a>/g, "<a href='/order'>")
		return {__html: modifyHtml}
	}

	useEffect(() => {
		document.title = `Thank You -  ${pageName}`;
		setData(global_status.status?.thankyou_page)
	}, [])

	return (
		<>
			<section className="content">
				<div className="wrapper-1">
					<div className="wrapper-2">
						<CheckCircleOutlined className="image" />
						<h1>{data?.main_line}</h1>
						<p dangerouslySetInnerHTML={{__html: data?.sub_line}} />
						<Button className="go-home" onClick={() => navigate("/home")}>{data?.button_text}</Button>
					</div>
					<div dangerouslySetInnerHTML={addHrefToLink(data?.footer_text)} />
				</div>
			</section>
			<link href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro" rel="stylesheet" />
		</>);
};

export default ThankYouPage;
