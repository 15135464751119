import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Space, Typography} from "antd";
import {CARAT_SIZE_DATA} from "../../../../helper/constant/filter-constant";
import {indexOf, isEmpty, isEqual, isNil, size} from "lodash";

const {Title} = Typography;
const FilterSizeData = ({filterData, setFilterData}) => {

	const sizeData = {min: filterData.weight.min, max: !isNil(filterData.weight.min) && isNil(filterData.weight.max) ? '4.99' : filterData.weight.max}
	const [caratData, setCaratData] = useState({high: null, low: null});

	useEffect(() => {
				  let maxSizeData = !isNil(sizeData.min) && isNil(sizeData.max) ? '4.99' : sizeData.max
				  let setLowSize = CARAT_SIZE_DATA.filter((data) => Number(data.lowsize) >= sizeData.min)
				  let setHighSize = CARAT_SIZE_DATA.filter((data) => Number(data.lowsize) <= maxSizeData && Number(data.highsize) >= maxSizeData)
				  if(sizeData.min >= 4) setCaratData({low: 14, high: 14})
				  else if(isEmpty(sizeData.max) && isEmpty(sizeData.min)) setCaratData({low: null, high: null})
				  else if(sizeData.min <= 0.2 && isEmpty(maxSizeData)) setCaratData({low: 0, high: 14})
				  else if(sizeData.min <= 0.2 && maxSizeData < 0.2) setCaratData({low: 0, high: 0})
				  else if(sizeData.min < 0.3 && maxSizeData > 0.2) {
					  const highIndex = isEqual(size(setHighSize), 0) ? 14 : indexOf(CARAT_SIZE_DATA, setHighSize[0]);
					  setCaratData({low: 0, high: highIndex});
				  } else {
					  const lowIndex = isEqual(size(setLowSize), 0) ? null : indexOf(CARAT_SIZE_DATA, setLowSize[0]);
					  const highIndex = isEqual(size(setHighSize), 0) ? 14 : indexOf(CARAT_SIZE_DATA, setHighSize[0]);
					  setCaratData({low: lowIndex, high: highIndex});
				  }
			  }, [sizeData]
	)

	// Handle carat-card
	const OnClickCaratCard = (i) => {
		const highestValue = isEqual(i, 14) ? null : CARAT_SIZE_DATA[i].highsize
		const lowestValue =  CARAT_SIZE_DATA[i].lowsize
		if(isEqual(caratData.low, 14) && isEqual(caratData.high, 14) && isEqual(i, 14) && !isEqual(sizeData.min, '4.00')) {
			setCaratData({low: 14, high: 14});
			setFilterData({...filterData, weight: {min: CARAT_SIZE_DATA[14].lowsize, max: null}})
		} else if(isEqual(i, caratData.low) && isEqual(i, caratData.high)) {
			setCaratData({low: null, high: null});
			setFilterData({...filterData, weight: {min: "", max: ""}})
		} else if(isNil(caratData.low) && isNil(caratData.high)) {
			setCaratData({low: i, high: i});
			setFilterData({...filterData, weight: {min: lowestValue, max: highestValue}})
		} else if(!isEqual(caratData.low, caratData.high)) {
			setCaratData({low: i, high: i});
			setFilterData({...filterData, weight: {min: lowestValue, max: highestValue}})
		} else if(i >= caratData.high) {
			setCaratData({...caratData, high: i});
			setFilterData({...filterData, weight: {...filterData.weight, max: highestValue}})
		} else {
			setCaratData({...caratData, low: i});
			setFilterData({...filterData, weight: {...filterData.weight, min: lowestValue}})
		}
	};

	// Handle Low-change value
	const OnLowValueChange = (v) => {
		setFilterData({...filterData, weight: {...filterData.weight, min: v}})
		let filterSizeData = CARAT_SIZE_DATA.filter((data) => Number(data.lowsize) <= v && Number(data.highsize >= v))
		if(v >= 4) setCaratData({low: 14, high: 14})
		else if(v < 0.2 && !isEmpty(v)) setCaratData({...caratData, low: 0})
		else if(isEmpty(v) && !isNil(caratData.high) && !isEmpty(sizeData.max) && !isEqual(sizeData.max, "4.99")) setCaratData({...caratData, low: 0})
		else if(isEmpty(v) && !isNil(caratData.high) && (isEmpty(sizeData.max) || isEqual(sizeData.max, "4.99"))) setCaratData({...caratData, low: null})
		else if(isEmpty(v) && isNil(caratData.high)) setCaratData({...caratData, low: null})
		else if(!isEqual(size(filterSizeData), 0)) {
			const index = indexOf(CARAT_SIZE_DATA, filterSizeData[0]);
			setCaratData({low: index, high: isNil(caratData.high) || isEmpty(caratData.high) ? 14 : caratData.high});
		}
	};

	// Handle High-change value
	const OnHighValueChange = (v) => {
		setFilterData({...filterData, weight: {...filterData.weight, max: v}})
		let filterSizeData = CARAT_SIZE_DATA.filter((data) => Number(data.highsize) >= v)
		if(v < 0.2 && !isEmpty(v)) setCaratData({...caratData, high: 0})
		else if(v >= 4 || isEmpty(v) && !isNil(caratData.low) && !isEmpty(sizeData.min)) setCaratData({...caratData, high: 14})
		else if(v >= 4 || isEmpty(v) && !isNil(caratData.low) && isEmpty(sizeData.min)) setCaratData({...caratData, high: null})
		else if(isEmpty(v) && isNil(caratData.low)) setCaratData({low: null, high: null})
		else if(!isEqual(size(filterSizeData), 0)) {
			const index = indexOf(CARAT_SIZE_DATA, filterSizeData[0]);
			setCaratData({low: isNil(caratData.low) ? 0 : caratData.low, high: index});
		}
	};

	// High Value
	const highValue = isEqual(sizeData?.max, "4.99") ? null : sizeData?.max

	return <>
		<Title level={5} className='filter-title'> Carat </Title>
		<Space direction="vertical">
			<Space>
				<Input placeholder="From" value={sizeData?.min} onChange={(e) => OnLowValueChange(e.target.value)} />
				<Input placeholder="To" value={highValue} onChange={(e) => OnHighValueChange(e.target.value)} />
			</Space>
			<Row gutter={[8, 8]}>
				{CARAT_SIZE_DATA?.map((carat, i) => {
					const type = caratData.low <= i && caratData.high >= i && !isNil(caratData.low) && !isNil(caratData.high) ? "primary" : "default";
					return <Col onClick={() => OnClickCaratCard(i)} key={carat.size}> <Button type={type}>{carat.size}</Button> </Col>;
				})}
			</Row>
		</Space>
	</>
}

export default FilterSizeData;
