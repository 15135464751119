import {Button, Col, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react'
import {CLARITY} from "../../../../helper/constant/filter-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography
const FilterClarityData = ({filterData, setFilterData}) => {
	const clarityData = filterData?.clarity
	const [selectItem, setSelectItem] = useState({low: null, high: null})

	// set data in filter data
	useEffect(() => {
		const clarityArr = slice(CLARITY, selectItem.low, selectItem.high + 1)
		if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, clarity: []})
		else setFilterData({...filterData, clarity: clarityArr})
	}, [selectItem])

	useEffect(() => {
		const length = size(clarityData)
		if(length > 0) {
			if(isEqual(length, 1)) {
				let index = indexOf(CLARITY, clarityData[0])
				setSelectItem({low: index, high: index})
			} else {
				let firstIndex = indexOf(CLARITY, clarityData[0])
				let lastIndex = indexOf(CLARITY, clarityData[length - 1])
				setSelectItem({low: firstIndex, high: lastIndex})
			}
		}
	}, [])

	// Handle Selected Value
	const handleCardClick = (i) => {
		const {low, high} = selectItem;
		if(isEqual(i, low) && isEqual(i, high)) setSelectItem({low: null, high: null});
		else if(isNil(low) && isNil(high)) setSelectItem({low: i, high: i});
		else if(!isEqual(low, high) || isNil(low) || isNil(high)) setSelectItem({low: i, high: i});
		else if(i >= high) setSelectItem({...selectItem, high: i})
		else setSelectItem({...selectItem, low: i});
	}

	return (
		<>
			<Title level={5} className='filter-title'>Clarity</Title>
			<Row gutter={[8, 8]}>
				{CLARITY.map((clarity, i) => {
					const buttonType = includes(clarityData, clarity) ? "primary" : "default"
					return <Col key={clarity}><Button type={buttonType} onClick={() => handleCardClick(i)}>{clarity}</Button></Col>
				})}
			</Row>
		</>
	)
}

export default FilterClarityData