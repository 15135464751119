export const AntdConfigStyle = {
	token     : {
		colorPrimary        : '#313c6a',
		colorPrimaryHover   : "#253575",
		colorLink           : "#686868",
		colorBgLayout       : "#313c6a",
		fontSize            : 13,
		paddingLG           : 18,
		colorTextDescription: '#686868',
		colorSplit          : "rgba(5, 5, 5, 0.15)",
	},
	components: {
		Typography: {
			titleMarginTop  : "15px",
			fontWeightStrong: 700
		},
		Button    : {
			defaultShadow: 'none !important',
			primaryShadow: 'none !important',
			dangerShadow : 'none !important'
		},

		Segmented   : {
			itemColor: "#ffffff",
		},
		Input       : {
			activeShadow: "none"
		},
		Descriptions: {
			titleMarginBottom: "5px",
			itemPaddingBottom: "1px"
		},
		Tabs		: {
			titleFontSize: "16px !important",
		},
	}
}