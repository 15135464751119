import {createSlice} from "@reduxjs/toolkit";
import {forgotPassword, getAccountInfo, resetPassword, submitKyc, updatePassword, userLogin, userRegistration} from "./auth-action";
import {userInitialValue} from "../../helper/constant/filter-constant";

const token = localStorage.getItem("token");
export const AuthReducer = createSlice(
	{
		name         : "auth",
		initialState : {
			token                 : token || "",
			registrationError     : "",
			loginError            : "",
			registrationLoading   : false,
			loginLoading          : false,
			forgotPassword        : {message: "", loading: false, error: ""},
			resetPassword         : {message: "", loading: false, error: ""},
			updatePassword        : {message: "", loading: false, error: ""},
			resetAndForgotPassword: {message: "", show: false},
			accountDetails        : {data: userInitialValue, error: "", loading: false},
			isAuthPath            : false

		},
		reducers     : {
			setResetPasswordMessage         : (state, action) => {
				void (state.resetPassword.message = "")
			},
			setUserInfo                     : (state, action) => {
				void (state.accountDetails.data = action.payload)
			},
			removeToken                     : (state, action) => {
				void (state.token = "")
			},
			setIsPath                       : (state, action) => {
				void (state.isAuthPath = action.payload)
			},
			setForgotPasswordMessage        : (state, action) => {
				void (state.resetAndForgotPassword.message = action.payload.message, state.resetAndForgotPassword.show = action.payload.show)
			},
			setKycSkip                      : (state, action) => {
				void (state.accountDetails.data = {...state.accountDetails.data, kyc_status: "skipped"})
			}
		},
		extraReducers: (builder) => {
			builder.addCase(userRegistration.fulfilled, (state, action) => {
								return action.payload?.token ? void (state.token = action.payload.token, state.registrationError = "", state.registrationLoading = false) :
									   void (state.token = "", state.registrationError = action.payload, state.registrationLoading = false)
							}
			)
			builder.addCase(userRegistration.pending, (state, action) => {
								void (state.registrationLoading = true)
							}
			)
			builder.addCase(userLogin.fulfilled, (state, action) => {
								return action.payload?.token ? void (state.token = action.payload.token, state.loginError = "", state.loginLoading = false) :
									   void (state.token = "", state.loginError = action.payload, state.loginLoading = false)
							}
			)
			builder.addCase(userLogin.pending, (state, action) => {
								void (state.loginLoading = true)
							}
			)
			builder.addCase(forgotPassword.fulfilled, (state, action) => {
								return action.payload?.data?.message ? void (state.forgotPassword = {message: action.payload?.data?.message, loading: false, error: ""}) :
									   void (state.forgotPassword = {message: "", loading: false, error: action.payload})
							}
			)
			builder.addCase(forgotPassword.pending, (state, action) => {
								void (state.forgotPassword = {...state.forgotPassword, loading: true})
							}
			)
			builder.addCase(resetPassword.fulfilled, (state, action) => {
								return action.payload?.data?.message ? void (state.resetPassword = {message: action.payload?.data?.message, error: ""}) :
									   void (state.resetPassword = {message: "", error: action.payload})
							}
			)
			builder.addCase(resetPassword.pending, (state, action) => {
								void (state.resetPassword = {...state.resetPassword, loading: true})
							}
			)
			builder.addCase(updatePassword.fulfilled, (state, action) => {
								return action.payload?.message ? void (state.updatePassword = {message: action.payload.message, error: ""}) :
									   void (state.updatePassword = {message: "", error: action.payload})
							}
			)
			builder.addCase(getAccountInfo.fulfilled, (state, action) => {
								return void (state.accountDetails = {data: action.payload, loading: false})
							}
			)
			builder.addCase(getAccountInfo.pending, (state, action) => {
								return void (state.accountDetails.loading = true)
							}
			)
			builder.addCase(submitKyc.fulfilled, (state, action) => {
								return void (state.accountDetails = {data: {...state.accountDetails.data, ...action.payload}, loading: false})
							}
			)
		}
	})

export const {
	removeToken,
	setIsPath,
	setForgotPasswordMessage,
	setKycSkip
} = AuthReducer.actions;
export default AuthReducer.reducer;

