import React, {useEffect} from 'react'
import {Result} from "antd";
import {pageName} from '../../helper/other/common';

const ServerErrorPage = () => {
	useEffect(() => {
		document.title = `Error Page -  ${pageName}`;
	}, []);
	return (
		<>
			{
				<Result status="500" title="500" subTitle="Internal Server Error." />
			}
		</>

	)
}

export default ServerErrorPage