import React, {useEffect, useState} from "react";
import {Button, Col, Image, Row, Typography} from "antd";
import {DIAMONDSHAPE} from "../../../../helper/constant/filter-constant";
import {includes, isNil, slice} from "lodash";

const {Text, Title} = Typography;
const FilterShapeData = ({filterData, setFilterData}) => {
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		if(filterData.shape.length === 0) {
			setShowMore(false)
			localStorage.setItem('showMore', false)
		}
		isNil(localStorage.getItem('showMore')) ? setShowMore(false) : localStorage.getItem('showMore') === 'true' ? setShowMore(true) : setShowMore(false)
	}, []);

	// Handle card-shape-click
	const handleCardShapeClick = (shape) => {
		if(includes(filterData.shape, shape)) {
			const filterShapeData = filterData.shape.filter(name => name !== shape);
			setFilterData({...filterData, shape: filterShapeData})
		} else setFilterData({...filterData, shape: [...filterData.shape, shape]})
	}

	// Shape data
	const shapeData = showMore ? DIAMONDSHAPE : slice(DIAMONDSHAPE, 0, 14);

	// Button title
	const buttonTitle = showMore ? "Show Less" : "Show More";

	// Handle show more and less
	const handleShowMoreAndLess = () => {
		setShowMore((prev) => !prev)
		localStorage.setItem('showMore', !showMore)
	}

	return (
		<>
			<Title level={5} className='filter-title'> Shape </Title>
			<Row gutter={[8, 5]} wrap>
				{shapeData.map((shape) => {
					const buttonType = includes(filterData.shape, shape.name) ? "primary" : "default"
					return <Col key={shape.name} className='text-center'>
						<Button type={buttonType} size="large" onClick={() => handleCardShapeClick(shape.name)} icon={<Image alt={`Image for ${shape.name}`} src={shape.image} preview={false} height={43} />} className='shape-btn' />
						<div className='shape-text-div'><Text>{shape.name}</Text></div>
					</Col>
				})}
			</Row>
			<Row className="d-flex justify-content-end">
				<Button type={"primary"} className="hover-button" size="middle" onClick={handleShowMoreAndLess} name="Show More">{buttonTitle}</Button>
			</Row>
		</>
	);
};

export default FilterShapeData;