import {createAsyncThunk} from "@reduxjs/toolkit";
import {Api} from "../../helper/api/api";
import axios from "axios";
import {URL} from "../../helper/other/url-helper";
import {errorHandler, removeData} from "../../helper/other/common";
import store from "../index";
import {removeToken} from "./auth-slice";
import {includes} from "lodash";

// User Registration
export const userRegistration = createAsyncThunk(
	"registration/userRegistration",
	async({data, navigate}) => {
		return await axios
			.post(`${URL}/register`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				localStorage.setItem("token", res?.data?.token);
				localStorage.setItem("email_verified", "false");
				localStorage.setItem("Login Details", data.email);

				navigate(`/email/verify`);
				return res?.data;
			})
			.catch((error) => {
				return error.response.data.errors.email[0];
			});
	}
);

export const userLogin = createAsyncThunk(
	"login/userLogin",
	async({data, navigate}) => {
		return await axios
			.post(`${URL}/login`, data)
			.then((res) => {
				localStorage.setItem("email_verified", res.data.email_verified !== null);
				localStorage.setItem("kycStatus", res.data.kyc_status);
				localStorage.setItem("token", res?.data?.token);
				localStorage.setItem("Login Details", data.email);
				navigate(`/natural`);
				return res?.data;
			})
			.catch((error) => {
				return error.response;
			});
	}
);

export const forgotPassword = createAsyncThunk(
	"password/forgotPassword",
	async({data}) => {
		return await axios
			.post(`${URL}/forgot_password`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return error.response.data.message;
			});
	}
);

export const resetPassword = createAsyncThunk(
	"password/resetPassword",
	async({data, navigate, dispatch}) => {
		return await axios
			.post(`${URL}/reset_password`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return error.response.data.message;
			});
	}
);

export const verifyEmail = async(data) => {
	return await Api.get(`/email/verify/${data}`)
					.then((res) => {
						res.status === 200 && localStorage.setItem("email_verified", "true");
						return res;
					})
					.catch((e) => {
							   return e
						   }
					);
};

export const resendVerifyEmail = async(id) => {
	return await Api.get(`/email/resend/${id}`)
					.then((res) => {
						return res;
					})
					.catch((e) => {
						return e;
					});
};

export const getAccountInfo = createAsyncThunk(
	"account/getAccountInfo",
	async({navigate, isKycSkip}) => {
		return await Api.get(`${URL}/profile`)
						.then((res) => {
							localStorage.setItem("kycStatus", res.data?.kyc_status);
							localStorage.setItem("userName", res.data?.first_name);
							localStorage.setItem("userStatus", res.data?.status);

							if(res?.status === 200) {
								localStorage.setItem("email_verified", "true")
								if(res?.data?.status === "blocked") {
									removeData()
									store.dispatch(removeToken())
									navigate('/login')
								}
							}

							const checkKycStatus = includes(["rejected", "pending"], res?.data?.kyc_status);
							if(checkKycStatus && isKycSkip) {
								return {...res?.data, kyc_status: "skipped"};
							}

							return res?.data;
						})
						.catch((error) => {
							//return error;
						});
	}
);

export const profileUpdate = createAsyncThunk(
	"account/updateProfile",
	async(data) => {
		return await Api.post(`${URL}/profile`, data)
						.then((res) => {
							return res;
						})
						.catch((error) => {
							// return error.response.data.message;
						});
	}
);

export const updatePassword = createAsyncThunk(
	"account/updatePassword",
	async(data) => {
		return await Api
			.post('/profile/update_password', data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				if(res?.status === 200) return {message: res?.data.message, status: res.status}
				else if(res?.response.status === 422) return {message: errorHandler(res), status: res.response.status};
				else return {message: res?.response.data.message, status: res.response.status};
			})
			.catch((error) => {
				return error.response.data.message;
			});
	}
);

export const submitKyc = createAsyncThunk(
	"account/submitKyc",
	async(data) => {
		return await Api
			.post('/profile/submit_kyc', data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				return {message: res?.data.message, status: res.status};
			})
			.catch((error) => {
				return error.response.data.message;
			});
	}
);

// Calling order - Api with async thunk

