import React from 'react'
import {Button, Col, Row, Typography} from 'antd'
import {AVAILABILITYDATA} from "../../../../helper/constant/filter-constant";
import {isEqual} from "lodash";

const {Title} = Typography

const FilterAvailabilityData = ({filterData, setFilterData}) => {

	// Handle Availability-card click
	const handleAvailabilityCardClick = (availability) => {
		if(isEqual(filterData?.availability , availability)) setFilterData({...filterData, availability: ""})
		else setFilterData({...filterData, availability: availability })
	}

	return <>
		<Title level={5} className='filter-title'> Availability </Title>
		<Row gutter={[8, 8]}>
			{AVAILABILITYDATA.map((availability) => {
				const buttonType = isEqual(filterData?.availability , availability) ? "primary" : "default"
				return <Col key={availability}> <Button type={buttonType} onClick={() => handleAvailabilityCardClick(availability)} className='text-uppercase'>{availability}</Button> </Col>
			})}
		</Row>
	</>
}
export default FilterAvailabilityData