import {Button, Col, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react'
import {FANCY_COLOR_INTENSITY} from "../../../../helper/constant/filter-constant";
import DiamondConstData from "../../../../helper/constant/diamond-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography
const FilterFancyColorIntensity = ({filterData, setFilterData}) => {
	const [selectItem, setSelectItem] = useState({low: null, high: null,});
	const data = filterData.fancy_color_intensity

	useEffect(() => {
		const fancyColorArr = slice(FANCY_COLOR_INTENSITY, selectItem.low, selectItem.high + 1)
		if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, fancy_color_intensity: []})
		else setFilterData({...filterData, fancy_color_intensity: fancyColorArr})
	}, [selectItem]);

	useEffect(() => {
		const length = size(data)
		if(isEqual(length, 1)) {
			let index = indexOf(FANCY_COLOR_INTENSITY, data[0])
			setSelectItem({low: index, high: index})
		} else if(length > 0) {
			let firstIndex = indexOf(FANCY_COLOR_INTENSITY, data[0])
			let lastIndex = indexOf(FANCY_COLOR_INTENSITY, data[length - 1])
			setSelectItem({low: firstIndex, high: lastIndex})
		}
	}, [])

	// Handle onCard click
	const handleCardClick = (i) => {
		const {low, high} = selectItem;
		if(isEqual(i, low) && isEqual(i, high)) setSelectItem({low: null, high: null});
		else if(isNil(low) && isNil(high)) setSelectItem({low: i, high: i});
		else if(!isEqual(low, high) || isNil(low) || isNil(high)) setSelectItem({low: i, high: i});
		else if(i >= high) setSelectItem({...selectItem, high: i});
		else setSelectItem({...selectItem, low: i});
	}

	return (
		<>
			<Title level={5} className='filter-title'>fancy color Intensity</Title>
			<Row gutter={[8, 8]}>
				{FANCY_COLOR_INTENSITY.map((intensity, i) => {
					const buttonType = includes(data, intensity) ? "primary" : "default"
					return <Col key={intensity}> <Button type={buttonType} onClick={() => handleCardClick(i)}>{DiamondConstData.fancy_color_intensity[`${intensity}`]}</Button> </Col>
				})}
			</Row>
		</>
	)
}

export default FilterFancyColorIntensity