import {createSlice} from "@reduxjs/toolkit";

const currency = localStorage.getItem("currency")
export const CurrencyDataReducer = createSlice(
	{
		name        : "currencyData",
		initialState: currency ? currency : "USD",
		reducers    : {setCurrencyData: (state, action) => action.payload}
	})

export const {setCurrencyData} = CurrencyDataReducer.actions;
export default CurrencyDataReducer.reducer;

