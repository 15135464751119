import React, {useEffect} from "react";
import lazyLoader from "../../components/utils/loader/LazyLoading";
import FilterDataBar from "./filter/FilterBar";
import {pageName} from "../../helper/other/common";

const DiamondListing = lazyLoader(() => import('./DiamondListing'))

const DiamondsPage = ({type}) => {
	const currency = localStorage.getItem("currency");
	useEffect(() => {
		!currency && localStorage.setItem("currency", "USD");
		!type && (document.title = pageName);
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => window.removeEventListener("scroll", isSticky);
	});

	/* Method that will fix header after a specific scrollable */
	const isSticky = () => {
		const header = document?.querySelector(".filter_btn");
		const btnHeader = document?.querySelector(".cartbtn");
		const scrollTop = window.scrollY;
		scrollTop >= 200 ? header?.classList?.add("is-sticky") : header?.classList?.remove("is-sticky");
		scrollTop >= 200 ? btnHeader?.classList?.remove("d-none") : btnHeader?.classList?.add("d-none");
	};

	return (
		<div className='diamond-container'>
			<div className="filter_btn"><FilterDataBar /></div>
			<div className="diamondpage"><DiamondListing /></div>
		</div>
	);
};

export default DiamondsPage;
