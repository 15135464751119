import React, {useEffect, useState} from "react";
import {Card, Col, Modal, notification, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {diamondString, isURL} from "../../../helper/other/common";
import {fetchSingleDiamond} from "../../../redux/diamond/diamond-action";
import LoadingPage from "../loader/Loading";
import {MediaContent, ProductAction} from "../../../layouts/product/grid/Product";
import {includes, isEmpty, isNil, keys, pick} from "lodash";
import ProductDetailCard from "../../../pages/diamond/product-card/ProductDetailCard";


const {Text, Title} = Typography;

const ProductInfoModel = ({setAction, action, id, handleImageRequest, isWishlist}) => {
	const [isReadMore, setIsReadMore] = useState(true);
	//product data
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting
	const dispatch = useDispatch();

	//use antd notification
	const [api, notificationHolder] = notification.useNotification();

	//extra info data
	let otherData = !isNil(data?.product_data) ? JSON.parse(data?.product_data?.extra_info) : {};

	//show remaining data which is not added in card or extra_info
	const remainingData = ["girdle_condition", "girdle_thin", "girdle_thick", "girdle_percentage", "fancy_color_intensity", "fancy_color", "fancy_color_overtone"];

	const remainingDataObj = pick(data, remainingData);

	//fetch product detail
	const fetchProductDetail = async() => {
		setIsLoading(true);
		const diamondDetail = await dispatch(fetchSingleDiamond(id));
		setData(diamondDetail?.payload?.data);
		if(!isEmpty(diamondDetail.payload.data)) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		action.productDetailModalOpen && fetchProductDetail();
	}, [action.productDetailModalOpen]);

	// set readMore option
	const toggleReadMore = () => setIsReadMore(!isReadMore);

	// read more button in extra info
	const ReadMore = (text) => {
		return <Text strong>
			{isReadMore ? text.slice(0, 15) : text}
			<span onClick={toggleReadMore} className="read-or-hide">
					{isReadMore ? "...read more" : " show less"}
			</span>
		</Text>
	};

	// Get the keys and sort them case-insensitively
	const sortedKeys = keys({...otherData, ...remainingDataObj}).sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}));

	// Create a new object with sorted keys
	const sortedObject = {};
	sortedKeys.forEach(key => {
		if(includes(remainingData, key)) sortedObject[key] = data[key]
		else sortedObject[key] = otherData[key]
	});

	// text transform for display
	const displayText = (text) => text.replace(/_/gi, " ")

	//show extra info data which is not link
	const ShowNonLinkedExtraInfo = (key, value) => {
		return <Col xs={12} sm={6} className='text-capitalize mt-2'>
			<Text type="secondary">{displayText(key)}: </Text>
			{value?.length > 25 && typeof value == "string" ? ReadMore(value) : <Text strong>{value}</Text>}
		</Col>
	}

	//show extra info which is linked
	const ShowLinkedExtraInfo = (key, value) => {
		return <Col xs={12} sm={6} className='text-capitalize mt-2'>
			<Text type="secondary">{displayText(key)}: </Text>
			<Link to={value} target="_blank" className='text-decoration-none'>
				<Text strong>link</Text>
			</Link>
		</Col>
	}

	const ShowExtraData = () => {
		return Object.entries(sortedObject).map(
			([key, value]) => {
				if(value && !isURL(value)) {
					return ShowNonLinkedExtraInfo(key, value)
				} else if(value && isURL(value)) {
					return ShowLinkedExtraInfo(key, value)
				}
			})
	}

	// when close the modal
	const onCloseModal = () => {
		setAction({...action, productDetailModalOpen: false})
	}

	// Media content
	const mediaContent = <MediaContent data={data} api={api} status={global_status} action={action} setAction={setAction} handleImageRequest={handleImageRequest} isWishlist={isWishlist}/>

	return (
		<>
			{notificationHolder}
			<Modal title={!isLoading && <Title className='text-uppercase mt-3 mb-3' level={4}>{diamondString(data)}</Title>}
				   open={action?.productDetailModalOpen}
				   onCancel={onCloseModal}
				   width={800}
				   footer={null}>
				<>
					{isLoading ? <div className="product-info-modal"><LoadingPage /></div> :
					 <>
						 <Row justify="center">
							 <Col md={12}> <Card className="product_card p-0 me-1" cover={mediaContent} bordered={false} /> </Col>
							 <Col md={12}>
								 <ProductDetailCard data={data} status={global_status} />
								 <ProductAction data={data} api={api} status={global_status} action={action} setAction={setAction} handleImageRequest={handleImageRequest} />
							 </Col>
						 </Row>
						 <Row className='mt-2'>{ShowExtraData()}</Row>
					 </>}
				</>
			</Modal>
		</>
	);
};

export default ProductInfoModel;
