import {createAsyncThunk} from "@reduxjs/toolkit";
import {Api} from "../../helper/api/api";

// order checkout
export const orderCheckout = createAsyncThunk(
	"checkout/orderCheckout",
	async(data) => {
		return await Api.post("/checkout", data, {headers: {"Content-Type": "multipart/form-data",},})
						.then((res) => {
							return {data: res?.data, status: res.status, message: res?.response?.data?.message};
						}).catch((error) => {
				return error
			});
	}
);


// Check checkout
export const checkCheckout = createAsyncThunk(
	"checkCheckout/checkCheckout",
	async() => {
		return await Api.get("/checkCheckout")
						.then((res) => {
							return {message: res.data.message, status: res.status};
						})
						.catch((error) => {
							return error
						});
	}
);