import {DebounceInput} from "react-debounce-input";
import React from "react";
import {Col, Row, Space, Typography} from "antd";

const {Title} = Typography;

const FilterPriceData = ({filterData, setFilterData}) => {
	const data = filterData.final_price

	// Handle min-price onChange
	const handleMinPriceChange = (e) => setFilterData({...filterData, final_price: {...filterData.final_price, min: e.target.value}})

	// Handle max-width onChange
	const handleMaxPriceChange = (e) => setFilterData({...filterData, final_price: {...filterData.final_price, max: e.target.value}})

	return (
		<>
			<Title level={5} className='filter-title'> Price </Title>
			<Space>
				 <DebounceInput debounceTimeout={800} className="debounce_input" placeholder="From" value={data.min} onChange={handleMinPriceChange} />
				 <DebounceInput debounceTimeout={800} className="debounce_input" placeholder="To" value={data.max} onChange={handleMaxPriceChange} />
			</Space>
		</>
	);
};

export default FilterPriceData;
