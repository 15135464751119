import {configureStore} from "@reduxjs/toolkit";
import DiamondDataReducer from "./diamond/diamond-slice";
import CartDataReducer from "./cart/cart-slice";
import WishlistDataReducer from "./wishlist/wishlist-slice";
import CurrencyDataReducer from "./currency/currency-slice";
import AuthReducer from "./auth/auth-slice";
import CheckoutReducer from "./checkout/checkout-slice";
import EnquiryReducer from "./enquiry/enquiry-slice";
import OrderReducer from "./order/order-slice";
import GlobalSettingReducer from "./layout/layout.slice";
import AddressBookReducer from "./address/address-slice";

// Store The Features of Reducer
const store = configureStore(
	{
		reducer: {
			diamondData   : DiamondDataReducer,
			cartData      : CartDataReducer,
			wishlistData  : WishlistDataReducer,
			currencyData  : CurrencyDataReducer,
			auth          : AuthReducer,
			orderCheckout : CheckoutReducer,
			enquiryRequest: EnquiryReducer,
			orderHistory  : OrderReducer,
			addressBook   : AddressBookReducer,
			globalSetting : GlobalSettingReducer
		},
	});

export default store;
