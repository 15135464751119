import React from "react";

// Lazy Loader
const lazyLoader = (importComp, fallback) => {
	return class extends React.Component {
		//Initial state
		state = {component: null};

		//loading the component and setting it to state
		componentDidMount() { importComp().then((comp) => this.setState({component: comp.default})); }

		//rendering the component
		render() {
			const C = this.state.component;
			return C ? (<C {...this.props} />) : fallback ? (fallback) : (<div></div>);
			// If component is not loaded then return fallback component, if fallback is not provided then use default loading
		}
	};
};
export default lazyLoader;