import {DebounceInput} from "react-debounce-input";
import React from "react";
import {Col, Row, Space, Typography} from "antd";

const {Title} = Typography;

const FilterDepthData = ({filterData, setFilterData}) => {

	const ratio = filterData.ratio
	const depth = filterData.depth

	// Handle min-ratio onChange
	const handleMinRatioChange = (e) => setFilterData({...filterData, ratio: {...filterData.ratio, min: e.target.value}})

	// Handle max-ratio onChange
	const handleMaxRatioChange = (e) => setFilterData({...filterData, ratio: {...filterData.ratio, max: e.target.value}})

	// Handle min-depth onChange
	const handleMinDepthChange = (e) => setFilterData({...filterData, depth: {...filterData.depth, min: e.target.value}})

	// Handle max-depth onChange
	const handleMaxDepthChange = (e) => setFilterData({...filterData, depth: {...filterData.depth, max: e.target.value}})

	return (
		<Row gutter={[8, 0]}>
			<Col span={12}>
				<Title level={5} className='filter-title'>Ratio</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={ratio.min} onChange={handleMinRatioChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={ratio.max} onChange={handleMaxRatioChange} />
				</Space>
			</Col>
			<Col span={12}>
				<Title level={5} className='filter-title'>Depth Percent</Title>
				<Space>
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Min" value={depth.min} onChange={handleMinDepthChange} />
					<DebounceInput type="number" min={0} debounceTimeout={800} className="debounce_input" placeholder="Max" value={depth.max} onChange={handleMaxDepthChange} />
				</Space>
			</Col>
		</Row>
	);
};

export default FilterDepthData;
