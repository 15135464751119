import React, {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useNavigate} from 'react-router-dom';
import {removeData} from '../../helper/other/common';
import {Typography} from "antd";
import {useDispatch} from "react-redux";
import {removeToken} from "../../redux/auth/auth-slice";

const {Title} = Typography;
const ErrorFallback = ({error}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if(error.message) {
			navigate("/");
			removeData();
			dispatch(removeToken());
		}
	}, [error, navigate, dispatch]); // Added dependencies
	return (
		<div>
			<Title className='text-center text-danger'>Something went wrong</Title>
			<pre className='text-center'>{error.message}</pre>
		</div>
	);
};
const Boundary = ({children}) => {
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			{children}
		</ErrorBoundary>
	);
};
export default Boundary;