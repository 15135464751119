/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const LoadingPage = () => {
	return (
		<div className="loading-data">
			<img src="/images/animat-diamond.gif" />
		</div>
	)
}

export default LoadingPage