import React, {useEffect} from "react";
import DiamondsPage from "./DiamondsPage";
import {pageName} from "../../helper/other/common";

export const NaturalPage = () => {

	useEffect(() => {
		document.title = `Natural - ${pageName}`;
	});

	return <DiamondsPage type="natural" />
};
