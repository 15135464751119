import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {Button, Col, notification, Result, Row, Space} from "antd";
import {isEmpty, pageName} from "../../helper/other/common";
import {useNavigate} from "react-router-dom";
import "./WishList.css"
import ProductCard from "../../layouts/product/grid/Product";
import {isEqual, size} from "lodash";

const WishListPage = () => {
	const navigate = useNavigate()
	const [api, notificationHolder] = notification.useNotification();
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const globalStatus = isEmpty(globalSetting) ? {} : globalSetting
	let wishlistData = useSelector((state) => state.wishlistData);

	useEffect(() => {
		document.title = `WishList -  ${pageName}`;
	}, []);

	useEffect(() => {
		localStorage.setItem("wishlist", JSON.stringify(wishlistData))
	}, [wishlistData]);

	// Check the wishlist length
	const isWishList = isEqual(size(wishlistData), 0)

	// Looping on wishlist data
	const mapOfWisListData = wishlistData?.map((data, index) => <Col key={index} className="product_card_col"> <ProductCard data={data} status={globalStatus} api={api} isWishlist={true}/> </Col>)

	return (
		// Start-Section
		<section>
			{notificationHolder}
			{isWishList ?
			 <Row justify="center" className="not-found-content">
				 <Result icon={<img src='images/not_found.png' className="image" />} title="Your wishlist is currently empty." extra={
					 <Space>
						 <Button type="primary" onClick={() => navigate('/natural')}>Natural </Button>
						 <Button type="primary" onClick={() => navigate('/lab-grown')}>Lab Grown</Button>
					 </Space>} />
			 </Row> :
			 <section className="diamondpage wishlistPage">
				 <Row>{mapOfWisListData}</Row>
			 </section>}
		</section>
		// 	End-Section
	)
}

export default WishListPage