import React from 'react'
import {useSelector} from "react-redux";
import {isEmpty, isEqual, isNumber} from "lodash";
import {commission, countCommission, dateFormatting, diamondString, eventPropagation, exchangeRate, formatPrice, getLabUrl} from "../../../helper/other/common";
import {Link} from "react-router-dom";
import DiamondConstData from "../../../helper/constant/diamond-constant";
import {Avatar, Col, Divider, Row, Tooltip, Typography} from "antd";
import {IdcardFilled, LockFilled, SafetyCertificateFilled} from "@ant-design/icons";

const {Text, Title} = Typography;
const ProductDetailCard = (props) => {

	let currency = useSelector((state) => state.currencyData);
	let {address_location, cert_number, stock_id, seller, table, depth, measurements, color, lab, shade, milky, eye_clean, final_price, ratio, discount, treatment, type} = props?.data;


	// Update to depth, table, and ratio records
	depth = isNumber(depth) ? parseFloat(depth.toFixed(2)) : 0;
	table = isNumber(table) ? parseFloat(table.toFixed(2)) : 0;
	ratio = isNumber(ratio) ? parseFloat(ratio.toFixed(2)) : 0;

	// Update to shade, milky, and eye_clean records
	shade = isEmpty(shade) ? "None" : shade
	milky = isEmpty(milky) ? "None" : milky
	eye_clean = isEmpty(eye_clean) ? "No" : eye_clean

	// Update the treatment value
	treatment = isEmpty(treatment) ? 'None' : treatment

	// set price according selected currency value
	let diamondPrice = formatPrice(final_price * exchangeRate(currency))
	const commissionCurrencyPrice = final_price * exchangeRate(currency)

	//when click on the link , set not open modal
	const handleLabLinkClick  = (e) => eventPropagation(e)

	// Lab Url
	const labUrl = lab !== "Non-cert" ? (<Link to={getLabUrl(lab, cert_number)} target="_blank" onClick={handleLabLinkClick}>{cert_number}</Link>) : (<Text className="fs-12">{cert_number}</Text>)

	// Return Policy
	const returnPolicy = isEqual(seller?.return_option, "1") || isEqual(props.status?.seller_return_option, "1") ? "Returnable" : "Not Returnable"

	// Seller Display name
	const sellerName = isEmpty(seller.display_name) ? "seller_name" : seller.display_name

	// Return a diamond string
	const itemString = diamondString(props.data)

	// Currency Symbol
	const currencySymbol = DiamondConstData.multi_currency[currency].symbol

	// Delivery Price
	const deliveryPrice = formatPrice(countCommission(seller, final_price, props.status))

	// Delivery Price without formatting
	const deliveryPriceWithoutFormatting = countCommission(seller, final_price, props.status)

	// Delivery Price commission
	const deliveryPriceDiscount = commission(discount, seller, props.status, commissionCurrencyPrice, deliveryPriceWithoutFormatting)

	// Count delivery date
	const deliveryDate = dateFormatting(props.status.estimated_delivery_date)

	return (
		<section>
			<Row>
				<Col flex={0.1} span={4}>
					<Avatar src={DiamondConstData.flag[`${address_location}`]} size="large" />
				</Col>
				<Col span={6}>
					<Tooltip title={sellerName}> <Text className="text-style">{sellerName}</Text> </Tooltip>
					<Text type="secondary" level={5}>{address_location}</Text>
				</Col>

				<Col span={14}>
					<Row justify={"end"}>
						<Col>
							<SafetyCertificateFilled className="icon" />
							<Text type="secondary" className="fs-12">{lab} : </Text>
							<Text className="fs-12" copyable={{text: cert_number, tooltips: [`Copy: ${cert_number}`, "Copied"]}} strong><p className={'ellipsis-text'}>{labUrl}</p></Text>
						</Col>
						<Col>
							<IdcardFilled className="icon" />
							<Text type="secondary">Stock Id : </Text>
							<Text  copyable={{text: stock_id, tooltips: [`Copy: ${stock_id}`, "Copied"]}} strong ><p className={'ellipsis-text'}>{stock_id}</p></Text>
						</Col>
					</Row>
				</Col>
			</Row>

			{/* Shape, weight, color, cut, polish, symmetry, and fluorescence intensity */}
			<Title className="mt-15 text-uppercase fw-bold" level={5}>{itemString}</Title>
			<Divider className="m-1" />

			{/* Diamond depth, ratio, table, and measurements */}
			<Row justify='space-between'>
				<Col>
					<Text type="secondary">T: </Text>
					<Text strong>{table}</Text>
				</Col>
				<Col>
					<Text type="secondary">D: </Text>
					<Text strong>{depth}</Text>
				</Col>
				<Col>
					<Text type="secondary">R: </Text>
					<Text strong>{ratio}</Text>
				</Col>
				<Col>
					<Text type="secondary">M: </Text>
					<Text strong>{measurements.toLowerCase()}</Text>
				</Col>
			</Row>

			{/* Shade, Milky, and Eye Clean */}
			<div className="diamond-shade p-2 justify-content-center">
				<Row justify='space-between'>
					<Col>
						<Text type="secondary">Shade: </Text>
						<Text strong>{shade}</Text>
					</Col>
					<Col>
						<Text type="secondary">Milky: </Text>
						<Text strong>{milky}</Text>
					</Col>
					<Col>
						<Text type="secondary">Eye Clean: </Text>
						<Text strong>{eye_clean}</Text>
					</Col>
				</Row>
			</div>

			<div className="mt-2">
				<Row justify='space-between'>
					<Col>
						<Text type="secondary">Return Policy: </Text>
						<Text strong>{returnPolicy}</Text>
					</Col>
					{type === 'lab_grown' && <Col>
						<Text type="secondary">Treatment: </Text>
						<Text strong className='text-uppercase'>{treatment}</Text>
					</Col>}
				</Row>
			</div>

			{/* Diamond and Delivery Price/Time */}
			<Divider className="m-2" />
			<Row className="price-detail">
				<Col flex={1}>
					<Text type="secondary" className="price-title text-uppercase"> Diamond Price </Text><br />
					{color !== "Fancy" && <><Text strong className="text-discount-price">{discount} % </Text> <br /></>}
					<Text strong>{currencySymbol}{diamondPrice}</Text>
				</Col>

				<Divider type="vertical" className="h-auto" />
				<Col flex={1}>
					<Text type="secondary" className="price-title text-uppercase"> Delivery Price </Text><br />
					{color !== "Fancy" && <><Text strong className="text-discount-price">{deliveryPriceDiscount} % </Text> <br /></>}
					<Text strong>{currencySymbol}{deliveryPrice}
						<Tooltip title={DiamondConstData.message.TOOL_TIP}> <LockFilled /> </Tooltip>
					</Text>
				</Col>
				<Divider type="vertical" className="h-auto" />
				<Col flex={1}>
					<Tooltip title={"Estimate Delivery Date"}> <Text className="text-uppercase price-title" type="secondary">Estimate Del..</Text> </Tooltip><br />
					{deliveryDate}
				</Col>
			</Row>
		</section>);
};
export default ProductDetailCard
