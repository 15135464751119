import React, {useEffect} from 'react'
import {Button, Col, Row, Typography} from 'antd'
import {TREATMENTDATA} from "../../../../helper/constant/filter-constant";

const {Title} = Typography

const FilterTreatmentData = ({filterData, setFilterData}) => {

	// Handle Treatment-card click
	const handleTreatmentCardClick = (treatment) => {
		if(filterData?.treatment?.includes(treatment)) {
			const filterTreatmentData = filterData?.treatment?.filter(name => name !== treatment);
			setFilterData({...filterData, treatment: filterTreatmentData})
		} else if(!filterData.treatment) {
			setFilterData({...filterData, treatment: [treatment]})
		} else setFilterData({...filterData, treatment: [...filterData?.treatment, treatment]})
	}

	return <>
		<Title level={5} className='filter-title'> Treatment </Title>
		<Row gutter={[8, 8]}>
			{TREATMENTDATA.map((treatment) => {
				const buttonType = filterData?.treatment?.includes(treatment) ? "primary" : "default"
				return <Col key={treatment}> <Button type={buttonType} onClick={() => handleTreatmentCardClick(treatment)} className='text-uppercase'>{treatment}</Button> </Col>
			})}
		</Row>
	</>
}
export default FilterTreatmentData