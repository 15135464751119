import React, {useEffect, useRef, useState} from "react";
import {Badge, Button, Divider, Dropdown, Image, Select, Space, Typography,} from "antd";
import {HeartOutlined, ShoppingCartOutlined, UserOutlined} from "@ant-design/icons";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {setCurrencyData} from "../../redux/currency/currency-slice";
import {setFilterData, setIsLogin} from "../../redux/diamond/diamond-slice";
import {filterArray} from "../../helper/constant/filter-constant";
import {removeData} from "../../helper/other/common";
import '../layout.css'
import {removeAllCartData} from "../../redux/cart/cart-slice";
import {removeAllWishListData} from "../../redux/wishlist/wishlist-slice";
import {isEqual, keys} from "lodash";
import {removeToken} from "../../redux/auth/auth-slice";
import DiamondConstData from "../../helper/constant/diamond-constant";

const {Text} = Typography;
/**
 * User Dropdown value
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const UserDropDown = ({setExpanded}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	//when click on menu item close the nav menu bar
	const onCloseNavMenu = () => setExpanded(false)

	// click on sign out button
	const onClickSignOut = () => {
		onCloseNavMenu()
		dispatch(setIsLogin(false))
		const data = {...filterArray}
		delete data.diamondPriority;
		navigate("/");
		dispatch(setFilterData(data))
		dispatch(removeAllCartData())
		dispatch(removeAllWishListData())
		dispatch(removeToken())
		removeData()
	};


	// Nav link item array
	const items = [
		{
			key  : "1",
			label: <NavLink to="/my-account" className="topbar-link" onClick={onCloseNavMenu}>My Account</NavLink>,
		},
		{
			key  : "2",
			label: <NavLink to="/order" className="topbar-link" onClick={onCloseNavMenu}>Orders</NavLink>,
		},
		{
			key  : "3",
			label: <NavLink to="/enquiry" className="topbar-link" onClick={onCloseNavMenu}>Enquiries</NavLink>,
		},
		{
			key  : "4",
			label: <NavLink className="topbar-link" onClick={onClickSignOut}>Sign Out</NavLink>,
		},

	];
	return (
		<>
			<Dropdown menu={{items, selectable: true}} placement="bottomRight" arrow={{pointAtCenter: true}}>
				<Button size="large" shape="round" className="nav-btn">
					<UserOutlined className="icon-style" />
				</Button>
			</Dropdown>
		</>
	)
}

/**
 * Return navbar action button with JSX component
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const NavBarActionButton = ({setExpanded}) => {
	const userInfo = useSelector((state) => state.auth.accountDetails);
	const kycStatus = userInfo?.data?.kyc_status;
	const navigate = useNavigate();
	const token = localStorage.getItem("token");

	let wishlistData = useSelector((state) => state.wishlistData);
	let cartData = useSelector((state) => state.cartData);

	const onClickCartButton = () => {
		setExpanded(false)
		navigate("/cart")
	};

	const onClickWishlistBtn = () => {
		setExpanded(false)
		navigate("/wishlist")
	}
	
	// Check KYC status
	const kycApproved = isEqual(kycStatus, 'approved')

	// this condition given in component because of design issue
	return (
		<>
		 <Space size={[4]}> {kycApproved && <>
			<Button className="nav-btn" size="large" shape="round" onClick={onClickWishlistBtn}>
				<Badge count={token && wishlistData.length} color='var(--primary)'>
					<HeartOutlined className="icon-style" />
				</Badge>
			</Button>
			<Button className="nav-btn" size="large" shape="round" onClick={onClickCartButton}>
				<Badge count={token && cartData.length} color='var(--primary)'>
					<ShoppingCartOutlined className="icon-style" />
				</Badge>
			</Button> </>}
			<UserDropDown setExpanded={setExpanded} />
		</Space>
		</>
	)
}

/**
 * Currency component
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const CurrencySwitcher = ({setExpanded}) => {
	const dispatch = useDispatch();

	const selectRef = useRef(null);
	let currencyData = useSelector((state) => state.currencyData);
	let globalSetting = useSelector((state) => state.globalSetting).globalSetting;
	const [currencyItem, setCurrencyItem] = useState([]);

	// multi currency object
	const multi_currency
		= {
		"USD": {rate: "(1)"},
		"GBP": {rate: `(${globalSetting?.exchange_rate_gbp?.toFixed(4)})`},
		"EUR": {rate: `(${globalSetting?.exchange_rate_eur?.toFixed(4)})`},
		"INR": {rate: `(${globalSetting?.exchange_rate_inr?.toFixed(4)})`}
	}

	// Develop currency logo JSX function(component)
	// Return a JSX code
	const currencyLogo = (key) => {
		return (
			<>
				<img src={DiamondConstData.multi_currency[key].logo} alt={key} className="currency_logo" />
				<Text className="currency-text">
					{key} {DiamondConstData.multi_currency[key].symbol} {multi_currency[key].rate}
				</Text>
			</>
		)
	}

	// Set currency value and logo
	const handleCurrency = () => {
		const item = [];
		keys(DiamondConstData.multi_currency).map((key) => {
			item.push({value: key, label: currencyLogo(key)});
		});
		setCurrencyItem(item);
	}

	// set value in multi currency dropdown
	useEffect(() => {
		handleCurrency()
	}, []);

	// change currency value
	const handleChangeCurrency = (value) => {
		setExpanded(false)
		dispatch(setCurrencyData(value));
		localStorage.setItem("currency", value);
	};

	// drop-down visible onchange
	const handleDropdownVisibleChange = (e) => {
		if(selectRef.current && !e) {
			selectRef.current.blur();
		}
	}

	return (
		// Start Section
		<Select ref={selectRef}
				defaultValue={currencyData}
				className="currency-dropdown"
				onChange={handleChangeCurrency}
				options={currencyItem}
				onDropdownVisibleChange={handleDropdownVisibleChange} />
	)
}

/**
 * Return a Menu Bar with using map method
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */
const Menu = ({setExpanded}) => {

	// NavBar item describe with path and name
	const navLinkItem = [
		{path: "/", name: "Home"},
		{path: "/natural", name: "Natural"},
		{path: "/lab-grown", name: "Lab Grown"},
	]

	return (
		<>
			{navLinkItem.map((item) => (
				<NavLink to={item.path} className="nav-link" style={({isActive}) => ({
					textDecoration: isActive ? "underline" : "",
				})} onClick={() => setExpanded(false)}>{item.name}</NavLink>
			))}
			<a className="nav-link" href="https://diahearts.freshdesk.com/" target="_blank"> Support </a>
		</>
	)
}

const HeaderPage = () => {
	const [expanded, setExpanded] = useState(false);
	let auth = useSelector((state) => state?.auth)

	return (
		<>
			<Navbar expanded={expanded} expand="lg" className="bg-body-tertiary ps-1 pe-3">
				<Navbar.Brand href="/">
					<Image className="navbar-img" src="/images/DiaHearts Logo2.png" preview={false} alt="Diamond Logo" />
				</Navbar.Brand>
				<Nav className="cartBtn header-action-btn">
					{auth.token && !auth.isAuthPath && <NavBarActionButton setExpanded={setExpanded} />}
				</Nav>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="justify-content-center ms-auto">
						<Menu setExpanded={setExpanded} />
					</Nav>
					<Nav className="ms-auto">
						{auth.token && !auth.isAuthPath &&
							<Space size={[4]}>
								<CurrencySwitcher setExpanded={setExpanded} />
								<span className="btnCart">
							   <Divider type="vertical" className="vertical-divider" />
							   <NavBarActionButton setExpanded={setExpanded} />
							</span>
							</Space>
						}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default HeaderPage;
