import {Button, Col, Row, Typography} from "antd";
import React from "react";
import {GIRDLE} from "../../../../helper/constant/filter-constant";
import {includes} from 'lodash'

const {Title} = Typography;
const FilterGirdle = ({filterData, setFilterData}) => {
	const data = filterData?.girdle_thick

	//Handle onClick for selected girdle card
	const handleCardClick = (girdle) => {
		if(includes(data, girdle)) {
			const filterGirdleData = data?.filter(name => name !== girdle);
			setFilterData({...filterData, girdle_thick: filterGirdleData, girdle_thin: filterGirdleData})
		} else {
			const girdleData = [...filterData?.girdle_thick, girdle]
			setFilterData({...filterData, girdle_thick: girdleData, girdle_thin: girdleData})
		}
	}

	return (
		<>
			<Title level={5} className='filter-title'> girdle </Title>
			<Row gutter={[8, 8]}>
				{GIRDLE.map((girdle, i) => {
					const buttonType = includes(data, girdle) ? "primary" : "default"
					return <Col key={girdle}> <Button type={buttonType} onClick={() => handleCardClick(girdle)}>{girdle}</Button> </Col>;
				})}
			</Row>
		</>
	);
};

export default FilterGirdle;
