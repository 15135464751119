//fetch all addresses
import {Api} from "../../helper/api/api";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const fetchAddresses = createAsyncThunk(
	'addressBook/fetchAddresses',
	async() => {
		return await Api.get("/address").then(res => {
			if(res.status === 200) {
				return res.data.data;
			} else {
				return [];
			}
		}).catch((error) => {
			return error
		});
	}
);

//fetch single address
export const fetchAddress = async(id) => {
	return await Api.get(`/address/${id}`)
					.then((res) => {
						return res;
					}).catch((err) => err.response);
};

//add address
export const addAddress = async(data) => {
	return await Api.post(`/address/store`, data)
					.then((res) => {
						return res;
					}).catch((err) => err.response);
};

//update address
export const updateAddress = async(id, data) => {
	return await Api.post(`/address/${id}/update`, data)
					.then((res) => {
						return res;
					}).catch((err) => err.response);
};

//delete address
export const deleteAddress = async(id) => {
	return await Api.delete(`/address/${id}`)
					.then((res) => {
						return res;
					}).catch((err) => err.response);
};