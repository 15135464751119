import React, {useEffect, useState} from "react";
import {Button, Col, Input, List, Row, Table, Typography,} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {DIAMONDSHAPE} from "../../helper/constant/filter-constant";
import DiamondConstData from "../../helper/constant/diamond-constant";
import {useNavigate} from "react-router-dom";
import {countCommission, diamondString, exchangeRate, formatPrice, pageName} from "../../helper/other/common";
import Hoc from "../../components/utils/higher-order/Hoc";
import {checkCheckout} from "../../redux/checkout/checkout-action";
import {removeCartData, updateCartData} from "../../redux/cart/cart-slice";
import {fetchSettingStatus} from "../../redux/order/order-action";
import {includes, isEmpty, isEqual, isUndefined, lastIndexOf, size} from 'lodash'
import AlertMessage from "../../helper/other/AlertMessage";
import PreviewImage from "../../components/utils/image-view/PreviewImage";

const {Title, Text} = Typography;

const CartPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let cartData = useSelector((state) => state.cartData);
	const [kycMessage, setKycMessage] = useState("");
	const [loading, setLoading] = useState(false);
	let currency = useSelector((state) => state.currencyData);
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting
	const symbol = DiamondConstData.multi_currency[currency].symbol
	const {TextArea} = Input;
	// Check user kyc is approved or not
	const checkUserKyc = async() => {
		setLoading(true)
		const data = await dispatch(checkCheckout());
		if(!isUndefined(data.payload)) {
			setLoading(false)
			navigate("/checkout");
		} else {
			setKycMessage(data.payload?.message);
			setLoading(false)
		}
	};

	useEffect(() => {
		document.title = `Cart -  ${pageName}`;
		dispatch(fetchSettingStatus())
	}, []);

	// Handle image error
	const onImageError = (e, imagePath) => e.currentTarget.src = imagePath

	// View diamond image
	const cartImage = (data) => {
		let {img_link} = data
		let fileExt

		if(isEmpty(img_link)) img_link = false
		else {
			let lastIndexFileExt = lastIndexOf(img_link, ".");
			fileExt = img_link?.slice(lastIndexFileExt + 1);
		}

		let imagePath = DIAMONDSHAPE?.filter((shape) => shape.name === data.shape).map((data) => data?.image)
		const fileExtArr = ["jpg", "jpeg", "JPG", "JPEG", "png", "PNG"]
		const isFileExtension = includes(fileExtArr, fileExt)

		// Return image with srcFile and image link
		const CartImage = (imagesrc) => {
			return <PreviewImage src={imagesrc} className="cart-image" onError={(e) => onImageError(e, imagePath)} />
		}

		return <Button className="preview-button">{!img_link ? (CartImage(imagePath)) : isFileExtension ? CartImage(img_link) : CartImage(imagePath)}</Button>
	}

	const handleNoteChange = (e, cart) => {
		const updatedCart = { ...cart, note: e.target.value };
		dispatch(updateCartData({ cart: updatedCart }));
	}

	// Product columns
	const productColumns = [
		{
			dataIndex: "close",
			key      : "close",
			align    : "center",
			render   : (cart) => <CloseCircleOutlined className="fs-6" onClick={() => dispatch(removeCartData(cart))} />,
		},
		{
			title    : <Text className="fs-7 fw-bold text-uppercase"> Image </Text>,
			dataIndex: "image",
			key      : "image",
			align    : "center",
			render   : (cart) => cartImage(cart),
		},
		{
			title    : <Text className="fs-7 fw-bold text-uppercase"> Title </Text>,
			dataIndex: "product",
			key      : "product",
			render   : (cart) => <>
				<Text className="fw-bold text-uppercase">{diamondString(cart)}</Text>
				<TextArea placeholder="Enter Note here" autoSize={{minRows: 1}} onChange={(e) => handleNoteChange(e, cart)} value={cart.note || ""}/>
			</>,
		},
		{
			title    : <Text className="fs-7 fw-bold text-uppercase"> Certificate Number </Text>,
			dataIndex: "close",
			key      : "close",
			render   : (text) => <Text className="fw-bold text-uppercase">{text.cert_number}</Text>,
		},
		{
			title    : <Text className="fs-7 fw-bold text-uppercase"> Price </Text>,
			dataIndex: "price",
			key      : "price",
			align    : "center",
			render   : (text) => {
				return <Text>{symbol}{formatPrice(text)}</Text>
			},
		},
	];

	// Product data
	const productData = cartData?.map((cart, i) => ({
		key    : i,
		close  : cart,
		image  : cart,
		product: cart,
		price  : `${countCommission(cart.seller, cart.final_price, global_status)}`,
	}));

	let subTotal = productData?.reduce((total, item) => total + (Number(item.price)), 0);

	// formatted price
	const formattedPrice = `${symbol}${formatPrice(subTotal)}`

	// convert tot usd price like country -> usd
	const convertToUsd = ` (${DiamondConstData.multi_currency["USD"].symbol}${formatPrice((subTotal / exchangeRate(currency)))})`

	// final subtotal price
	const finalSubTotal = `${formattedPrice}${DiamondConstData.multi_currency[currency].symbol !== DiamondConstData.multi_currency["USD"].symbol ? convertToUsd : ""}`

	// Disable checkout button
	const isDisable = isEqual(size(cartData), 0) ? true : false

	// Shipping Title and content
	const shippingContent = <><Text className="fw-bold d-flex justify-content-center align-items-center w-auto"> Free shipping </Text> <Text>Shipping options will be updated during checkout.</Text></>

	// list for display price details
	const list = [
		{
			name: "Subtotal",
			data: formattedPrice,
		},
		{
			name: "Shipping",
			data: shippingContent
		},
		{
			name: "Total",
			data: finalSubTotal
		},
	];

	useEffect(() => {
		localStorage.setItem("add_to_cart", JSON.stringify({data: cartData, expire: JSON.parse(localStorage.getItem("add_to_cart"))?.expire}));
	}, [cartData]);

	// Check the string is empty or not with lodash
	const isKycApproved = isEmpty(kycMessage)

	return (
		<>
			<section className="main-container">
				<Title className="text-center page-title mb-4" level={2}>Cart</Title>
				{/*Alert Message*/}
				{!isKycApproved && <Row justify="center"> <Col> <AlertMessage className="mb-4" message={"kycMessage"} type="error" closable={true} /> </Col> </Row>}

				<Row justify="center" gutter={[30, 10]}>
					<Col flex={1} className="w-50">
						<Table columns={productColumns} dataSource={productData} pagination={false} scroll={{x: 'max-content'}} />
					</Col>
					<Col flex={1}>
						<List dataSource={list} bordered renderItem={(item) => (
							<List.Item key={item.email}>
								<List.Item.Meta title={<Row>
									<Col flex={1}>
										<Text className="fs-6 fw-bold">{item.name}</Text>
									</Col>
									<Col flex={1} className="fs-6 fw-normal d-flex justify-content-center align-items-center">
										<Text>{item.data}</Text>
									</Col>
								</Row>} />
							</List.Item>)} />
						<div className="mt-2 d-flex justify-content-center align-items-center">
							<Button type="primary" className="text-uppercase" shape="round" onClick={checkUserKyc} disabled={isDisable} loading={loading}> Proceed to CheckOut </Button>
						</div>
					</Col>
				</Row>
			</section>
		</>);
};

export default Hoc(CartPage);


