import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {removeOrderFilterData} from "../redux/order/order-slice";
import {scrollTo} from "../helper/other/common";
import {includes, isEqual} from 'lodash'
import {removeToken} from "../redux/auth/auth-slice";

const ProtectedRoute = ({children}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userInfo = useSelector((state) => state.auth.accountDetails);
	let token = localStorage.getItem("token");
	let verifyStatus = localStorage.getItem("email_verified");
	let kycStatus = userInfo.data?.kyc_status || "undefined"
	const data = scrollTo();
	const kycStatusArray = ['rejected', 'pending']
	const isOrderPage = window.location.pathname.startsWith('/order')
	const isEmailNotVerified = isEqual(verifyStatus, 'false');
	const isKycNotApproved = token && includes(kycStatusArray , kycStatus);;

	useEffect(() => {
		if(!isOrderPage) dispatch(removeOrderFilterData())
		if(!token) {
			navigate("/login");
			dispatch(removeToken())
		}
		else if(isEmailNotVerified) navigate("/email/verify");
		else if(isKycNotApproved) navigate("/kyc");
	});

	// Return the child component if the user is authenticated
	const child = (token && !isKycNotApproved && !isEmailNotVerified) && children;

	return child
};

export default ProtectedRoute;
