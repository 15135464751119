import {createSlice} from "@reduxjs/toolkit";
import {enquiryRequest} from "./enquiry-action";

export const EnquiryReducer = createSlice(
	{
		name         : "enquiryRequest",
		initialState : {
			token  : "",
			error  : "",
			loading: false,
		},
		reducers     : {},
		extraReducers: (builder) => {
			builder.addCase(enquiryRequest.fulfilled, (state, action) => {
				void ((state.token = action.payload?.token), (state.loading = false));
			});
			builder.addCase(enquiryRequest.pending, (state) => {state.loading = true})
		},
	});

export default EnquiryReducer.reducer;
