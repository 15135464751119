import React, {useEffect, useState} from "react";
import {Button, Col, Row, Typography} from "antd";
import {COLOR} from "../../../../helper/constant/filter-constant";
import {includes, indexOf, isEqual, isNil, size, slice} from "lodash";

const {Title} = Typography;
const FilterColorData = ({filterData, setFilterData}) => {
		const colorData = filterData?.color
		const [selectItem, setSelectItem] = useState({low: null, high: null,});

		useEffect(() => {
			const colorArr = slice(COLOR, selectItem.low, selectItem.high + 1)
			if(isNil(selectItem.low) && isNil(selectItem.high)) setFilterData({...filterData, color: []})
			else setFilterData({...filterData, color: colorArr})
		}, [selectItem]);

		useEffect(() => {
			const length = size(colorData)
			if(isEqual(length, 1)) {
				let index = indexOf(COLOR, colorData[0])
				setSelectItem({low: index, high: index})
			} else if(length > 0) {
				let firstIndex = indexOf(COLOR, colorData[0])
				let lastIndex = indexOf(COLOR, colorData[length - 1])
				setSelectItem({low: firstIndex, high: lastIndex})
			}
		}, [])

		// Handle color-card-click
		const handleColorCardClick = (i) => {
			const {low, high} = selectItem;
			if(isEqual(i, low) && isEqual(i, high)) setSelectItem({low: null, high: null});
			else if(isNil(low) && isNil(high)) setSelectItem({low: i, high: i});
			else if(!isEqual(low, high) || isNil(low) || isNil(high)) setSelectItem({low: i, high: i});
			else if(i >= high) setSelectItem({...selectItem, high: i})
			else setSelectItem({...selectItem, low: i});
		}


		return (
			<>
				<Title level={5} className='filter-title'> Color </Title>
				<Row gutter={[3, 8]}>
					{COLOR.map((color, i) => {
						let buttonType = includes(colorData, color) ? "primary" : "default"
						return <Col key={color}> <Button type={buttonType} onClick={() => handleColorCardClick(i)}>{color}</Button> </Col>;
					})}
				</Row>
			</>
		);
	}
;

export default FilterColorData;
