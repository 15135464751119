import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Space, Table, Tag, Typography} from "antd";
import {LeftOutlined, RightOutlined,} from "@ant-design/icons";
import {diamondString, isEmpty, pageName, scrollTo} from "../../helper/other/common";
import {fetchEnquiryHistory} from "../../redux/enquiry/enquiry-action";
import {ceil, isEqual} from "lodash";

const {Title} = Typography;
export const EnquiryPage = () => {
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [totalData, setTotalData] = useState(0);
	const navigate = useNavigate();
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting
	const [enquiryData, setEnquiryData] = useState(
		{
			data   : [],
			error  : false,
			loading: true
		})

	// fetch all enquiries from redux call api
	const fetchEnquiries = async() => {
		setEnquiryData({...enquiryData, loading: true})
		const response = await fetchEnquiryHistory(page);
		let totalPages;
		if(response.data) {
			setEnquiryData({data: response.data, loading: false, error: false})
			setTotalData(response.total)
			totalPages = response.payload?.err ? 0 : ceil(response.total / response.per_page) || 0;
			isEqual(response.data.length, 0) && setPage(0)
		} else {
			setEnquiryData({...enquiryData, error: false})
			setTotalData(0)
			totalPages = 0;
			setPage(0)
		}
		setTotalPage(totalPages);
	};

	useEffect(() => {
		scrollTo()
		fetchEnquiries();
	}, [page]);

	// Render The RequestData component for display requested data in enquiryDetails Columns
	const RequestedData = (data) => !data?.length ? "-" : data?.map((v) => <Tag color="geekblue" className='enquiry-tag'>{v?.toUpperCase()}</Tag>)

	// Enquiry columns
	const columns = [
		{
			title    : "Request ID",
			dataIndex: "id",
			key      : "id",
		},
		{
			title    : "Certificate Number",
			dataIndex: "diamond",
			key      : "diamond",
			render   : (data) => data?.cert_number || '-'
		},
		{
			title    : "Stock Id",
			dataIndex: "diamond",
			key      : "diamond",
			render   : (data) => data?.stock_id || '-'
		},
		{
			title    : "Diamond Title",
			dataIndex: "diamond",
			key      : "diamond",
			render   : (data) => data ? diamondString(data) : "-"
		},
		{
			title    : "Message",
			dataIndex: "message",
			key      : "message",
			render   : (message) => message || '-'
		},
		{
			title    : "Requested data",
			key      : "request_for",
			dataIndex: "request_for",
			render   : (data) => RequestedData(data)
		},
		{
			title    : "Status",
			key      : "status",
			dataIndex: "status",
			render   : (status) => {
				const color = global_status?.status?.enquiry_status_color[status];
				return <Tag color={color} className='enquiry-tag'>{global_status?.status?.enquiry_status[status]}</Tag>
			},
		},
		{
			key   : "action",
			render: (record) => <button onClick={() => viewMoreData(record)} className='ant-btn hoverableButton'>View More</button>
		},
	];

	// NextPage
	const nextPage = () => {
		if(!isEqual(page, totalPage)) setPage(page + 1);
	}

	// Previous Page
	const previousPage = () => page > 1 && setPage(page - 1);

	let isDisablePreviousBtn = isEqual(page, totalPage) || isEqual(page, 0)
	let currentPage = isEqual(totalPage, 0) ? 0 : page
	let previousBtnClassName = `d-block pagination-icon ${isDisablePreviousBtn ? 'icon-disable' : ''}`
	let nextBtnClassName = `d-block pagination-icon ${isEqual(page, 1) || isEqual(page, 0) ? 'icon-disable' : ''}`

	//click on view More data button
	const viewMoreData = (record) => navigate(`/enquiry/${record.id}`, {state: record})

	useEffect(() => {
		document.title = `Enquiries -  ${pageName}`;
	}, []);

	return (
		<div className='enquiry-list-container'>
			<Title className='text-center page-title'>Enquiries</Title>
			<Table columns={columns} pagination={false} dataSource={enquiryData.data} scroll={{x: 'max-content'}} loading={enquiryData.loading} />
			<div className='enquiry-pagination'>
				<Space align="center" size={[3]}>
					<RightOutlined className={previousBtnClassName} onClick={nextPage} />
					{`Page: ${currentPage}/${totalPage}`}
					{`Total: ${totalData}`}
					<LeftOutlined className={nextBtnClassName} onClick={previousPage} />
				</Space>
			</div>
		</div>
	);
};