/* eslint-disable react-hooks/rules-of-hooks */
import DiamondConstData from "../constant/diamond-constant";
import {filterArray, LAB_URL} from "../constant/filter-constant";
import store from "../../redux";
import _, {isEqual, isNil, toString, toUpper, trim} from "lodash";
import dayjs from "dayjs";

// Exchange Rate
export const exchangeRate = (currency) => {
	const {globalSetting} = store.getState().globalSetting
	const globalRate = globalSetting;
	const multi_currency = {
		"USD": {value: 1},
		"GBP": {value: globalRate?.exchange_rate_gbp},
		"EUR": {value: globalRate?.exchange_rate_eur},
		"INR": {value: globalRate?.exchange_rate_inr}
	}
	return multi_currency[currency].value
}

const detailObj = {
	shape       : "shape",
	weight      : "weight",
	color       : "color",
	clarity     : "clarity",
	cut         : "cut",
	polish      : "polish",
	symmetry    : "symmetry",
	fl_intensity: "fl_intensity"
}

// Create a Diamond String
export const diamondString = (element = detailObj) => {
	const string =
		element.shape + " " +
		element.weight?.toFixed(2) + "CT" + " " +
		element.color + " " +
		element.clarity + " " +
		toUpper(DiamondConstData.grading[`${element.cut}`]) +
		(_.isEmpty(element.cut) ? "" : " ") +
		toUpper(DiamondConstData.grading[`${element.polish}`]) + " " +
		toUpper(DiamondConstData.grading[`${element.symmetry}`]) + " " +
		element.fl_intensity;
	return trim(string);
};

// Window Scroll To
export const scrollTo = () => {
	window.requestAnimationFrame(() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}));
};

// Remove Data from LocalStorage
export const removeData = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("Login Details");
	localStorage.removeItem("email_verified");
	localStorage.removeItem("kycStatus");
	localStorage.removeItem("loglevel");
	localStorage.removeItem("add_to_cart");
	localStorage.removeItem("currency");
	localStorage.removeItem("userName");
	localStorage.removeItem("userStatus");
	localStorage.removeItem("cipherId");
	localStorage.removeItem("id");
	localStorage.removeItem("add_to_cart");
	localStorage.removeItem("wishlist");
	localStorage.removeItem("filterData")
	localStorage.removeItem("cutShape")
	localStorage.removeItem('filterObject')
	localStorage.removeItem('showMore')
	for(const key in filterArray) {
		if(Object.hasOwnProperty.call(filterArray, key)) {
			localStorage.removeItem(key)
		}
	}
};

// Regex For Video Url
export const isValidVideoUrl = (url) => {
	// Regular expression for validating an iframe video URL
	var regex = /[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/\S*)?/;
	return regex.test(url);
};

// Count Final Commission
const handleFinalCommission = (commission, globalData, currencyPrice) => {
	const currency = store.getState().currencyData;
	const global = {...globalData}
	global.minimum_commission = global.minimum_commission * exchangeRate(currency)
	global.maximum_commission = global.maximum_commission * exchangeRate(currency)
	if(commission < global.minimum_commission) return Number(currencyPrice) + Number(global.minimum_commission);
	if(commission > global.maximum_commission) return Number(currencyPrice) + Number(global.maximum_commission)
	// if between minimum and maximum then return default commission
	return Number(currencyPrice) + commission
}

// Count Commission Price
export const countCommission = (seller, currencyPrice, global) => {
	const currency = store.getState().currencyData;
	currencyPrice = parseFloat(currencyPrice) * exchangeRate(currency);
	const seller_commission = isNil(seller.commission) ? null : parseFloat(seller.commission);
	const global_seller_commission = parseFloat(global.seller_commission) || 0;
	if(!isNil(seller_commission) && typeof seller_commission !== 'undefined') {
		const commission_rate = isEqual(seller_commission, 0) ? 0 : seller_commission / 100;
		const commission = currencyPrice * commission_rate
		return handleFinalCommission(commission, global, currencyPrice)
	} else {
		const commission = currencyPrice * (global_seller_commission / 100)
		return handleFinalCommission(commission, global, currencyPrice)
	}
};

// Commission Percentage
export const commission = (discount, seller, global, diamondPrice, deliveryPrice) => {
	discount = Math.abs(discount);
	const sellerCommission = (isNil(seller.commission) ? parseFloat(global.seller_commission) : parseFloat(seller.commission)) || 0;
	const commission_rate = isEqual(sellerCommission, 0) ? 0 : sellerCommission / 100;
	const commission = diamondPrice * commission_rate
	if(commission < global.minimum_commission || commission > global.maximum_commission) {
		//ex: -> 749.36 * 100 / 100 - dis% = (ans1), 100 * 786.83 || deliveryPrice / ans1 = (ans2) , 100 - ans2 = finalAns
		return (Math.abs((100 * parseFloat(deliveryPrice)) / (diamondPrice * 100 / (discount - 100))) - 100).toFixed(2);
	}
	return ((100 - discount) + ((100 - discount) * parseFloat(sellerCommission) / 100) - 100).toFixed(2)
};

// Count SubTotal
export const countSubTotal = (total, currency) => {
	return (total / exchangeRate(currency)).toFixed(2)
}

// Price Format with Regex
export const formatPrice = (price) => {
	price = parseFloat(price)
	const formatedPrice = toString(price.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return formatedPrice;
}

// clear filter from redux and localstorage
export const clearFilter = (data) => {
	let filterObj = {}
	for(const key in filterArray) {
		if(Object.hasOwnProperty.call(filterArray, key)) {
			filterObj = {
				...filterObj,
				[key]: filterArray[key]
			}
		}
	}
	return filterObj
}

export const updateFilterObject = (filterObject, deletedData) => {
	let filterObj = {}
	for(const key in filterObject) {
		if(Object.hasOwnProperty.call(filterObject, key)) {
			deletedData?.map((v) => delete filterObject[v])
			filterObj = {
				...filterObj,
				[key]: filterObject[key],
			}
		}
	}
	return filterObj
}

export const pageName = "Diahearts";

export const getLabUrl = (lab, cert_number) => {
	return `${LAB_URL[lab]}=${cert_number}`
}

// Check the empty value like null,undefined,emptyString
export const isEmpty = (value) => _.isEmpty(value);

//check extra info value is string or not
export const isURL = (string) => {
	try {
		new URL(string);
		return true;
	} catch(error) {
		return false;
	}
}

//use for stop event Propagation
export const eventPropagation = (e) => e.stopPropagation()

//Show all error message
export const errorHandler = (response) => {
	return response.response.data.errors ?
		   Object.keys(response.response.data.errors).map(key => response.response.data.errors[key][0]).join(' ') : response.response.data.message
}

//date formatting
export const dateFormatting = (date) => {
	return dayjs(new Date(date)).format('DD-MM-YYYY')
}