
import {createSlice} from "@reduxjs/toolkit";
import {fetchSettingStatus} from "../order/order-action";

export const GlobalSettingReducer = createSlice(
	{
		name         : "globalSetting",
		initialState : {globalSetting: JSON.parse(localStorage.getItem("global_status"))},
		reducers     : {
			setGlobalSettingData   : (state, action) => action.payload,
			removeGlobalSettingData: (state, action) => {state = {}}
		},
		extraReducers: (builder) => {
			builder.addCase(fetchSettingStatus.fulfilled, (state, action) => {
				void ((state.globalSetting = action.payload.data))
			})
		}
	})

export const {setGlobalSettingData, removeGlobalSettingData} = GlobalSettingReducer.actions;
export default GlobalSettingReducer.reducer;