import React from "react";
import {Col, Row, Space, Typography} from "antd";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "../../helper/other/common";

const {Text} = Typography
/**
 * Auth NavBar Style
 * @param offerTextColor
 * @returns {JSX.Element}
 * @constructor
 */

const AuthNavBar = ({offerTextColor}) => {
	// Active style function
	const isActiveStyle = (isActive) => ({...(isActive && {textDecoration: 'underline'})});

	return (
		<Space>
			<NavLink to="/login" className="nav-link-topbar-offer" style={({isActive}) => isActiveStyle(isActive)}>
				<Text style={offerTextColor} className="nav-link-topbar-offer">Login</Text>
			</NavLink>
			/
			<NavLink to="/register" className="nav-link-topbar-offer" style={({isActive}) => isActiveStyle(isActive)}>
				<Text style={offerTextColor} className="nav-link-topbar-offer">Register</Text>
			</NavLink>
		</Space>
	)
}

const Topbar = () => {
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const offer = isEmpty(globalSetting) ? {} : JSON.parse(globalSetting.offer)

	const offerText = offer.text
	const offerTextColor = {color: offer.text_color || "white", textDecoration: "none"}
	const offerTextBackGroundColor = {backgroundColor: offer.background_color}

	const token = localStorage.getItem("token");

	return (
		<Row className="tab_bar" justify='space-between' style={offerTextBackGroundColor}>
			<Col flex={4}>
				<a style={offerTextColor} href="mailto:info@diahearts.com">info@diahearts.com</a>
			</Col>
			<Col flex={5}>
				<Text style={offerTextColor}>{offerText}</Text>
			</Col>
			<Col flex={1}>
				{!token && <AuthNavBar offerTextColor={offerTextColor} />}
			</Col>
		</Row>
	);
};

export default Topbar;
