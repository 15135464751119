import React, {useEffect} from 'react'
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import {pageName} from '../../helper/other/common';

const PageNotFound = () => {
	const navigate = useNavigate()

	useEffect(() => {
		document.title = `Page not found -  ${pageName}`
	}, [])
	return (
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={<Button type="primary" onClick={() => navigate("/")}>Back Home</Button>}
		/>
	)
}

export default PageNotFound