import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {hideOthers} from "aria-hidden";
import {Button, Card, Col, Descriptions, Row, Tag, Typography} from "antd";
import {IdcardFilled, SafetyCertificateFilled,} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {diamondString, isEmpty, pageName} from "../../helper/other/common";

const {Title, Text} = Typography;

const EnquiryDetailPage = () => {
	const {state} = useLocation();
	const navigate = useNavigate();
	const contentRef = React.useRef(null);
	let {globalSetting} = useSelector((state) => state?.globalSetting)
	const global_status = isEmpty(globalSetting) ? {} : globalSetting

	useEffect(() => {
		document.title = `Enquiries - ${pageName}`;
		const content = contentRef.current;
		if(content) hideOthers(content);
	}, []);

	const statusTagColor = global_status?.status?.enquiry_status_color[state?.status]
	const descriptionLabelStyle = {fontWeight: "bold", fontSize: "14px", color: "black", paddingBottom: "15px"}
	const descriptionContentStyle = {fontSize: "14px", paddingBottom: "10px", fontWeight: '550px'}

	// Render The IconComponent and Text Value in enquiryDetails Columns
	const RenderIcon = (icon, value) => value ? value : icon

	// Render The RequestData component for display requested data in enquiryDetails Columns
	const RequestedData = () => !state?.request_for?.length ? "-" :
								<Row gutter={[2, 2]}>
									{state?.request_for?.map((v) => (<Col key={v}>
										<Tag color="geekblue" className='enquiry-tag'>{v.toUpperCase()}</Tag>
									</Col>))}
								</Row>

	const EnquiryDetails = [
		{key: "1", label: "Request Id", children: state?.id},
		{
			key     : "2",
			label   : "Certificate Number",
			children: RenderIcon(<SafetyCertificateFilled />, state?.diamond?.cert_number),
		},
		{
			key     : "3",
			label   : "Stock Id",
			children: RenderIcon(<IdcardFilled />, state?.diamond?.stock_id),
		},
		{
			key     : "4",
			label   : "Diamond Title",
			children: state?.diamond !== null ? diamondString(state?.diamond) : "-",
		},
		{
			key     : "5",
			label   : "Message",
			children: state?.message || "-",
		},
		{
			key     : "6",
			label   : "Requested Data",
			children: RequestedData(),
		},
		{
			key     : "7",
			label   : "Status",
			children: <Tag color={statusTagColor} className='enquiry-tag'> {global_status?.status?.enquiry_status[state?.status]}</Tag>,
		},
		{
			key     : "8",
			label   : "Admin Reply",
			children: state?.admin_reply || "-",
		},
	];


	return (
		<div className='enquiry-detail-container' ref={contentRef}>
			<Title className='text-center page-title'>Enquiry #{state?.id}</Title>
			<div className='enquiry-detail-card'>
				<div className='justify-content-center '>
					<Card title={<Title level={3} className='mb-0'> Enquiry Details </Title>}>
						<Descriptions
							column={1}
							labelStyle={descriptionLabelStyle}
							items={EnquiryDetails}
							contentStyle={descriptionContentStyle}
						/>
					</Card>
					<div className='enquiry-pagination'>
						<Button type="primary" onClick={() => navigate("/enquiry")}>BACK</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EnquiryDetailPage;
