import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {Row} from "antd";
import {useNavigate} from 'react-router-dom';
import {fetchSettingStatus} from '../redux/order/order-action';
import {removeOrderFilterData} from "../redux/order/order-slice";
import {isEqual, isUndefined, trim} from 'lodash'

const UnProtectedRoute = ({children}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	let kyc_status = localStorage?.getItem("kycStatus");
	let userStatus = localStorage?.getItem("userStatus");
	let isHome = isEqual(window.location.pathname, "/") || isEqual(window.location.pathname, "/home")

	let isAllow = token && isEqual(userStatus, 'active') && !isHome

	useEffect(() => {
		dispatch(fetchSettingStatus())
		dispatch(removeOrderFilterData())
		if(isAllow) navigate('/natural')
	})

	const kycStatusMessage = JSON.parse(localStorage?.getItem("global_status"))?.status?.kyc_status_message
	const displayKycStatusMessage = !isUndefined(kycStatusMessage) ? kycStatusMessage[trim(kyc_status)] : {}

	let isVerified = localStorage?.getItem("email_verified");

	return (
		<>
			{isEqual(isVerified, 'false') && <Row className="message_bar" justify="center"> Your email is not verify, Please verify your email to access all benefits</Row>}
			{(isEqual(isVerified, 'true') && !isEqual(kyc_status, "approved") && !isEqual(kyc_status, 'undefined'))
				&& <Row className="message_bar" justify="center">{displayKycStatusMessage}</Row>}
			{!isAllow && children}
		</>
	)
}
export default UnProtectedRoute