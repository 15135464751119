import React from 'react'
import {Button, Col, Row, Typography} from 'antd'
import {LABDATA} from "../../../../helper/constant/filter-constant";
import {includes} from "lodash";

const {Title} = Typography

const FilterLabData = ({filterData, setFilterData}) => {

	// Handle Lab-card click
	const handleLabCardClick = (lab) => {
		if(includes(filterData.lab, lab)) {
			const filterLabData = filterData.lab.filter(name => name !== lab);
			setFilterData({...filterData, lab: filterLabData})
		} else setFilterData({...filterData, lab: [...filterData?.lab, lab]})
	}

	return <>
		<Title level={5} className='filter-title'> Labs </Title>
		<Row gutter={[8, 8]}>
			{LABDATA.map((lab) => {
				const buttonType = includes(filterData.lab, lab) ? "primary" : "default"
				return <Col key={lab}> <Button type={buttonType} onClick={() => handleLabCardClick(lab)} className='text-uppercase'>{lab}</Button> </Col>
			})}
		</Row>
	</>
}
export default FilterLabData