import React, {useState} from "react";
import {Button, Checkbox, Col, Input, Modal, Row, Space, Typography,} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {diamondString} from "../../../helper/other/common";
import AlertMessage from "../../../helper/other/AlertMessage";
import {enquiryRequest} from "../../../redux/enquiry/enquiry-action";
import DiamondConstData from "../../../helper/constant/diamond-constant";
import {includes, isEmpty, isEqual} from "lodash";

const {TextArea} = Input;
const {Text, Title} = Typography;

const EnquiryModel = ({setAction, action, data, imageRequest}) => {
	const dispatch = useDispatch();
	const itemString = diamondString(data);
	const {shade, milky, eye_clean, id} = data;

	//imageRequest = 'i_request' means click on request image btn

	//check value for shade ,  milky ,  eye clean
	const emptyValue = ["", "None", "No"]
	let isMilky = includes(emptyValue, milky)
	let isShade = includes(emptyValue, shade)
	let isEyeClean = includes(emptyValue, eye_clean)

	const enquiryHistory = useSelector((state) => state.enquiryRequest)
	const [enquiry, setEnquiry] = useState({message: "", request_for: [], request_status: {photo: false, milky: false, eye_clean: false, shade: false,}, diamond_id: id});
	const [message, setMessage] = useState({isMessage: false, message: "", type: ""});

	//when close the enquiry modal
	const closeEnquiryModal = () => {
		setEnquiry({message: "", request_for: [], diamond_id: id});
		setAction({...action, enquiryModalOpen: false});
		setMessage({isMessage: false, message: "", type: ""});
	};

	// preform action [api calling] when click on enquiry submit btn
	const submitEnquiryAction = async(data) => {
		const enquiryObj = {
			message    : enquiry.message,
			request_for: JSON.stringify(data),
			diamond_id : parseInt(enquiry.diamond_id),
			item_name  : itemString
		};

		const respo = await dispatch(enquiryRequest(enquiryObj));
		if(respo.payload.status) {
			setMessage({isMessage: true, message: DiamondConstData.message.ENQUIRY_MESSAGE, type: "success"});
			setEnquiry({message: "", request_for: []});
		}
	}

	// when click on submit Enquiry btn
	const onSubmitEnquiry = () => {
		const requestStatusArr = [enquiry.request_status?.eye_clean, enquiry.request_status?.milky, enquiry.request_status?.photo, enquiry.request_status?.shade]
		const isValue = includes(requestStatusArr, true)
		const hasSelectValue = !isValue && isEmpty(enquiry.message) && !isEqual(imageRequest, "i_request")
		const data = isEqual(imageRequest, "i_request") ? [...enquiry.request_for, "Request Photo or Video"] : enquiry.request_for
		return hasSelectValue ? setMessage({isMessage: true, message: "Please write a query", type: "error"}) : submitEnquiryAction(data)
	};

	// when close the alert data
	const closeMessage = () => {
		!isEqual(message.type, "error") && closeEnquiryModal()
		setMessage({isMessage: false, message: "", type: ""});
	};

	const requestForValue = {shade: 'Request Shade', milky: 'Request Milky', eye_clean: 'Request Eye Clean', photo: 'Request Photo or Video'}

	//default image request selected when click for i_request
	let imageRequestValue = isEqual(imageRequest, "i_request") ? true : enquiry.request_status?.photo

	// when check or remove the checkbox value
	const selectForRequest = (e, requestFor) => {
		if(isEqual(e.target.checked, true)) {
			setEnquiry({...enquiry, request_for: [...enquiry.request_for, requestForValue[requestFor]], request_status: {...enquiry.request_status, [`${requestFor}`]: e.target.checked},});
		} else {
			const filterRequest = enquiry.request_for.filter((value) => value !== requestForValue[requestFor]);
			setEnquiry({...enquiry, request_for: filterRequest, request_status: {...enquiry.request_status, [`${requestFor}`]: e.target.checked}});
		}
	}

	//Enquiry submit button and show error message
	const ModalFooter = () => {
		let showData = !message.isMessage || isEqual(message.type, "error")
		return showData && <div className='d-flex justify-items-start'>
			<Space>
				<Button type="primary" className='text-uppercase mt-2' onClick={onSubmitEnquiry} loading={enquiryHistory.loading}> Enquiry Now </Button>
				<AlertMessage className="alert-tag error-alert" message={message.message} type={message.type} onClose={closeMessage} />
			</Space>
		</div>
	}

	return (
		<Modal open={action.enquiryModalOpen}
			   title="Enquiry For"
			   footer={[ModalFooter()]}
			   onCancel={closeEnquiryModal}
			   zIndex={2000}>
			{message.isMessage && !isEqual(message.type, "error") ?
			 <AlertMessage className="alert-tag" message={message.message} type={message.type} closable={true} onClose={closeMessage} /> : <>
				 <div>
					 <Title level={5} className='text-uppercase mt-1 fw-normal'>{itemString}</Title>
					 <Space>
						 <Text type="secondary">Stock Id : </Text>
						 <Text strong>{data.stock_id}</Text>
						 <Text type="secondary">GIA : </Text>
						 <Text strong>{data.cert_number}</Text>
					 </Space>
				 </div>
				 <div className='p-1 justify-content-center'>
					 <Row>
						 {isShade && (<Col flex={1}>
							 <Checkbox checked={enquiry.request_status?.shade} onClick={(e) => selectForRequest(e, "shade")}>
								 Request Shade
							 </Checkbox>
						 </Col>)}
						 {isMilky && (<Col flex={1}>
							 <Checkbox checked={enquiry.request_status?.milky} onClick={(e) => selectForRequest(e, "milky")}>
								 Request Milky
							 </Checkbox>
						 </Col>)}
						 {isEyeClean && (<Col flex={1}>
							 <Checkbox checked={enquiry.request_status?.eye_clean} onClick={(e) => selectForRequest(e, 'eye_clean')}>
								 Request Eye Clean
							 </Checkbox>
						 </Col>)}
						 <Col flex={1}>
							 <Checkbox checked={imageRequestValue} onClick={(e) => selectForRequest(e, 'photo')}>
								 Request Photo or Video
							 </Checkbox>
						 </Col>
					 </Row>
					 <div className='mt-3'>
						 <TextArea rows={3} value={enquiry.message} onChange={(e) => {setEnquiry({...enquiry, message: e.target.value})}} placeholder="Enter your query Here..." />
					 </div>
				 </div>
			 </>}
		</Modal>
	);
};

export default EnquiryModel;
