import {useState} from "react"
import TableRows from "./TableRows"
import {Table} from "react-bootstrap";

function AddDeleteTableRows() {


	const [rowsData, setRowsData] = useState([{
		ratio  : '',
		diamond: '',
		price  : ''
	}]);

	const addTableRows = () => {

		const rowsInput = {
			ratio  : '',
			diamond: '',
			price  : ''
		}
		setRowsData([...rowsData, rowsInput])

	}
	const deleteTableRows = (index) => {
		const rows = [...rowsData];
		rows.splice(index, 1);
		setRowsData(rows);
	}

	const handleChange = (index, evnt) => {

		const {name, value} = evnt.target;
		const rowsInput = [...rowsData];
		rowsInput[index][name] = value;
		setRowsData(rowsInput);
	}

	return (
		<div className="container">
			{/* <h1>Loose Diamond</h1> */}
			<div className="row">
				{/* <div className="col-sm-8"> */}

				<Table striped bordered hover>
					<thead>
					<tr>
						<th>Ratio</th>
						<th>Diamond</th>
						<th>Price</th>
						<th>
							<button className="btn btn-outline-success" onClick={addTableRows}>+</button>
						</th>
					</tr>

					</thead>
					<tbody>

					<TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />

					</tbody>
				</Table>

			</div>
		</div>
	)

}

export default AddDeleteTableRows