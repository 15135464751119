import {Button, Col, Row, Typography} from "antd";
import React from "react";
import {FL_COLOR} from "../../../../helper/constant/filter-constant";
import {includes} from 'lodash'

const {Title} = Typography;
const FilterFluColor = ({filterData, setFilterData}) => {

	const data = filterData.fl_clr

	// Handle onClick for color-click
	const handleColorClick = (color) => {
		if(includes(data, color)) {
			const filterColorData = data?.filter(name => name !== color);
			setFilterData({...filterData, fl_clr: filterColorData})
		} else setFilterData({...filterData, fl_clr: [...filterData.fl_clr, color]})
	}

	return (
		<>
			<Title level={5} className='filter-title'> Fluorescence Color </Title>
			<Row gutter={[8, 8]}>
				{FL_COLOR.map((color) => {
					const colorClassName = includes(data, color.name) ? "fancy_color_selected" : "fancy_color";
					const colorArr = ["Yellow", "White"]
					const buttonStyle = {backgroundColor: color.code, color: includes(colorArr, color.name) ? "black" : "white"}
					return (
						<Col key={color.name}>
							<Button className={`${colorClassName} text-uppercase`} style={buttonStyle} onClick={() => handleColorClick(color.name)}>{color.name.charAt(0)}</Button>
						</Col>)
				})}
			</Row>
		</>
	);
};

export default FilterFluColor;
